import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeUser,
  changePasswordProfile,
  createUser,
  deleteUser,
  getUser,
  getUserIsLogging,
  inactiveUser,
  listUser,
  updateUser,
} from 'api/user/user.api';
import {
  ActiveUserRequestModel,
  ChangePasswordProfileRequestModel,
  DeleteUserRequestModel,
  GetUserIsLoggingRequestModel,
  GetUserRequestModel,
  InactiveUserRequestModel,
  ListUserRequestModel,
  SaveUserRequestModel,
} from 'models/user/user.model';

export const getUserAction = createAsyncThunk(
  'GET_USER_REQUEST',
  async (payload: GetUserRequestModel) => getUser(payload),
);

export const getUserIsLoggingAction = createAsyncThunk(
  'GET_USER_IS_LOGGING_REQUEST',
  async (payload: GetUserIsLoggingRequestModel) => getUserIsLogging(payload),
);

export const listUserAction = createAsyncThunk(
  'LIST_USER_REQUEST',
  async (payload: ListUserRequestModel) => listUser(payload),
);

export const createUserAction = createAsyncThunk(
  'CREATE_USER_REQUEST',
  async (payload: SaveUserRequestModel, { rejectWithValue }) => {
    try {
      return await createUser(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateUserAction = createAsyncThunk(
  'UPDATE_USER_REQUEST',
  async (payload: SaveUserRequestModel, { rejectWithValue }) => {
    try {
      return await updateUser(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteUserAction = createAsyncThunk(
  'DELETE_USER_REQUEST',
  async (payload: DeleteUserRequestModel, { rejectWithValue }) => {
    try {
      return await deleteUser(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveUserAction = createAsyncThunk(
  'INACTIVE_USER_REQUEST',
  async (payload: InactiveUserRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveUser(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeUserAction = createAsyncThunk(
  'ACTIVE_USER_REQUEST',
  async (payload: ActiveUserRequestModel, { rejectWithValue }) => {
    try {
      return await activeUser(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const changePasswordProfileAction = createAsyncThunk(
  'CHANGE_PASSWORD_PROFILE_REQUEST',
  async (payload: ChangePasswordProfileRequestModel, { rejectWithValue }) => {
    try {
      return await changePasswordProfile(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
