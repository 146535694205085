import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { UiTemplateBoothStateModel } from 'models/setting/ui-template-booth/ui-template-booth.model';
import {
  activeUiTemplateBoothAction,
  createUiTemplateBoothAction,
  deleteUiTemplateBoothAction,
  getUiTemplateBoothAction,
  inactiveUiTemplateBoothAction,
  listUiTemplateBoothAction,
  updateUiTemplateBoothAction,
} from './ui-template-booth.action';

const initialState: UiTemplateBoothStateModel = {
  uiTemplateBoothListData: null,
  uiTemplateBoothDetail: null,
  triggerSaveUiTemplateBooth: null,

  loadingList: false,
  loadingDetail: false,
};

export const uiTemplateBoothSlice = createSlice({
  name: 'uiTemplateBooth',
  initialState,
  reducers: {
    setUiTemplateBoothStateAction: (
      state,
      action: PayloadAction<Partial<UiTemplateBoothStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as UiTemplateBoothStateModel),
    resetUiTemplateBoothStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUiTemplateBoothAction.pending, (state) => ({
        ...state,
        uiTemplateBoothDetail: null,
        loadingDetail: true,
      }))
      .addCase(getUiTemplateBoothAction.fulfilled, (state, { payload }) => ({
        ...state,
        uiTemplateBoothDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getUiTemplateBoothAction.rejected, (state) => ({
        ...state,
        uiTemplateBoothDetail: null,
        loadingDetail: false,
      }))
      .addCase(listUiTemplateBoothAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listUiTemplateBoothAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        uiTemplateBoothListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listUiTemplateBoothAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createUiTemplateBoothAction.fulfilled, (state) => ({
        ...state,
        triggerSaveUiTemplateBooth: { id: uuidv4() },
      }))
      .addCase(updateUiTemplateBoothAction.fulfilled, (state) => ({
        ...state,
        triggerSaveUiTemplateBooth: { id: uuidv4() },
      }))

      .addCase(deleteUiTemplateBoothAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteUiTemplateBoothAction.fulfilled, (state) => ({
        ...state,
        triggerSaveUiTemplateBooth: { id: uuidv4() },
      }))
      .addCase(deleteUiTemplateBoothAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeUiTemplateBoothAction.fulfilled, (state) => ({
        ...state,
        triggerSaveUiTemplateBooth: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveUiTemplateBoothAction.fulfilled, (state) => ({
        ...state,
        triggerSaveUiTemplateBooth: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const {
  setUiTemplateBoothStateAction,
  resetUiTemplateBoothStateAction,
} = uiTemplateBoothSlice.actions;

export default uiTemplateBoothSlice.reducer;
