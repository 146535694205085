import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { StickerCategoryStateModel } from 'models/sticker/sticker-category.model';
import {
  activeStickerCategoryAction,
  createStickerCategoryAction,
  deleteStickerCategoryAction,
  getStickerCategoryAction,
  inactiveStickerCategoryAction,
  listStickerCategoryAction,
  updateStickerCategoryAction,
} from './sticker-category.action';

const initialState: StickerCategoryStateModel = {
  stickerCategoryListData: null,
  stickerCategoryDetail: null,
  loadingList: false,
  triggerSaveStickerCategory: null,
};

export const stickerCategorySlice = createSlice({
  name: 'stickerCategory',
  initialState,
  reducers: {
    setStickerCategoryStateAction: (
      state,
      action: PayloadAction<Partial<StickerCategoryStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as StickerCategoryStateModel),
    resetStickerCategoryStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStickerCategoryAction.pending, (state) => ({
        ...state,
        stickerCategoryDetail: null,
      }))
      .addCase(getStickerCategoryAction.fulfilled, (state, { payload }) => ({
        ...state,
        stickerCategoryDetail: payload?.data || null,
      }))
      .addCase(getStickerCategoryAction.rejected, (state) => ({
        ...state,
        stickerCategoryDetail: null,
      }))
      .addCase(listStickerCategoryAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listStickerCategoryAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        stickerCategoryListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listStickerCategoryAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createStickerCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStickerCategory: { id: uuidv4() },
      }))
      .addCase(updateStickerCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStickerCategory: { id: uuidv4() },
      }))

      .addCase(deleteStickerCategoryAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteStickerCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStickerCategory: { id: uuidv4() },
      }))
      .addCase(deleteStickerCategoryAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeStickerCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStickerCategory: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveStickerCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStickerCategory: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const {
  setStickerCategoryStateAction,
  resetStickerCategoryStateAction,
} = stickerCategorySlice.actions;

export default stickerCategorySlice.reducer;
