import { isNumberable } from './math.helper';

interface Data {
  [key: string]: any;
}

export function convertToFormData(
  formData: FormData,
  data: Data,
  parentKey: any = undefined,
) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      const dataKey = !isNumberable(key as any)
        ? `${parentKey}.${key}`
        : `${parentKey}[${key}]`;
      // if (data[key] instanceof File) {
      //   dataKey = parentKey;
      // }
      convertToFormData(formData, data[key], parentKey ? dataKey : key);
    });
  } else {
    const value: any = data ?? '';

    formData.append(parentKey, value);
  }
}

export function getFormData(data: Data) {
  const formData = new FormData();
  convertToFormData(formData, data);

  return formData;
}
