import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { LayoutStateModel } from 'models/layout/layout.model';
import {
  createLayoutAction,
  deleteLayoutAction,
  getLayoutAction,
  listLayoutAction,
  updateLayoutAction,
} from './layout.action';

const initialState: LayoutStateModel = {
  layoutListData: null,
  layoutDetail: null,
  triggerSaveLayout: null,

  loadingList: false,
  loadingDetail: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setLayoutStateAction: (
      state,
      action: PayloadAction<Partial<LayoutStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as LayoutStateModel),
    resetLayoutStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLayoutAction.pending, (state) => ({
        ...state,
        layoutDetail: null,
        loadingDetail: true,
      }))
      .addCase(getLayoutAction.fulfilled, (state, { payload }) => ({
        ...state,
        layoutDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getLayoutAction.rejected, (state) => ({
        ...state,
        layoutDetail: null,
        loadingDetail: false,
      }))
      .addCase(listLayoutAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listLayoutAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        layoutListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listLayoutAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createLayoutAction.fulfilled, (state) => ({
        ...state,
        triggerSaveLayout: { id: uuidv4() },
      }))
      .addCase(updateLayoutAction.fulfilled, (state) => ({
        ...state,
        triggerSaveLayout: { id: uuidv4() },
      }))

      .addCase(deleteLayoutAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteLayoutAction.fulfilled, (state) => ({
        ...state,
        triggerSaveLayout: { id: uuidv4() },
      }))
      .addCase(deleteLayoutAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }));
  },
});

export const { setLayoutStateAction, resetLayoutStateAction } =
  layoutSlice.actions;

export default layoutSlice.reducer;
