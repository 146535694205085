import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveUserRequestModel,
  ActiveUserResponseModel,
  ChangePasswordProfileRequestModel,
  ChangePasswordProfileResponseModel,
  DeleteUserRequestModel,
  DeleteUserResponseModel,
  GetUserIsLoggingRequestModel,
  GetUserIsLoggingResponseModel,
  GetUserRequestModel,
  GetUserResponseModel,
  InactiveUserRequestModel,
  InactiveUserResponseModel,
  ListUserRequestModel,
  ListUserResponseModel,
  ListUserRolesRequestModel,
  ListUserRolesResponseModel,
  SaveUserRequestModel,
  SaveUserResponseModel,
} from 'models/user/user.model';

export function listUserRoles(payload: ListUserRolesRequestModel) {
  return authorizedRequest.get<
    ListUserRolesResponseModel,
    ListUserRolesResponseModel
  >(`${PREFIX_API.GENERAL}/users/roles`);
}

export function getUser(payload: GetUserRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<GetUserResponseModel, GetUserResponseModel>(
      `${PREFIX_API.GENERAL}/users/${payload?.id}`,
    );
  }
}

export function getUserIsLogging(payload: GetUserIsLoggingRequestModel) {
  return authorizedRequest.get<
    GetUserIsLoggingResponseModel,
    GetUserIsLoggingResponseModel
  >(`${PREFIX_API.GENERAL}/users/self`);
}

export function listUser(payload: ListUserRequestModel) {
  return authorizedRequest.get<ListUserResponseModel, ListUserResponseModel>(
    `${PREFIX_API.GENERAL}/users?${preprocessGetQuery(payload)}`,
  );
}

export function createUser(payload: SaveUserRequestModel) {
  return authorizedRequest.post<SaveUserResponseModel, SaveUserResponseModel>(
    `${PREFIX_API.GENERAL}/users`,
    payload,
  );
}

export function updateUser(payload: SaveUserRequestModel) {
  if (payload?.id) {
    return authorizedRequest.put<SaveUserResponseModel, SaveUserResponseModel>(
      `${PREFIX_API.GENERAL}/users/${payload?.id}`,
      payload,
    );
  }
}

export function deleteUser(payload: DeleteUserRequestModel) {
  return authorizedRequest.delete<
    DeleteUserResponseModel,
    DeleteUserResponseModel
  >(`${PREFIX_API.GENERAL}/users/${payload?.id}`);
}

export function inactiveUser(payload: InactiveUserRequestModel) {
  return authorizedRequest.put<
    InactiveUserResponseModel,
    InactiveUserResponseModel
  >(`${PREFIX_API.GENERAL}/users/${payload?.id}/deactivate`);
}

export function activeUser(payload: ActiveUserRequestModel) {
  return authorizedRequest.put<
    ActiveUserResponseModel,
    ActiveUserResponseModel
  >(`${PREFIX_API.GENERAL}/users/${payload?.id}/activate`);
}

export function changePasswordProfile(
  payload: ChangePasswordProfileRequestModel,
) {
  return authorizedRequest.put<
    ChangePasswordProfileResponseModel,
    ChangePasswordProfileResponseModel
  >(`${PREFIX_API.GENERAL}/users/${payload?.id}/reset-password`, payload);
}
