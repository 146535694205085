import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveLayoutTemplateRequestModel,
  ActiveLayoutTemplateResponseModel,
  DeleteLayoutTemplateRequestModel,
  DeleteLayoutTemplateResponseModel,
  GetLayoutTemplateRequestModel,
  GetLayoutTemplateResponseModel,
  InactiveLayoutTemplateRequestModel,
  InactiveLayoutTemplateResponseModel,
  ListLayoutTemplateRequestModel,
  ListLayoutTemplateResponseModel,
  SaveLayoutTemplateRequestModel,
  SaveLayoutTemplateResponseModel,
} from 'models/layout/layout-template.model';

export function getLayoutTemplate(payload: GetLayoutTemplateRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetLayoutTemplateResponseModel,
      GetLayoutTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/layout-lists/${payload?.id}`);
  }
}

export function listLayoutTemplate(payload: ListLayoutTemplateRequestModel) {
  return authorizedRequest.get<
    ListLayoutTemplateResponseModel,
    ListLayoutTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/layout-lists?${preprocessGetQuery(payload)}`);
}

export function createLayoutTemplate(payload: SaveLayoutTemplateRequestModel) {
  return authorizedRequest.post<
    SaveLayoutTemplateResponseModel,
    SaveLayoutTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/layout-lists`, payload);
}

export function updateLayoutTemplate(payload: SaveLayoutTemplateRequestModel) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveLayoutTemplateResponseModel,
      SaveLayoutTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/layout-lists/${payload?.id}`, payload);
  }
}

export function deleteLayoutTemplate(
  payload: DeleteLayoutTemplateRequestModel,
) {
  return authorizedRequest.delete<
    DeleteLayoutTemplateResponseModel,
    DeleteLayoutTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/layout-lists/${payload?.id}`);
}

export function inactiveLayoutTemplate(
  payload: InactiveLayoutTemplateRequestModel,
) {
  return authorizedRequest.put<
    InactiveLayoutTemplateResponseModel,
    InactiveLayoutTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/layout-lists/${payload?.id}/inactive`);
}

export function activeLayoutTemplate(
  payload: ActiveLayoutTemplateRequestModel,
) {
  return authorizedRequest.put<
    ActiveLayoutTemplateResponseModel,
    ActiveLayoutTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/layout-lists/${payload?.id}/active`);
}
