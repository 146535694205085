import {
  HAVE_ALL_PERMISSION,
  NOT_HAVE_PERMISSION,
  ROLE_TYPES,
} from 'constants/backend-defines.const';
import { AppRouteConst } from 'constants/route.const';
import { isEqualVal } from 'helpers/string.helper';
import { find, forEach } from 'lodash';
import {
  PermissionAllowModel,
  PermissionMappingModel,
} from 'models/hook/permission.model';
import { useCallback, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/store-hooks';

export function usePermission() {
  const { pathname } = useLocation();
  const { userIsLogging } = useAppSelector((state) => state.user);

  const allPermission: PermissionMappingModel = useMemo(
    () => [
      {
        role: ROLE_TYPES.Admin,
        isFullPermission: true,
      },
      {
        role: ROLE_TYPES.Manager,
        permission: [
          {
            route: AppRouteConst.DASHBOARD,
            allows: { canView: true, canUpdate: false, canDelete: false },
          },
          {
            route: AppRouteConst.TRANSACTION,
            allows: {
              canView: true,
              canUpdate: true,
              canDelete: false,
              canRefund: true,
            },
          },
          {
            route: AppRouteConst.REPORT.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.COMPANY,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.STORE,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.BOOTH,
            allows: {
              canView: true,
              canUpdate: true,
              canDelete: true,
              canSeeBoothId: false,
            },
          },
          {
            route: AppRouteConst.LAYOUT_TEMPLATE.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.THEME_TEMPLATE.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.THEME_CATEGORY,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.THEME.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.STICKER_TEMPLATE.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.STICKER_CATEGORY,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.STICKER.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.PROMOTION_CAMPAIGN,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.VOUCHER.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.USER,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.PROFILE.PREFIX,
            isFullPermission: true,
          },

          // Setting
          {
            route: AppRouteConst.SETTING.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.SETTING.SETTING_CAPTURE_POSITION.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.SETTING.SETTING_CAPTURE_POSITION_TEMPLATE.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.SETTING.SETTING_BOOTH_BACKGROUND.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.SETTING.SETTING_BOOTH_BACKGROUND_TEMPLATE.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.SETTING.SETTING_BOOTH_BACKGROUND_CATEGORY,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.SETTING.SETTING_UI_TEMPLATE_BOOTH.LIST,
            isFullPermission: true,
          },
        ],
      },
      {
        role: ROLE_TYPES.Sale,
        permission: [
          {
            route: AppRouteConst.DASHBOARD,
            allows: { canView: true, canUpdate: false, canDelete: false },
          },
        ],
      },
      {
        role: ROLE_TYPES.Franchiser,
        permission: [
          {
            route: AppRouteConst.DASHBOARD,
            allows: { canView: true, canUpdate: false, canDelete: false },
          },
          {
            route: AppRouteConst.TRANSACTION,
            allows: {
              canView: true,
              canUpdate: false,
              canDelete: false,
              canRefund: true,
            },
          },
          {
            route: AppRouteConst.REPORT.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.PROMOTION_CAMPAIGN,
            allows: { canView: true, canUpdate: false, canDelete: false },
          },
          {
            route: AppRouteConst.VOUCHER.LIST,
            allows: { canView: true, canUpdate: true, canDelete: true },
          },
          {
            route: AppRouteConst.USER,
            allows: { canView: true, canUpdate: true, canDelete: true },
          },
          {
            route: AppRouteConst.PROFILE.PREFIX,
            isFullPermission: true,
          },
        ],
      },
      {
        role: ROLE_TYPES.FranchiserManager,
        permission: [
          {
            route: AppRouteConst.DASHBOARD,
            allows: { canView: true, canUpdate: false, canDelete: false },
          },
          {
            route: AppRouteConst.TRANSACTION,
            allows: {
              canView: true,
              canUpdate: false,
              canDelete: false,
              canRefund: true,
            },
          },
          {
            route: AppRouteConst.REPORT.LIST,
            isFullPermission: true,
          },
          {
            route: AppRouteConst.PROMOTION_CAMPAIGN,
            allows: { canView: true, canUpdate: false, canDelete: false },
          },
          {
            route: AppRouteConst.VOUCHER.LIST,
            allows: { canView: true, canUpdate: true, canDelete: true },
          },
          {
            route: AppRouteConst.PROFILE.PREFIX,
            allows: { canView: false, canUpdate: false },
          },
        ],
      },
      {
        role: ROLE_TYPES.BoothReceptionist,
        permission: [
          {
            route: AppRouteConst.PROMOTION_CAMPAIGN,
            allows: { canView: true, canUpdate: false, canDelete: false },
          },
          {
            route: AppRouteConst.VOUCHER.LIST,
            allows: { canView: true, canUpdate: true, canDelete: true },
          },
          {
            route: AppRouteConst.PROFILE.PREFIX,
            allows: { canView: false, canUpdate: false },
          },
        ],
      },
    ],
    [],
  );

  const getAllowingByPathname = useCallback(
    (path: string = pathname): PermissionAllowModel => {
      const permissionData = find(allPermission, (o) =>
        isEqualVal(o?.role, userIsLogging?.role),
      );
      if (permissionData) {
        if (permissionData?.isFullPermission) {
          return { ...HAVE_ALL_PERMISSION };
        }
        let foundAllows = {};
        forEach(permissionData?.permission, (item) => {
          if (matchPath({ path: item.route }, path) && item?.isFullPermission) {
            foundAllows = { ...HAVE_ALL_PERMISSION };
            return false;
          }
          if (matchPath({ path: item.route }, path) && item?.allows) {
            foundAllows = item?.allows;
            return false;
          }
        });
        return {
          ...NOT_HAVE_PERMISSION,
          ...foundAllows,
        };
      }
      return { ...NOT_HAVE_PERMISSION };
    },
    [allPermission, pathname, userIsLogging?.role],
  );

  const getDefaultRoute = useCallback((): string => {
    const permissionData = find(allPermission, (o) =>
      isEqualVal(o?.role, userIsLogging?.role),
    );
    if (permissionData) {
      if (permissionData?.isFullPermission) {
        return AppRouteConst.DASHBOARD;
      }
      let droute = '';
      forEach(permissionData?.permission, (item) => {
        if (item?.isFullPermission || item?.allows?.canView) {
          droute = item?.route;
          return false;
        }
      });
      if (droute) return droute;
    }
    return '';
  }, [allPermission, userIsLogging?.role]);

  const checkPermissionByLevel = useCallback(
    (
      actionRole: ROLE_TYPES | undefined,
      affectedRole: ROLE_TYPES | undefined,
    ) => {
      if (actionRole) {
        switch (actionRole) {
          case ROLE_TYPES.Admin:
            return true;
          case ROLE_TYPES.Manager:
            return (
              affectedRole !== ROLE_TYPES.Admin &&
              affectedRole !== ROLE_TYPES.Manager
            );
          case ROLE_TYPES.Franchiser:
            return (
              affectedRole === ROLE_TYPES.FranchiserManager ||
              affectedRole === ROLE_TYPES.BoothReceptionist
            );
        }
      }
      return false;
    },
    [],
  );

  const currentUserAllow = useMemo(
    () => getAllowingByPathname(),
    [getAllowingByPathname],
  );

  const isSaleRole = isEqualVal(userIsLogging?.role, ROLE_TYPES.Sale);

  return {
    getAllowingByPathname,
    getDefaultRoute,
    checkPermissionByLevel,
    currentUserAllow,
    isSaleRole,
  };
}
