import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ReportStateModel } from 'models/report/report.model';
import {
  listAnnualShootingCountAction,
  listMonthlyShootingCountAction,
  getShootingCountSummaryAction,
  listReportShootingDurationAction,
  listReportErrorBoothAction,
  exportReportErrorBoothAction,
  listReportDepositLogAction,
} from './report.action';

const initialState: ReportStateModel = {
  shootingCountSummary: null,
  listAnnualShootingCount: null,
  listMonthlyShootingCount: null,

  loadingShootingCountSummary: false,
  loadingAnnualShootingCount: false,
  loadingMonthlyShootingCount: false,

  listReportShootingDuration: [],
  loadingReportShootingDuration: false,

  listReportDepositLog: {
    items: [],
    totalPages: 0,
    totalItems: 0,
  },
  loadingReportDepositLog: false,

  // Báo cáo thống kê booth lỗi
  listReportErrorBooth: [],
  loadingReportErrorBooth: false,
  loadingExportErrorReport: false,
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setReportStateAction: (
      state,
      action: PayloadAction<Partial<ReportStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as ReportStateModel),
    resetReportStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShootingCountSummaryAction.pending, (state) => ({
        ...state,
        shootingCountSummary: null,
        loadingShootingCountSummary: true,
      }))
      .addCase(
        getShootingCountSummaryAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          shootingCountSummary: payload?.data || null,
          loadingShootingCountSummary: false,
        }),
      )
      .addCase(getShootingCountSummaryAction.rejected, (state) => ({
        ...state,
        shootingCountSummary: null,
        loadingShootingCountSummary: false,
      }))

      .addCase(listAnnualShootingCountAction.pending, (state) => ({
        ...state,
        listAnnualShootingCount: null,
        loadingAnnualShootingCount: true,
      }))
      .addCase(
        listAnnualShootingCountAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listAnnualShootingCount: payload?.data || null,
          loadingAnnualShootingCount: false,
        }),
      )
      .addCase(listAnnualShootingCountAction.rejected, (state) => ({
        ...state,
        listAnnualShootingCount: null,
        loadingAnnualShootingCount: false,
      }))
      .addCase(listMonthlyShootingCountAction.pending, (state) => ({
        ...state,
        listMonthlyShootingCount: null,
        loadingMonthlyShootingCount: true,
      }))
      .addCase(
        listMonthlyShootingCountAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listMonthlyShootingCount: payload?.data || null,
          loadingMonthlyShootingCount: false,
        }),
      )
      .addCase(listMonthlyShootingCountAction.rejected, (state) => ({
        ...state,
        listMonthlyShootingCount: null,
        loadingMonthlyShootingCount: false,
      }))

      .addCase(listReportShootingDurationAction.pending, (state) => ({
        ...state,
        listReportShootingDuration: [],
        loadingReportShootingDuration: true,
      }))
      .addCase(
        listReportShootingDurationAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listReportShootingDuration: payload?.data || [],
          loadingReportShootingDuration: false,
        }),
      )
      .addCase(listReportShootingDurationAction.rejected, (state) => ({
        ...state,
        listReportShootingDuration: [],
        loadingReportShootingDuration: false,
      }))

      .addCase(listReportDepositLogAction.pending, (state) => ({
        ...state,
        listReportDepositLog: { ...initialState?.listReportDepositLog },
        loadingReportDepositLog: true,
      }))
      .addCase(listReportDepositLogAction.fulfilled, (state, { payload }) => ({
        ...state,
        listReportDepositLog: {
          items: payload?.data?.items || [],
          totalPages: payload?.data?.totalPages || 0,
          totalItems: payload?.data?.totalItems || 0,
        },
        loadingReportDepositLog: false,
      }))
      .addCase(listReportDepositLogAction.rejected, (state) => ({
        ...state,
        listReportDepositLog: { ...initialState?.listReportDepositLog },
        loadingReportDepositLog: false,
      }))

      .addCase(listReportErrorBoothAction.pending, (state) => ({
        ...state,
        listReportErrorBooth: [],
        loadingReportErrorBooth: true,
      }))
      .addCase(listReportErrorBoothAction.fulfilled, (state, { payload }) => ({
        ...state,
        listReportErrorBooth: payload?.data || [],
        loadingReportErrorBooth: false,
      }))
      .addCase(listReportErrorBoothAction.rejected, (state) => ({
        ...state,
        listReportErrorBooth: [],
        loadingReportErrorBooth: false,
      }))
      .addCase(exportReportErrorBoothAction.pending, (state) => ({
        ...state,
        loadingExportErrorReport: true,
      }))
      .addCase(exportReportErrorBoothAction.fulfilled, (state) => ({
        ...state,
        loadingExportErrorReport: false,
      }))
      .addCase(exportReportErrorBoothAction.rejected, (state) => ({
        ...state,
        loadingExportErrorReport: false,
      }));
  },
});

export const { setReportStateAction, resetReportStateAction } =
  reportSlice.actions;

export default reportSlice.reducer;
