export const REVERSE_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';
export const YEAR_FORMAT = 'YYYY';
export const MONTH_FORMAT = 'MM';
export const MONTH_YEAR_FORMAT = 'MM-YYYY';
export const TIME_FORMAT = 'HH:mm';

export const DATE_FORMAT_2 = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT_2 = 'DD/MM/YYYY HH:mm';
export const MONTH_YEAR_FORMAT_2 = 'MM/YYYY';

export const YEAR_TO_DATE_NO_UNDERSCORE_FORMAT = 'YYYY_MM_DD';

export const REACT_LIB_DATE_FORMAT = 'dd/MM/yyyy';
export const FOUNDER_YEAR = 2023;
