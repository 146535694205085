import React from 'react';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'hooks/useTranslation';
import withAuthenticate from 'hoc/withAuthenticate';

function Page404() {
  const { T } = useTranslation(I18nNamespace.COMMON);

  return <div>{T(`${I18nNamespace.COMMON}:pageNotFound`)}</div>;
}

export default withAuthenticate(Page404);
