import authorizedRequest from 'api/request/authorizedRequest';
import CONFIG from 'config';
import { GRANT_TYPES } from 'constants/api.const';
import {
  LoginRequestModel,
  LoginResponseModel,
  RefreshTokenRequestModel,
} from 'models/auth/auth.model';

export function login(payload: LoginRequestModel) {
  return authorizedRequest.post<LoginResponseModel, LoginResponseModel>(
    `${CONFIG.REACT_APP_IDP_BASE_URL}/connect/token`,
    {
      grant_type: GRANT_TYPES.PASSWORD,
      scope: CONFIG.REACT_APP_AUTH_SCOPE,
      client_id: CONFIG.REACT_APP_CLIENT_ID,
      client_secret: CONFIG.REACT_APP_CLIENT_SECRET,
      ...payload,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
}

export function refreshToken(payload: RefreshTokenRequestModel) {
  return authorizedRequest.post<LoginResponseModel, LoginResponseModel>(
    `${CONFIG.REACT_APP_IDP_BASE_URL}/connect/token`,
    {
      grant_type: GRANT_TYPES.REFRESH_TOKEN,
      client_id: CONFIG.REACT_APP_CLIENT_ID,
      ...payload,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
}
