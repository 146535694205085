import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { BoothStateModel } from 'models/booth/booth.model';
import {
  activeBoothAction,
  createBoothAction,
  deleteBoothAction,
  getBoothAction,
  getBoothIdentityAction,
  inactiveBoothAction,
  listBoothAction,
  updateBoothAction,
  updateBoothVersionAction,
} from './booth.action';

const initialState: BoothStateModel = {
  boothListData: null,
  boothDetail: null,
  boothIdentityId: '',
  triggerSaveBooth: null,

  loadingList: false,
  loadingDetail: false,
  loadingUpdateBoothVersion: false,
};

export const boothSlice = createSlice({
  name: 'booth',
  initialState,
  reducers: {
    setBoothStateAction: (
      state,
      action: PayloadAction<Partial<BoothStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as BoothStateModel),
    resetBoothStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoothAction.pending, (state) => ({
        ...state,
        boothDetail: null,
        loadingDetail: true,
      }))
      .addCase(getBoothAction.fulfilled, (state, { payload }) => ({
        ...state,
        boothDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getBoothAction.rejected, (state) => ({
        ...state,
        boothDetail: null,
        loadingDetail: false,
      }))
      .addCase(getBoothIdentityAction.pending, (state) => ({
        ...state,
        boothIdentityId: '',
      }))
      .addCase(getBoothIdentityAction.fulfilled, (state, { payload }) => ({
        ...state,
        boothIdentityId: payload?.data?.data?.boothHash || '',
      }))
      .addCase(getBoothIdentityAction.rejected, (state) => ({
        ...state,
        boothIdentityId: '',
      }))
      .addCase(listBoothAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listBoothAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        boothListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listBoothAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createBoothAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBooth: { id: uuidv4() },
      }))
      .addCase(updateBoothAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBooth: { id: uuidv4() },
      }))

      .addCase(updateBoothVersionAction.pending, (state) => ({
        ...state,
        loadingUpdateBoothVersion: true,
      }))
      .addCase(updateBoothVersionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBooth: { id: uuidv4() },
        loadingUpdateBoothVersion: false,
      }))
      .addCase(updateBoothVersionAction.rejected, (state) => ({
        ...state,
        loadingUpdateBoothVersion: false,
      }))

      .addCase(deleteBoothAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteBoothAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBooth: { id: uuidv4() },
      }))
      .addCase(deleteBoothAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeBoothAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBooth: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveBoothAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBooth: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setBoothStateAction, resetBoothStateAction } =
  boothSlice.actions;

export default boothSlice.reducer;
