import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveBoothVersionRequestModel,
  ActiveBoothVersionResponseModel,
  DeleteBoothVersionRequestModel,
  DeleteBoothVersionResponseModel,
  GetBoothVersionRequestModel,
  GetBoothVersionResponseModel,
  InactiveBoothVersionRequestModel,
  InactiveBoothVersionResponseModel,
  ListBoothVersionRequestModel,
  ListBoothVersionResponseModel,
  SaveBoothVersionRequestModel,
  SaveBoothVersionResponseModel,
} from 'models/setting/booth-version/booth-version.model';

export function getBoothVersion(payload: GetBoothVersionRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetBoothVersionResponseModel,
      GetBoothVersionResponseModel
    >(`${PREFIX_API.GENERAL}/file-versions/${payload?.id}`);
  }
}

export function listBoothVersion(payload: ListBoothVersionRequestModel) {
  return authorizedRequest.get<
    ListBoothVersionResponseModel,
    ListBoothVersionResponseModel
  >(`${PREFIX_API.GENERAL}/file-versions?${preprocessGetQuery(payload)}`);
}

export function createBoothVersion(payload: SaveBoothVersionRequestModel) {
  return authorizedRequest.post<
    SaveBoothVersionResponseModel,
    SaveBoothVersionResponseModel
  >(`${PREFIX_API.GENERAL}/file-versions`, payload);
}

export function updateBoothVersion(payload: SaveBoothVersionRequestModel) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveBoothVersionResponseModel,
      SaveBoothVersionResponseModel
    >(`${PREFIX_API.GENERAL}/file-versions/${payload?.id}`, payload);
  }
}

export function deleteBoothVersion(payload: DeleteBoothVersionRequestModel) {
  return authorizedRequest.delete<
    DeleteBoothVersionResponseModel,
    DeleteBoothVersionResponseModel
  >(`${PREFIX_API.GENERAL}/file-versions/${payload?.id}`);
}

export function inactiveBoothVersion(
  payload: InactiveBoothVersionRequestModel,
) {
  return authorizedRequest.put<
    InactiveBoothVersionResponseModel,
    InactiveBoothVersionResponseModel
  >(`${PREFIX_API.GENERAL}/file-versions/${payload?.id}/inactive`);
}

export function activeBoothVersion(payload: ActiveBoothVersionRequestModel) {
  return authorizedRequest.put<
    ActiveBoothVersionResponseModel,
    ActiveBoothVersionResponseModel
  >(`${PREFIX_API.GENERAL}/file-versions/${payload?.id}/active`);
}
