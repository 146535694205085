import React, { memo } from 'react';
import isEqual from 'react-fast-compare';
import {
  GridColumn as SUIGridColumn,
  GridColumnProps as SUIGridColumnProps,
} from 'semantic-ui-react';

export type GridColumnProps = SUIGridColumnProps & {};

const GridColumn: React.FC<GridColumnProps> = ({ ...rest }) => (
  <SUIGridColumn {...rest} />
);

export default memo(GridColumn, isEqual);
