import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeSticker,
  createSticker,
  deleteSticker,
  getSticker,
  inactiveSticker,
  listSticker,
  updateSticker,
} from 'api/sticker/sticker.api';
import {
  ActiveStickerRequestModel,
  DeleteStickerRequestModel,
  GetStickerRequestModel,
  InactiveStickerRequestModel,
  ListStickerRequestModel,
  SaveStickerRequestModel,
} from 'models/sticker/sticker.model';

export const getStickerAction = createAsyncThunk(
  'GET_STICKER_REQUEST',
  async (payload: GetStickerRequestModel) => getSticker(payload),
);

export const listStickerAction = createAsyncThunk(
  'LIST_STICKER_REQUEST',
  async (payload: ListStickerRequestModel) => listSticker(payload),
);

export const createStickerAction = createAsyncThunk(
  'CREATE_STICKER_REQUEST',
  async (payload: SaveStickerRequestModel, { rejectWithValue }) => {
    try {
      return await createSticker(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateStickerAction = createAsyncThunk(
  'UPDATE_STICKER_REQUEST',
  async (payload: SaveStickerRequestModel, { rejectWithValue }) => {
    try {
      return await updateSticker(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteStickerAction = createAsyncThunk(
  'DELETE_STICKER_REQUEST',
  async (payload: DeleteStickerRequestModel, { rejectWithValue }) => {
    try {
      return await deleteSticker(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveStickerAction = createAsyncThunk(
  'INACTIVE_STICKER_REQUEST',
  async (payload: InactiveStickerRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveSticker(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeStickerAction = createAsyncThunk(
  'ACTIVE_STICKER_REQUEST',
  async (payload: ActiveStickerRequestModel, { rejectWithValue }) => {
    try {
      return await activeSticker(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
