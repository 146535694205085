import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { TransactionStateModel } from 'models/transaction/transaction.model';
import {
  exportTransactionAction,
  getTransactionAction,
  listTransactionAction,
  refundTransactionAction,
} from './transaction.action';

const initialState: TransactionStateModel = {
  transactionListData: null,

  transactionDetail: null,

  triggerUpdateTransaction: null,

  loadingDetail: false,

  loadingList: false,

  loadingExport: false,
};

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTransactionStateAction: (
      state,
      action: PayloadAction<Partial<TransactionStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as TransactionStateModel),
    resetTransactionStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionAction.pending, (state) => ({
        ...state,
        loadingDetail: true,
      }))
      .addCase(getTransactionAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingDetail: false,
        transactionDetail: payload?.data || null,
      }))
      .addCase(getTransactionAction.rejected, (state) => ({
        ...state,
        transactionDetail: null,
        loadingDetail: false,
      }))
      .addCase(listTransactionAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listTransactionAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        transactionListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
          totalRevenue: payload?.data?.totalRevenue || 0,
        },
      }))
      .addCase(listTransactionAction.rejected, (state) => ({
        ...state,
        transactionListData: null,
        loadingList: false,
      }))
      .addCase(refundTransactionAction.fulfilled, (state) => ({
        ...state,
        triggerUpdateTransaction: { id: uuidv4() },
      }))
      .addCase(exportTransactionAction.pending, (state) => ({
        ...state,
        loadingExport: true,
      }))
      .addCase(exportTransactionAction.fulfilled, (state) => ({
        ...state,
        loadingExport: false,
      }))
      .addCase(exportTransactionAction.rejected, (state) => ({
        ...state,
        loadingExport: false,
      }));
  },
});

export const { setTransactionStateAction, resetTransactionStateAction } =
  transactionSlice.actions;

export default transactionSlice.reducer;
