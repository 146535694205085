import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import {
  ListLayoutHasThemeRequestModel,
  ListLayoutHasThemeResponseModel,
  GetThemeLayoutRequestModel,
  GetThemeLayoutResponseModel,
} from 'models/theme/theme-layout.model';

export function getThemeLayout(payload: GetThemeLayoutRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetThemeLayoutResponseModel,
      GetThemeLayoutResponseModel
    >(`${PREFIX_API.GENERAL}/layout-themes/${payload?.id}`);
  }
}

export function listLayoutHasTheme(payload: ListLayoutHasThemeRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      ListLayoutHasThemeResponseModel,
      ListLayoutHasThemeResponseModel
    >(`${PREFIX_API.GENERAL}/layout-themes/theme/${payload?.id}`);
  }
}
