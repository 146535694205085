import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import funLogoImg from 'assets/images/fun_studio_logo.png';
import { Button, Form, Header, Image, Segment } from 'semantic-ui-react';
import { useReactForm } from 'hooks/useReactForm';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'hooks/useTranslation';
import HookFormInput from 'components/hook-form/HookFormInput';
import { useAppDispatch } from 'store/store-hooks';
import { loginAction } from 'store/features/auth/auth.action';
import { LoginRequestModel } from 'models/auth/auth.model';
import { handleDispatchThen } from 'helpers/api.helper';
import { AppRouteConst } from 'constants/route.const';
import { I18nNamespace } from 'constants/i18n.const';
import './login.css';

const Login = () => {
  const { T } = useTranslation([
    I18nNamespace.AUTH,
    I18nNamespace.COMMON,
    I18nNamespace.ERROR,
  ]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // hook form
  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        username: Yup.string()
          .trim()
          .required(`${T('error:userNameIsRequired')}`),
        password: Yup.string()
          .trim()
          .required(`${T('error:passwordIsRequired')}`),
      }),
    [T],
  );

  const formMethods = useReactForm({
    formSchema,
  });
  const { handleSubmit } = formMethods;

  const handleSubmitForm = useCallback(
    (submitData: LoginRequestModel) => {
      setIsSubmitting(true);
      dispatch(loginAction({ ...submitData }))
        .then((res) => {
          handleDispatchThen(
            res,
            () => navigate(AppRouteConst.DASHBOARD),
            T('auth:successLogin'),
            T('error:emailOrPasswordIsIncorrect'),
          );
        })
        .finally(() => setIsSubmitting(false));
    },
    [dispatch, navigate, T],
  );

  return (
    <>
      <Header as="h2" color="pink" textAlign="center">
        <Image className="fun-logo-login" src={funLogoImg} />{' '}
        {T('common:login')}
      </Header>
      <FormProvider {...formMethods}>
        <Form size="large" onSubmit={handleSubmit(handleSubmitForm)}>
          <Segment>
            <HookFormInput
              fluid
              icon="user"
              name="username"
              iconPosition="left"
              placeholder={T('auth:username')}
            />
            <HookFormInput
              fluid
              icon="lock"
              name="password"
              iconPosition="left"
              isPasswordField
              placeholder={T('auth:password')}
            />

            <Button
              type="submit"
              color="pink"
              fluid
              size="large"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {T('login')}
            </Button>
          </Segment>
        </Form>
      </FormProvider>
    </>
  );
};

export default Login;
