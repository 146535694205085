import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeNews,
  createNews,
  deleteNews,
  getNews,
  inactiveNews,
  listNews,
  updateNews,
} from 'api/setting/news/news.api';
import {
  ActiveNewsRequestModel,
  DeleteNewsRequestModel,
  GetNewsRequestModel,
  InactiveNewsRequestModel,
  ListNewsRequestModel,
  SaveNewsRequestModel,
} from 'models/setting/news/news.model';

export const getNewsAction = createAsyncThunk(
  'GET_NEWS_REQUEST',
  async (payload: GetNewsRequestModel) => getNews(payload),
);

export const listNewsAction = createAsyncThunk(
  'LIST_NEWS_REQUEST',
  async (payload: ListNewsRequestModel) => listNews(payload),
);

export const createNewsAction = createAsyncThunk(
  'CREATE_NEWS_REQUEST',
  async (payload: SaveNewsRequestModel, { rejectWithValue }) => {
    try {
      return await createNews(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateNewsAction = createAsyncThunk(
  'UPDATE_NEWS_REQUEST',
  async (payload: SaveNewsRequestModel, { rejectWithValue }) => {
    try {
      return await updateNews(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteNewsAction = createAsyncThunk(
  'DELETE_NEWS_REQUEST',
  async (payload: DeleteNewsRequestModel, { rejectWithValue }) => {
    try {
      return await deleteNews(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveNewsAction = createAsyncThunk(
  'INACTIVE_NEWS_REQUEST',
  async (payload: InactiveNewsRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveNews(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeNewsAction = createAsyncThunk(
  'ACTIVE_NEWS_REQUEST',
  async (payload: ActiveNewsRequestModel, { rejectWithValue }) => {
    try {
      return await activeNews(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
