import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveCapturePositionTemplateRequestModel,
  ActiveCapturePositionTemplateResponseModel,
  DeleteCapturePositionTemplateRequestModel,
  DeleteCapturePositionTemplateResponseModel,
  GetCapturePositionTemplateRequestModel,
  GetCapturePositionTemplateResponseModel,
  InactiveCapturePositionTemplateRequestModel,
  InactiveCapturePositionTemplateResponseModel,
  ListCapturePositionTemplateRequestModel,
  ListCapturePositionTemplateResponseModel,
  SaveCapturePositionTemplateRequestModel,
  SaveCapturePositionTemplateResponseModel,
} from 'models/setting/capture-position/capture-position-template.model';

export function getCapturePositionTemplate(
  payload: GetCapturePositionTemplateRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetCapturePositionTemplateResponseModel,
      GetCapturePositionTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/capture-position-lists/${payload?.id}`);
  }
}

export function listCapturePositionTemplate(
  payload: ListCapturePositionTemplateRequestModel,
) {
  return authorizedRequest.get<
    ListCapturePositionTemplateResponseModel,
    ListCapturePositionTemplateResponseModel
  >(
    `${PREFIX_API.GENERAL}/capture-position-lists?${preprocessGetQuery(
      payload,
    )}`,
  );
}

export function createCapturePositionTemplate(
  payload: SaveCapturePositionTemplateRequestModel,
) {
  return authorizedRequest.post<
    SaveCapturePositionTemplateResponseModel,
    SaveCapturePositionTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/capture-position-lists`, payload);
}

export function updateCapturePositionTemplate(
  payload: SaveCapturePositionTemplateRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveCapturePositionTemplateResponseModel,
      SaveCapturePositionTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/capture-position-lists/${payload?.id}`, payload);
  }
}

export function deleteCapturePositionTemplate(
  payload: DeleteCapturePositionTemplateRequestModel,
) {
  return authorizedRequest.delete<
    DeleteCapturePositionTemplateResponseModel,
    DeleteCapturePositionTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/capture-position-lists/${payload?.id}`);
}

export function inactiveCapturePositionTemplate(
  payload: InactiveCapturePositionTemplateRequestModel,
) {
  return authorizedRequest.put<
    InactiveCapturePositionTemplateResponseModel,
    InactiveCapturePositionTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/capture-position-lists/${payload?.id}/inactive`);
}

export function activeCapturePositionTemplate(
  payload: ActiveCapturePositionTemplateRequestModel,
) {
  return authorizedRequest.put<
    ActiveCapturePositionTemplateResponseModel,
    ActiveCapturePositionTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/capture-position-lists/${payload?.id}/active`);
}
