import { DListResponse, DObjectResponse } from 'models/common.model';

export type LocationModel = {
  id: number;
  parentId: number;
  name: string;
  type: string;
  orderNo: number;
  isActive: boolean;
};

export enum LocationType {
  COUNTRY = 'Country',
  PROVINCE = 'Province',
  DISTRICT = 'District',
  WARD = 'Ward',
}

export type ListLocationRequestModel = {
  pageIndex?: number;
  pageSize?: number;
  parentId?: number;
  name?: string;
  typeId?: LocationType;
  isActive?: boolean;
};
export type ListLocationResponseModel = DListResponse<LocationModel>;

export type SaveLocationRequestModel = {
  id?: number;
  parentId: number;
  name: string;
  type: number;
  orderNo: number;
  userId: string;
  isActive: boolean;
};
export type SaveLocationResponseModel = DObjectResponse<boolean>;

export type DeleteLocationRequestModel = {
  id?: number;
};
export type DeleteLocationResponseModel = DObjectResponse<boolean>;

export interface LocationStateModel {
  listProvinces: LocationModel[];
  listDistricts: LocationModel[];
  listWards: LocationModel[];
}
