interface TokenManager {
  token: string;
  refreshToken: string;
  setToken: (token: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  doLogout: () => void;
}

export const tokenManager: TokenManager = {
  token: '',
  refreshToken: '',
  setToken(t: string) {
    this.token = t;
  },
  setRefreshToken(t: string) {
    this.refreshToken = t;
  },
  doLogout() {
    this.token = '';
    this.refreshToken = '';
  },
};
