import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LayoutTemplateStateModel } from 'models/layout/layout-template.model';
import { v4 as uuidv4 } from 'uuid';
import {
  activeLayoutTemplateAction,
  createLayoutTemplateAction,
  deleteLayoutTemplateAction,
  getLayoutTemplateAction,
  inactiveLayoutTemplateAction,
  listLayoutTemplateAction,
  updateLayoutTemplateAction,
} from './layout-template.action';

const initialState: LayoutTemplateStateModel = {
  layoutTemplateListData: null,
  layoutTemplateDetail: null,
  loadingList: false,
  triggerSaveLayoutTemplate: null,
};

export const layoutTemplateSlice = createSlice({
  name: 'layoutTemplate',
  initialState,
  reducers: {
    setLayoutTemplateStateAction: (
      state,
      action: PayloadAction<Partial<LayoutTemplateStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as LayoutTemplateStateModel),
    resetLayoutTemplateStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLayoutTemplateAction.pending, (state) => ({
        ...state,
        layoutTemplateDetail: null,
      }))
      .addCase(getLayoutTemplateAction.fulfilled, (state, { payload }) => ({
        ...state,
        layoutTemplateDetail: payload?.data || null,
      }))
      .addCase(getLayoutTemplateAction.rejected, (state) => ({
        ...state,
        layoutTemplateDetail: null,
      }))
      .addCase(listLayoutTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listLayoutTemplateAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        layoutTemplateListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listLayoutTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createLayoutTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveLayoutTemplate: { id: uuidv4() },
      }))
      .addCase(updateLayoutTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveLayoutTemplate: { id: uuidv4() },
      }))

      .addCase(deleteLayoutTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteLayoutTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveLayoutTemplate: { id: uuidv4() },
      }))
      .addCase(deleteLayoutTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeLayoutTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveLayoutTemplate: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveLayoutTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveLayoutTemplate: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setLayoutTemplateStateAction, resetLayoutTemplateStateAction } =
  layoutTemplateSlice.actions;

export default layoutTemplateSlice.reducer;
