import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { BoothBackgroundCategoryStateModel } from 'models/booth-background/booth-background-category.model';
import {
  activeBoothBackgroundCategoryAction,
  createBoothBackgroundCategoryAction,
  deleteBoothBackgroundCategoryAction,
  getBoothBackgroundCategoryAction,
  inactiveBoothBackgroundCategoryAction,
  listBoothBackgroundCategoryAction,
  updateBoothBackgroundCategoryAction,
} from './booth-background-category.action';

const initialState: BoothBackgroundCategoryStateModel = {
  boothBackgroundCategoryListData: null,
  boothBackgroundCategoryDetail: null,
  loadingList: false,
  triggerSaveBoothBackgroundCategory: null,
};

export const boothBackgroundCategorySlice = createSlice({
  name: 'booth-backgroundCategory',
  initialState,
  reducers: {
    setBoothBackgroundCategoryStateAction: (
      state,
      action: PayloadAction<Partial<BoothBackgroundCategoryStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as BoothBackgroundCategoryStateModel),
    resetBoothBackgroundCategoryStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoothBackgroundCategoryAction.pending, (state) => ({
        ...state,
        boothBackgroundCategoryDetail: null,
      }))
      .addCase(
        getBoothBackgroundCategoryAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          boothBackgroundCategoryDetail: payload?.data || null,
        }),
      )
      .addCase(getBoothBackgroundCategoryAction.rejected, (state) => ({
        ...state,
        boothBackgroundCategoryDetail: null,
      }))
      .addCase(listBoothBackgroundCategoryAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(
        listBoothBackgroundCategoryAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          loadingList: false,
          boothBackgroundCategoryListData: {
            items: payload?.data?.items || [],
            totalItems: payload?.data?.totalItems || 0,
            totalPages: payload?.data?.totalPages || 0,
          },
        }),
      )
      .addCase(listBoothBackgroundCategoryAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createBoothBackgroundCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackgroundCategory: { id: uuidv4() },
      }))
      .addCase(updateBoothBackgroundCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackgroundCategory: { id: uuidv4() },
      }))

      .addCase(deleteBoothBackgroundCategoryAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteBoothBackgroundCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackgroundCategory: { id: uuidv4() },
      }))
      .addCase(deleteBoothBackgroundCategoryAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeBoothBackgroundCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackgroundCategory: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveBoothBackgroundCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackgroundCategory: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const {
  setBoothBackgroundCategoryStateAction,
  resetBoothBackgroundCategoryStateAction,
} = boothBackgroundCategorySlice.actions;

export default boothBackgroundCategorySlice.reducer;
