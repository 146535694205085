import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createLayout,
  deleteLayout,
  getLayout,
  listLayout,
  updateLayout,
} from 'api/layout/layout.api';
import {
  DeleteLayoutRequestModel,
  GetLayoutRequestModel,
  ListLayoutRequestModel,
  SaveLayoutRequestModel,
} from 'models/layout/layout.model';

export const getLayoutAction = createAsyncThunk(
  'GET_LAYOUT_REQUEST',
  async (payload: GetLayoutRequestModel) => getLayout(payload),
);

export const listLayoutAction = createAsyncThunk(
  'LIST_LAYOUT_REQUEST',
  async (payload: ListLayoutRequestModel) => listLayout(payload),
);

export const createLayoutAction = createAsyncThunk(
  'CREATE_LAYOUT_REQUEST',
  async (payload: SaveLayoutRequestModel, { rejectWithValue }) => {
    try {
      return await createLayout(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateLayoutAction = createAsyncThunk(
  'UPDATE_LAYOUT_REQUEST',
  async (payload: SaveLayoutRequestModel, { rejectWithValue }) => {
    try {
      return await updateLayout(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteLayoutAction = createAsyncThunk(
  'DELETE_LAYOUT_REQUEST',
  async (payload: DeleteLayoutRequestModel, { rejectWithValue }) => {
    try {
      return await deleteLayout(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
