import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DashboardStateModel } from 'models/dashboard/dashboard.model';
import {
  getSummaryRevenueStatisticAction,
  listAnnualRevenueStatisticAction,
  listCaptureModeStatisticAction,
  listDailyRevenueStatisticAction,
  listLayoutStatisticAction,
  listMonthlyRevenueStatisticAction,
  listThemeStatisticAction,
} from './dashboard.action';

const initialState: DashboardStateModel = {
  summaryRevenueStatistic: null,
  listLayoutStatistic: [],
  listCaptureModeStatistic: [],
  listThemeStatistic: [],
  listAnnualRevenueStatistic: null,
  listMonthlyRevenueStatistic: null,
  listDailyRevenueStatistic: null,

  loadingSummaryRevenueStatistic: false,
  loadingLayoutStatistic: false,
  loadingCaptureModeStatistic: false,
  loadingThemeStatistic: false,
  loadingAnnualRevenueStatistic: false,
  loadingMonthlyRevenueStatistic: false,
  loadingDailyRevenueStatistic: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardStateAction: (
      state,
      action: PayloadAction<Partial<DashboardStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as DashboardStateModel),
    resetDashboardStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryRevenueStatisticAction.pending, (state) => ({
        ...state,
        summaryRevenueStatistic: null,
        loadingSummaryRevenueStatistic: true,
      }))
      .addCase(
        getSummaryRevenueStatisticAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          summaryRevenueStatistic: payload?.data || null,
          loadingSummaryRevenueStatistic: false,
        }),
      )
      .addCase(getSummaryRevenueStatisticAction.rejected, (state) => ({
        ...state,
        summaryRevenueStatistic: null,
        loadingSummaryRevenueStatistic: false,
      }))

      .addCase(listAnnualRevenueStatisticAction.pending, (state) => ({
        ...state,
        listAnnualRevenueStatistic: null,
        loadingAnnualRevenueStatistic: true,
      }))
      .addCase(
        listAnnualRevenueStatisticAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listAnnualRevenueStatistic: payload?.data || null,
          loadingAnnualRevenueStatistic: false,
        }),
      )
      .addCase(listAnnualRevenueStatisticAction.rejected, (state) => ({
        ...state,
        listAnnualRevenueStatistic: null,
        loadingAnnualRevenueStatistic: false,
      }))
      .addCase(listMonthlyRevenueStatisticAction.pending, (state) => ({
        ...state,
        listMonthlyRevenueStatistic: null,
        loadingMonthlyRevenueStatistic: true,
      }))
      .addCase(
        listMonthlyRevenueStatisticAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listMonthlyRevenueStatistic: payload?.data || null,
          loadingMonthlyRevenueStatistic: false,
        }),
      )
      .addCase(listMonthlyRevenueStatisticAction.rejected, (state) => ({
        ...state,
        listMonthlyRevenueStatistic: null,
        loadingMonthlyRevenueStatistic: false,
      }))

      .addCase(listDailyRevenueStatisticAction.pending, (state) => ({
        ...state,
        listDailyRevenueStatistic: null,
        loadingDailyRevenueStatistic: true,
      }))
      .addCase(
        listDailyRevenueStatisticAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listDailyRevenueStatistic: payload?.data || null,
          loadingDailyRevenueStatistic: false,
        }),
      )
      .addCase(listDailyRevenueStatisticAction.rejected, (state) => ({
        ...state,
        listDailyRevenueStatistic: null,
        loadingDailyRevenueStatistic: false,
      }))
      .addCase(listLayoutStatisticAction.pending, (state) => ({
        ...state,
        listLayoutStatistic: [],
        loadingLayoutStatistic: true,
      }))
      .addCase(listLayoutStatisticAction.fulfilled, (state, { payload }) => ({
        ...state,
        listLayoutStatistic: payload?.data || [],
        loadingLayoutStatistic: false,
      }))
      .addCase(listLayoutStatisticAction.rejected, (state) => ({
        ...state,
        listLayoutStatistic: [],
        loadingLayoutStatistic: false,
      }))
      .addCase(listCaptureModeStatisticAction.pending, (state) => ({
        ...state,
        listCaptureModeStatistic: [],
        loadingCaptureModeStatistic: true,
      }))
      .addCase(
        listCaptureModeStatisticAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listCaptureModeStatistic: payload?.data || [],
          loadingCaptureModeStatistic: false,
        }),
      )
      .addCase(listCaptureModeStatisticAction.rejected, (state) => ({
        ...state,
        listCaptureModeStatistic: [],
        loadingCaptureModeStatistic: false,
      }))
      .addCase(listThemeStatisticAction.pending, (state) => ({
        ...state,
        listThemeStatistic: [],
        loadingMonthlyRevenueStatistic: true,
      }))
      .addCase(listThemeStatisticAction.fulfilled, (state, { payload }) => ({
        ...state,
        listThemeStatistic: payload?.data || [],
        loadingMonthlyRevenueStatistic: false,
      }))
      .addCase(listThemeStatisticAction.rejected, (state) => ({
        ...state,
        listThemeStatistic: [],
        loadingMonthlyRevenueStatistic: false,
      }));
  },
});

export const { setDashboardStateAction, resetDashboardStateAction } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
