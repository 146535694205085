import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  listAnnualRevenueStatistic,
  listCaptureModeStatistic,
  listLayoutStatistic,
  listMonthlyRevenueStatistic,
  getSummaryRevenueStatistic,
  listThemeStatistic,
  listDailyRevenueStatistic,
} from 'api/dashboard/dashboard.api';
import {
  ListAnnualRevenueStatisticRequestModel,
  ListCaptureModeStatisticRequestModel,
  ListLayoutStatisticRequestModel,
  ListMonthlyRevenueStatisticRequestModel,
  GetSummaryRevenueStatisticRequestModel,
  ListThemeStatisticRequestModel,
  ListDailyRevenueStatisticRequestModel,
} from 'models/dashboard/dashboard.model';

export const getSummaryRevenueStatisticAction = createAsyncThunk(
  'GET_SUMMARY_REVENUE_STATISTIC_REQUEST',
  async (
    payload: GetSummaryRevenueStatisticRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await getSummaryRevenueStatistic(payload);
    } catch (err: any) {
      // TODO: refactor types
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const listAnnualRevenueStatisticAction = createAsyncThunk(
  'LIST_ANNUAL_REVENUE_STATISTIC_REQUEST',
  async (
    payload: ListAnnualRevenueStatisticRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await listAnnualRevenueStatistic(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const listMonthlyRevenueStatisticAction = createAsyncThunk(
  'LIST_MONTHLY_REVENUE_STATISTIC_REQUEST',
  async (
    payload: ListMonthlyRevenueStatisticRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await listMonthlyRevenueStatistic(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const listDailyRevenueStatisticAction = createAsyncThunk(
  'LIST_DAILY_REVENUE_STATISTIC_REQUEST',
  async (
    payload: ListDailyRevenueStatisticRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await listDailyRevenueStatistic(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const listLayoutStatisticAction = createAsyncThunk(
  'LIST_LAYOUT_STATISTIC_REQUEST',
  async (payload: ListLayoutStatisticRequestModel) =>
    listLayoutStatistic(payload),
);

export const listCaptureModeStatisticAction = createAsyncThunk(
  'LIST_CAPTURE_MODE_STATISTIC_REQUEST',
  async (payload: ListCaptureModeStatisticRequestModel) =>
    listCaptureModeStatistic(payload),
);

export const listThemeStatisticAction = createAsyncThunk(
  'LIST_THEME_STATISTIC_REQUEST',
  async (payload: ListThemeStatisticRequestModel) =>
    listThemeStatistic(payload),
);
