import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeLayoutTemplate,
  createLayoutTemplate,
  deleteLayoutTemplate,
  getLayoutTemplate,
  inactiveLayoutTemplate,
  listLayoutTemplate,
  updateLayoutTemplate,
} from 'api/layout/layout-template.api';
import {
  ActiveLayoutTemplateRequestModel,
  DeleteLayoutTemplateRequestModel,
  GetLayoutTemplateRequestModel,
  InactiveLayoutTemplateRequestModel,
  ListLayoutTemplateRequestModel,
  SaveLayoutTemplateRequestModel,
} from 'models/layout/layout-template.model';

export const getLayoutTemplateAction = createAsyncThunk(
  'GET_LAYOUT_TEMPLATE_REQUEST',
  async (payload: GetLayoutTemplateRequestModel) => getLayoutTemplate(payload),
);

export const listLayoutTemplateAction = createAsyncThunk(
  'LIST_LAYOUT_TEMPLATE_REQUEST',
  async (payload: ListLayoutTemplateRequestModel) =>
    listLayoutTemplate(payload),
);

export const createLayoutTemplateAction = createAsyncThunk(
  'CREATE_LAYOUT_TEMPLATE_REQUEST',
  async (payload: SaveLayoutTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await createLayoutTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateLayoutTemplateAction = createAsyncThunk(
  'UPDATE_LAYOUT_TEMPLATE_REQUEST',
  async (payload: SaveLayoutTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await updateLayoutTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteLayoutTemplateAction = createAsyncThunk(
  'DELETE_LAYOUT_TEMPLATE_REQUEST',
  async (payload: DeleteLayoutTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await deleteLayoutTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveLayoutTemplateAction = createAsyncThunk(
  'INACTIVE_LAYOUT_TEMPLATE_REQUEST',
  async (payload: InactiveLayoutTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveLayoutTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeLayoutTemplateAction = createAsyncThunk(
  'ACTIVE_LAYOUT_TEMPLATE_REQUEST',
  async (payload: ActiveLayoutTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await activeLayoutTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
