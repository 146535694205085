import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeTheme,
  createTheme,
  createThemeLayout,
  deleteTheme,
  getTheme,
  inactiveTheme,
  listTheme,
  updateTheme,
  updateThemeLayout,
} from 'api/theme/theme.api';
import {
  ActiveThemeRequestModel,
  DeleteThemeRequestModel,
  GetThemeRequestModel,
  InactiveThemeRequestModel,
  ListThemeRequestModel,
  SaveThemeLayoutRequestModel,
  SaveThemeRequestModel,
} from 'models/theme/theme.model';

export const getThemeAction = createAsyncThunk(
  'GET_THEME_REQUEST',
  async (payload: GetThemeRequestModel) => getTheme(payload),
);

export const listThemeAction = createAsyncThunk(
  'LIST_THEME_REQUEST',
  async (payload: ListThemeRequestModel) => listTheme(payload),
);

export const createThemeAction = createAsyncThunk(
  'CREATE_THEME_REQUEST',
  async (payload: SaveThemeRequestModel, { rejectWithValue }) => {
    try {
      return await createTheme(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateThemeAction = createAsyncThunk(
  'UPDATE_THEME_REQUEST',
  async (payload: SaveThemeRequestModel, { rejectWithValue }) => {
    try {
      return await updateTheme(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const createThemeLayoutAction = createAsyncThunk(
  'CREATE_THEME_LAYOUT_REQUEST',
  async (payload: SaveThemeLayoutRequestModel, { rejectWithValue }) => {
    try {
      return await createThemeLayout(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateThemeLayoutAction = createAsyncThunk(
  'UPDATE_THEME_LAYOUT_REQUEST',
  async (payload: SaveThemeLayoutRequestModel, { rejectWithValue }) => {
    try {
      return await updateThemeLayout(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteThemeAction = createAsyncThunk(
  'DELETE_THEME_REQUEST',
  async (payload: DeleteThemeRequestModel, { rejectWithValue }) => {
    try {
      return await deleteTheme(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveThemeAction = createAsyncThunk(
  'INACTIVE_THEME_REQUEST',
  async (payload: InactiveThemeRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveTheme(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeThemeAction = createAsyncThunk(
  'ACTIVE_THEME_REQUEST',
  async (payload: ActiveThemeRequestModel, { rejectWithValue }) => {
    try {
      return await activeTheme(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
