import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { BoothVersionStateModel } from 'models/setting/booth-version/booth-version.model';
import {
  activeBoothVersionAction,
  createBoothVersionAction,
  deleteBoothVersionAction,
  getBoothVersionAction,
  inactiveBoothVersionAction,
  listBoothVersionAction,
  updateBoothVersionAction,
} from './booth-version.action';

const initialState: BoothVersionStateModel = {
  boothVersionListData: null,
  boothVersionDetail: null,
  triggerSaveBoothVersion: null,

  loadingList: false,
  loadingDetail: false,
};

export const boothVersionSlice = createSlice({
  name: 'booth-version',
  initialState,
  reducers: {
    setBoothVersionStateAction: (
      state,
      action: PayloadAction<Partial<BoothVersionStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as BoothVersionStateModel),
    resetBoothVersionStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoothVersionAction.pending, (state) => ({
        ...state,
        boothVersionDetail: null,
        loadingDetail: true,
      }))
      .addCase(getBoothVersionAction.fulfilled, (state, { payload }) => ({
        ...state,
        boothVersionDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getBoothVersionAction.rejected, (state) => ({
        ...state,
        boothVersionDetail: null,
        loadingDetail: false,
      }))
      .addCase(listBoothVersionAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listBoothVersionAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        boothVersionListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listBoothVersionAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createBoothVersionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothVersion: { id: uuidv4() },
      }))
      .addCase(updateBoothVersionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothVersion: { id: uuidv4() },
      }))

      .addCase(deleteBoothVersionAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteBoothVersionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothVersion: { id: uuidv4() },
      }))
      .addCase(deleteBoothVersionAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeBoothVersionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothVersion: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveBoothVersionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothVersion: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setBoothVersionStateAction, resetBoothVersionStateAction } =
  boothVersionSlice.actions;

export default boothVersionSlice.reducer;
