/* eslint-disable no-console */
import React, { ErrorInfo, PropsWithChildren, memo } from 'react';
import isEqual from 'react-fast-compare';

type StateTypes = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component {
  constructor(props: PropsWithChildren<any>) {
    super(props);
    this.state = { hasError: false } as StateTypes;
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    console.log('ttt getDerivedStateFromError', error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log('ttt componentDidCatch', error, errorInfo);
  }

  render() {
    if ((this.state as StateTypes).hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return (this.props as PropsWithChildren<any>).children;
  }
}

export default memo(ErrorBoundary, isEqual);
