import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import { getFormData } from 'helpers/form-data.helper';
import {
  ActiveThemeRequestModel,
  ActiveThemeResponseModel,
  DeleteThemeRequestModel,
  DeleteThemeResponseModel,
  GetThemeRequestModel,
  GetThemeResponseModel,
  InactiveThemeRequestModel,
  InactiveThemeResponseModel,
  ListThemeRequestModel,
  ListThemeResponseModel,
  SaveThemeLayoutRequestModel,
  SaveThemeLayoutResponseModel,
  SaveThemeRequestModel,
  SaveThemeResponseModel,
} from 'models/theme/theme.model';

export function getTheme(payload: GetThemeRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<GetThemeResponseModel, GetThemeResponseModel>(
      `${PREFIX_API.GENERAL}/themes/${payload?.id}`,
    );
  }
}

export function listTheme(payload: ListThemeRequestModel) {
  return authorizedRequest.get<ListThemeResponseModel, ListThemeResponseModel>(
    `${PREFIX_API.GENERAL}/themes?${preprocessGetQuery(payload)}`,
  );
}

export function createTheme(payload: SaveThemeRequestModel) {
  return authorizedRequest.post<SaveThemeResponseModel, SaveThemeResponseModel>(
    `${PREFIX_API.GENERAL}/themes`,
    payload,
  );
}

export function updateTheme(payload: SaveThemeRequestModel) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveThemeResponseModel,
      SaveThemeResponseModel
    >(`${PREFIX_API.GENERAL}/themes/${payload?.id}`, payload);
  }
}

export function createThemeLayout(payload: SaveThemeLayoutRequestModel) {
  const formDataPayload = getFormData(payload);

  return authorizedRequest.post<
    SaveThemeLayoutResponseModel,
    SaveThemeLayoutResponseModel
  >(`${PREFIX_API.GENERAL}/themes/theme-layouts`, formDataPayload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function updateThemeLayout(payload: SaveThemeLayoutRequestModel) {
  if (payload?.id) {
    const formDataPayload = getFormData(payload);

    return authorizedRequest.put<
      SaveThemeLayoutResponseModel,
      SaveThemeLayoutResponseModel
    >(
      `${PREFIX_API.GENERAL}/themes/theme-layouts/${payload?.id}`,
      formDataPayload,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
  }
}

export function deleteTheme(payload: DeleteThemeRequestModel) {
  return authorizedRequest.delete<
    DeleteThemeResponseModel,
    DeleteThemeResponseModel
  >(`${PREFIX_API.GENERAL}/themes/${payload?.id}`);
}

export function inactiveTheme(payload: InactiveThemeRequestModel) {
  return authorizedRequest.put<
    InactiveThemeResponseModel,
    InactiveThemeResponseModel
  >(`${PREFIX_API.GENERAL}/themes/${payload?.id}/inactive`);
}

export function activeTheme(payload: ActiveThemeRequestModel) {
  return authorizedRequest.put<
    ActiveThemeResponseModel,
    ActiveThemeResponseModel
  >(`${PREFIX_API.GENERAL}/themes/${payload?.id}/active`);
}
