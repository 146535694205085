import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeBoothVersion,
  createBoothVersion,
  deleteBoothVersion,
  getBoothVersion,
  inactiveBoothVersion,
  listBoothVersion,
  updateBoothVersion,
} from 'api/setting/booth-version/booth-version.api';
import {
  ActiveBoothVersionRequestModel,
  DeleteBoothVersionRequestModel,
  GetBoothVersionRequestModel,
  InactiveBoothVersionRequestModel,
  ListBoothVersionRequestModel,
  SaveBoothVersionRequestModel,
} from 'models/setting/booth-version/booth-version.model';

export const getBoothVersionAction = createAsyncThunk(
  'GET_BOOTH_VERSION_REQUEST',
  async (payload: GetBoothVersionRequestModel) => getBoothVersion(payload),
);

export const listBoothVersionAction = createAsyncThunk(
  'LIST_BOOTH_VERSION_REQUEST',
  async (payload: ListBoothVersionRequestModel) => listBoothVersion(payload),
);

export const createBoothVersionAction = createAsyncThunk(
  'CREATE_BOOTH_VERSION_REQUEST',
  async (payload: SaveBoothVersionRequestModel, { rejectWithValue }) => {
    try {
      return await createBoothVersion(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateBoothVersionAction = createAsyncThunk(
  'UPDATE_BOOTH_VERSION_REQUEST',
  async (payload: SaveBoothVersionRequestModel, { rejectWithValue }) => {
    try {
      return await updateBoothVersion(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteBoothVersionAction = createAsyncThunk(
  'DELETE_BOOTH_VERSION_REQUEST',
  async (payload: DeleteBoothVersionRequestModel, { rejectWithValue }) => {
    try {
      return await deleteBoothVersion(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveBoothVersionAction = createAsyncThunk(
  'INACTIVE_BOOTH_VERSION_REQUEST',
  async (payload: InactiveBoothVersionRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveBoothVersion(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeBoothVersionAction = createAsyncThunk(
  'ACTIVE_BOOTH_VERSION_REQUEST',
  async (payload: ActiveBoothVersionRequestModel, { rejectWithValue }) => {
    try {
      return await activeBoothVersion(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
