import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import { getFormData } from 'helpers/form-data.helper';
import {
  ActiveStickerRequestModel,
  ActiveStickerResponseModel,
  DeleteStickerRequestModel,
  DeleteStickerResponseModel,
  GetStickerRequestModel,
  GetStickerResponseModel,
  InactiveStickerRequestModel,
  InactiveStickerResponseModel,
  ListStickerRequestModel,
  ListStickerResponseModel,
  SaveStickerRequestModel,
  SaveStickerResponseModel,
} from 'models/sticker/sticker.model';

export function getSticker(payload: GetStickerRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetStickerResponseModel,
      GetStickerResponseModel
    >(`${PREFIX_API.GENERAL}/stickers/${payload?.id}`);
  }
}

export function listSticker(payload: ListStickerRequestModel) {
  return authorizedRequest.get<
    ListStickerResponseModel,
    ListStickerResponseModel
  >(`${PREFIX_API.GENERAL}/stickers?${preprocessGetQuery(payload)}`);
}

export function createSticker(payload: SaveStickerRequestModel) {
  const formDataPayload = getFormData(payload);

  return authorizedRequest.post<
    SaveStickerResponseModel,
    SaveStickerResponseModel
  >(`${PREFIX_API.GENERAL}/stickers`, formDataPayload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function updateSticker(payload: SaveStickerRequestModel) {
  const formDataPayload = getFormData(payload);

  if (payload?.id) {
    return authorizedRequest.put<
      SaveStickerResponseModel,
      SaveStickerResponseModel
    >(`${PREFIX_API.GENERAL}/stickers/${payload?.id}`, formDataPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export function deleteSticker(payload: DeleteStickerRequestModel) {
  return authorizedRequest.delete<
    DeleteStickerResponseModel,
    DeleteStickerResponseModel
  >(`${PREFIX_API.GENERAL}/stickers/${payload?.id}`);
}

export function inactiveSticker(payload: InactiveStickerRequestModel) {
  return authorizedRequest.put<
    InactiveStickerResponseModel,
    InactiveStickerResponseModel
  >(`${PREFIX_API.GENERAL}/stickers/${payload?.id}/inactive`);
}

export function activeSticker(payload: ActiveStickerRequestModel) {
  return authorizedRequest.put<
    ActiveStickerResponseModel,
    ActiveStickerResponseModel
  >(`${PREFIX_API.GENERAL}/stickers/${payload?.id}/active`);
}
