import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { BE_DEFINES } from 'constants/backend-defines.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ExportReportErrorBoothRequestModel,
  ExportReportErrorBoothResponseModel,
  GetShootingCountSummaryRequestModel,
  GetShootingCountSummaryResponseModel,
  ListAnnualShootingCountRequestModel,
  ListAnnualShootingCountResponseModel,
  ListMonthlyShootingCountRequestModel,
  ListMonthlyShootingCountResponseModel,
  ListReportDepositLogRequestModel,
  ListReportDepositLogResponseModel,
  ListReportErrorBoothRequestModel,
  ListReportErrorBoothResponseModel,
  ListReportShootingDurationRequestModel,
  ListReportShootingDurationResponseModel,
} from 'models/report/report.model';

export function getShootingCountSummary(
  payload: GetShootingCountSummaryRequestModel,
) {
  return authorizedRequest.get<
    GetShootingCountSummaryResponseModel,
    GetShootingCountSummaryResponseModel
  >(
    `${PREFIX_API.REPORT}/transactions/current-revenue?${preprocessGetQuery({
      ...(payload || {}),
      type: BE_DEFINES.REPORT_REVENUE_SHOOTING_TYPES.SHOOTING_COUNT,
    })}`,
  );
}

export function listAnnualShootingCount(
  payload: ListAnnualShootingCountRequestModel,
) {
  return authorizedRequest.get<
    ListAnnualShootingCountResponseModel,
    ListAnnualShootingCountResponseModel
  >(
    `${PREFIX_API.REPORT}/stores/revenue-year?${preprocessGetQuery({
      ...(payload || {}),
      type: BE_DEFINES.REPORT_REVENUE_SHOOTING_TYPES.SHOOTING_COUNT,
    })}`,
  );
}

export function listMonthlyShootingCount(
  payload: ListMonthlyShootingCountRequestModel,
) {
  return authorizedRequest.get<
    ListMonthlyShootingCountResponseModel,
    ListMonthlyShootingCountResponseModel
  >(
    `${PREFIX_API.REPORT}/stores/revenue-month?${preprocessGetQuery({
      ...(payload || {}),
      type: BE_DEFINES.REPORT_REVENUE_SHOOTING_TYPES.SHOOTING_COUNT,
    })}`,
  );
}

// báo cáo thời gian chụp
export function listReportShootingDuration(
  _payload: ListReportShootingDurationRequestModel,
) {
  return authorizedRequest.get<
    ListReportShootingDurationResponseModel,
    ListReportShootingDurationResponseModel
  >(`${PREFIX_API.REPORT}/transactions/duration`);
}

// báo cáo nạp tiền
export function listReportDepositLog(
  payload: ListReportDepositLogRequestModel,
) {
  return authorizedRequest.get<
    ListReportDepositLogResponseModel,
    ListReportDepositLogResponseModel
  >(
    `${PREFIX_API.GENERAL}/deposit-logs?${preprocessGetQuery({
      ...(payload || {}),
    })}`,
  );
}

// Báo cáo thống kê booth lỗi
export function listReportErrorBooth(
  payload: ListReportErrorBoothRequestModel,
) {
  return authorizedRequest.get<
    ListReportErrorBoothResponseModel,
    ListReportErrorBoothResponseModel
  >(
    `${
      PREFIX_API.REPORT
    }/transactions/error-transaction-ratio?${preprocessGetQuery({
      ...(payload || {}),
    })}`,
  );
}

export function exportReportErrorBooth(
  payload: ExportReportErrorBoothRequestModel,
) {
  return authorizedRequest.get<
    ExportReportErrorBoothResponseModel,
    ExportReportErrorBoothResponseModel
  >(
    `${
      PREFIX_API.REPORT
    }/transactions/error-transaction-ratio-excel?${preprocessGetQuery({
      ...(payload || {}),
    })}`,
  );
}
