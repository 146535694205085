import React, { FC, useCallback } from 'react';
import funLogoImg from 'assets/images/fun_studio_logo.png';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { I18nNamespace } from 'constants/i18n.const';
import { AppRouteConst, AuthRouteConst } from 'constants/route.const';
import { logoutAction } from 'store/features/auth/auth.action';
import { useTranslation } from 'hooks/useTranslation';
import Menu from 'components/menu/Menu';
import MenuItem from 'components/menu/MenuItem';
import Dropdown from 'components/dropdown/Dropdown';
import DropdownMenu from 'components/dropdown/DropdownMenu';
import DropdownItem from 'components/dropdown/DropdownItem';
import MenuMenu from 'components/menu/MenuMenu';
import Image from 'components/image/Image';
import HeaderBreadcrumb from './breadcrumb/HeaderBreadcrumb';
import './app-header.css';

interface AppHeaderProps {}

const AppHeader: FC<AppHeaderProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { T } = useTranslation(I18nNamespace.COMMON);
  const { userIsLogging } = useAppSelector((s) => s.user);

  const handleLogout = useCallback(() => {
    dispatch(logoutAction());
    navigate(AuthRouteConst.LOGIN);
  }, [dispatch, navigate]);

  return (
    <header>
      <Menu attached="top">
        <MenuItem as="a" header className="logo-wrapper">
          <NavLink to={AppRouteConst.HOME} className="logo-link">
            <Image size="mini" src={funLogoImg} />
          </NavLink>
        </MenuItem>
        <MenuItem header>
          <HeaderBreadcrumb />
        </MenuItem>

        <MenuMenu position="right">
          <Dropdown item simple text={userIsLogging?.displayName}>
            <DropdownMenu>
              <DropdownItem as={Link} to={AppRouteConst.PROFILE.MY_PROFILE}>
                {T('layout.profile')}
              </DropdownItem>
              <DropdownItem
                as={Link}
                to={AppRouteConst.PROFILE.CHANGE_PASSWORD}
              >
                {T('layout.changePassword')}
              </DropdownItem>
              <DropdownItem onClick={handleLogout}>
                {T('layout.logout')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </MenuMenu>
      </Menu>
    </header>
  );
};

export default AppHeader;
