import React, { FC, PropsWithChildren, ReactElement } from 'react';
import {
  Sidebar,
  SidebarProps,
  SidebarPushable,
  SidebarPushableProps,
  SidebarPusher,
  SidebarPusherProps,
} from 'semantic-ui-react';
import { I18nNamespace } from 'constants/i18n.const';
import { useTranslation } from 'hooks/useTranslation';
import Menu from 'components/menu/Menu';
import Icon from 'components/icon/Icon';
import './right-drawer.css';

export type RightDrawerProps = PropsWithChildren & {
  open?: boolean;
  title?: string | ReactElement | ReactElement[];
  content?: ReactElement | ReactElement[];
  SidebarProps?: SidebarProps;
  SidebarPushableProps?: SidebarPushableProps;
  SidebarPusherProps?: SidebarPusherProps;
  isClickOutsideToClose?: boolean;
  onClose?: () => void;
};

const RightDrawer: FC<RightDrawerProps> = ({
  open = false,
  title,
  content,
  children,
  SidebarProps,
  SidebarPushableProps,
  SidebarPusherProps,
  isClickOutsideToClose = false,
  onClose,
  ...rest
}) => {
  const { T } = useTranslation(I18nNamespace.COMMON);

  return (
    <SidebarPushable {...SidebarPushableProps}>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        direction="right"
        vertical
        visible={open}
        {...(isClickOutsideToClose && { onHide: onClose })}
        {...SidebarProps}
      >
        <div className="pb-drawer-content-wrapper">
          <div className="pb-drawer-header">
            {(title as string) || T('title')}
            <Icon
              className="btn-header-close times"
              onClick={onClose}
              title={T('common:close')}
            />
          </div>
          <div className="pb-drawer-content">{content}</div>
        </div>
      </Sidebar>
      <SidebarPusher dimmed={open} {...SidebarPusherProps}>
        {children}
      </SidebarPusher>
    </SidebarPushable>
  );
};

export default RightDrawer;
