import { PAGE_INDEX_DEFAULT } from 'constants/route.const';
import { useEffect } from 'react';
import {
  listProvinceAction,
  listPrefetchCompanyAction,
  listPrefetchUserRolesAction,
  listPrefetchLayoutAction,
  listPrefetchThemeAction,
  listPrefetchThemeCategoryAction,
  listPrefetchStickerCategoryAction,
  listPrefetchStickerAction,
  listPrefetchBoothBackgroundAction,
  listPrefetchBoothBackgroundCategoryAction,
} from 'store/features/app/prefetch/prefetch.action';
import { getUserIsLoggingAction } from 'store/features/user/user.action';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';

export const useLoadFirstData = () => {
  const dispatch = useAppDispatch();
  const { triggerSaveCompany } = useAppSelector((s) => s.company);
  const { triggerSaveLayout } = useAppSelector((s) => s.layout);
  const { triggerSaveTheme } = useAppSelector((s) => s.theme);
  const { triggerSaveThemeCategory } = useAppSelector((s) => s.themeCategory);
  const { triggerSaveSticker } = useAppSelector((s) => s.sticker);
  const { triggerSaveStickerCategory } = useAppSelector(
    (s) => s.stickerCategory,
  );

  useEffect(() => {
    dispatch(getUserIsLoggingAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listPrefetchUserRolesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      listPrefetchCompanyAction({
        pageIndex: PAGE_INDEX_DEFAULT,
        pageSize: 9999,
        isActive: true,
      }),
    );
  }, [dispatch, triggerSaveCompany]);

  useEffect(() => {
    dispatch(
      listProvinceAction({
        pageIndex: PAGE_INDEX_DEFAULT,
        pageSize: 9999,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      listPrefetchLayoutAction({
        pageIndex: PAGE_INDEX_DEFAULT,
        pageSize: 9999,
        isActive: true,
      }),
    );
  }, [dispatch, triggerSaveLayout]);

  useEffect(() => {
    dispatch(
      listPrefetchThemeAction({
        pageIndex: PAGE_INDEX_DEFAULT,
        pageSize: 9999,
        isActive: true,
      }),
    );
  }, [dispatch, triggerSaveTheme]);

  useEffect(() => {
    dispatch(
      listPrefetchThemeCategoryAction({
        pageIndex: PAGE_INDEX_DEFAULT,
        pageSize: 9999,
        isActive: true,
      }),
    );
  }, [dispatch, triggerSaveThemeCategory]);

  useEffect(() => {
    dispatch(
      listPrefetchStickerAction({
        pageIndex: PAGE_INDEX_DEFAULT,
        pageSize: 9999,
        isActive: true,
      }),
    );
  }, [dispatch, triggerSaveSticker]);

  useEffect(() => {
    dispatch(
      listPrefetchStickerCategoryAction({
        pageIndex: PAGE_INDEX_DEFAULT,
        pageSize: 9999,
        isActive: true,
      }),
    );
  }, [dispatch, triggerSaveStickerCategory]);

  useEffect(() => {
    dispatch(
      listPrefetchBoothBackgroundAction({
        pageIndex: PAGE_INDEX_DEFAULT,
        pageSize: 9999,
        isActive: true,
      }),
    );
  }, [dispatch, triggerSaveSticker]);

  useEffect(() => {
    dispatch(
      listPrefetchBoothBackgroundCategoryAction({
        pageIndex: PAGE_INDEX_DEFAULT,
        pageSize: 9999,
        isActive: true,
      }),
    );
  }, [dispatch, triggerSaveSticker]);
};
