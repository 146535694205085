/* eslint-disable react/no-children-prop */
import React, { FC, memo } from 'react';
import isEqual from 'react-fast-compare';
import cx from 'classnames';
import { omit } from 'lodash';
import {
  Input as SUIInput,
  InputProps as SUIInputProps,
} from 'semantic-ui-react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import BaseMaskedInput from 'react-text-mask';
import { moneyMaskOptions, numericMaskOptions } from './input.util';
import './input.css';

export type Props = SUIInputProps & {
  isError?: boolean;
  isMoneyMask?: boolean;
  isNumericMask?: boolean;
  maskOptions?: any;
};

const Input: FC<Props> = ({
  isMoneyMask,
  isNumericMask,
  maskOptions,
  ...rest
}) => {
  if (isMoneyMask || isNumericMask) {
    return (
      <BaseMaskedInput
        value={rest?.value}
        onChange={rest?.onChange as any}
        mask={createNumberMask({
          inputMode: 'numeric',
          ...(isMoneyMask && { ...moneyMaskOptions }),
          ...(isNumericMask && { ...numericMaskOptions }),
          ...maskOptions,
        })}
        // ? Fix use CustomizeInput
        // render={(_ref, props) => (
        //   <CustomizeInput
        //     {...omit(rest, ['value', 'onChange', 'onBlur'])}
        //     {...props}
        //     inputRef={_ref}
        //     ref={(input: HTMLInputElement) => _ref(input)}
        //   />
        // )}
        render={(_ref, props) => (
          <input
            {...omit(rest, ['value', 'onChange', 'onBlur'])}
            {...props}
            ref={(input: HTMLInputElement) => _ref(input)}
          />
        )}
      />
    );
  }
  return <CustomizeInput {...rest} />;
};

// eslint-disable-next-line react/display-name
const CustomizeInput: FC<Props> = React.forwardRef(
  ({ className, ...rest }, ref: any) => (
    <SUIInput
      {...rest}
      ref={ref}
      className={cx(
        'pb-input',
        {
          'pb-input-disabled': !!rest?.disabled,
          'pb-input-error': !!rest?.isError,
        },
        'text-pb-h1',
        className,
      )}
    />
  ),
);

export default memo(Input, isEqual);
