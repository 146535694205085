import { isNil, isNumber, isNaN as isNaNLodash } from 'lodash';
import numeral from 'numeral';

function isNumberable(num: number | string): boolean {
  return num === 0 || (!!num && isNumber(+num) && !isNaNLodash(+num));
}

function generateRandomNumber() {
  return Math.floor(Math.random() * 10 ** 8);
}

function formatCurrency(s: number | string): string {
  if (isNil(s)) return '';

  return numeral(s).format('$0,0');
}

function formatMoneyVN(num: number | undefined | null): string {
  if (isNil(num)) return '0';

  return numeral(num).format('0,0');
}

function mToSec(num: number | undefined): number {
  return (num || 0) / 1000;
}

const keepValueOnlyNumber = (str: string, isNatureNumber = true): string => {
  if (typeof str !== 'string') return '';

  const isNegative = str.indexOf('-') === 0;

  return `${isNegative ? '-' : ''}${
    isNatureNumber ? str.replace(/\D+/g, '') : str.replace(/[^0-9^.]+/g, '')
  }`;
};

function addZeroPrefix(num: number | string, isForce: boolean = false) {
  const numConvert = +num || 0;
  if (isForce) {
    return numConvert < 10 ? `0${numConvert}` : numConvert;
  }
  return numConvert < 10 && numConvert > 0 ? `0${numConvert}` : numConvert;
}

export {
  isNumberable,
  generateRandomNumber,
  formatCurrency,
  formatMoneyVN,
  mToSec,
  keepValueOnlyNumber,
  addZeroPrefix,
};
