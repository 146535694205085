import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeVoucher,
  deleteVoucher,
  generateVoucher,
  getVoucher,
  inactiveVoucher,
  listVoucher,
} from 'api/promotion/voucher.api';
import {
  ActiveVoucherRequestModel,
  DeleteVoucherRequestModel,
  GenerateVoucherRequestModel,
  GetVoucherRequestModel,
  InactiveVoucherRequestModel,
  ListVoucherRequestModel,
} from 'models/promotion/voucher.model';

export const getVoucherAction = createAsyncThunk(
  'GET_VOUCHER_REQUEST',
  async (payload: GetVoucherRequestModel) => getVoucher(payload),
);

export const listVoucherAction = createAsyncThunk(
  'LIST_VOUCHER_REQUEST',
  async (payload: ListVoucherRequestModel) => listVoucher(payload),
);

export const generateVoucherAction = createAsyncThunk(
  'GENERATE_VOUCHER_REQUEST',
  async (payload: GenerateVoucherRequestModel, { rejectWithValue }) => {
    try {
      return await generateVoucher(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteVoucherAction = createAsyncThunk(
  'DELETE_VOUCHER_REQUEST',
  async (payload: DeleteVoucherRequestModel, { rejectWithValue }) => {
    try {
      return await deleteVoucher(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveVoucherAction = createAsyncThunk(
  'INACTIVE_VOUCHER_REQUEST',
  async (payload: InactiveVoucherRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveVoucher(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeVoucherAction = createAsyncThunk(
  'ACTIVE_VOUCHER_REQUEST',
  async (payload: ActiveVoucherRequestModel, { rejectWithValue }) => {
    try {
      return await activeVoucher(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
