import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import { getFormData } from 'helpers/form-data.helper';
import {
  ActiveNewsRequestModel,
  ActiveNewsResponseModel,
  DeleteNewsRequestModel,
  DeleteNewsResponseModel,
  GetNewsRequestModel,
  GetNewsResponseModel,
  InactiveNewsRequestModel,
  InactiveNewsResponseModel,
  ListNewsRequestModel,
  ListNewsResponseModel,
  SaveNewsRequestModel,
  SaveNewsResponseModel,
} from 'models/setting/news/news.model';

export function getNews(payload: GetNewsRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<GetNewsResponseModel, GetNewsResponseModel>(
      `${PREFIX_API.GENERAL}/news/${payload?.id}`,
    );
  }
}

export function listNews(payload: ListNewsRequestModel) {
  return authorizedRequest.get<ListNewsResponseModel, ListNewsResponseModel>(
    `${PREFIX_API.GENERAL}/news?${preprocessGetQuery(payload)}`,
  );
}

export function createNews(payload: SaveNewsRequestModel) {
  const formDataPayload = getFormData(payload);

  return authorizedRequest.post<SaveNewsResponseModel, SaveNewsResponseModel>(
    `${PREFIX_API.GENERAL}/news`,
    formDataPayload,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
}

export function updateNews(payload: SaveNewsRequestModel) {
  const formDataPayload = getFormData(payload);

  if (payload?.id) {
    return authorizedRequest.put<SaveNewsResponseModel, SaveNewsResponseModel>(
      `${PREFIX_API.GENERAL}/news/${payload?.id}`,
      formDataPayload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  }
}

export function deleteNews(payload: DeleteNewsRequestModel) {
  return authorizedRequest.delete<
    DeleteNewsResponseModel,
    DeleteNewsResponseModel
  >(`${PREFIX_API.GENERAL}/news/${payload?.id}`);
}

export function inactiveNews(payload: InactiveNewsRequestModel) {
  return authorizedRequest.put<
    InactiveNewsResponseModel,
    InactiveNewsResponseModel
  >(`${PREFIX_API.GENERAL}/news/${payload?.id}/inactive`);
}

export function activeNews(payload: ActiveNewsRequestModel) {
  return authorizedRequest.put<
    ActiveNewsResponseModel,
    ActiveNewsResponseModel
  >(`${PREFIX_API.GENERAL}/news/${payload?.id}/active`);
}
