import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { AppRouteConst } from 'constants/route.const';
import { I18nNamespace } from 'constants/i18n.const';
import { MenuItemTypes } from 'models/layout.model';
import { useTranslation } from 'hooks/useTranslation';
import { useRouter } from 'hooks/useRouter';
import { usePermission } from 'hooks/usePermission';
import Icon from 'components/icon/Icon';
import imgBooth from 'assets/images/booth.png';
import imgGift from 'assets/images/gift.png';
import imgLayout from 'assets/images/layout.png';
import imgMoney from 'assets/images/money.png';
import imgStore from 'assets/images/store.png';
import imgTheme from 'assets/images/theme.png';
import Tooltip from 'components/tooltip/Tooltip';
import './left-menu.css';

const LeftMenu: FC = () => {
  const { T } = useTranslation(I18nNamespace.COMMON);
  const { isMatchPath, isMatchSubPath } = useRouter();
  const { getAllowingByPathname, isSaleRole } = usePermission();

  // danh sách menu
  const menuItems: MenuItemTypes[] = useMemo(
    () => [
      {
        icon: <Icon name="pie chart" />,
        title: T('page.dashboard'),
        path: AppRouteConst.HOME,
        to: AppRouteConst.DASHBOARD,
        isShow: getAllowingByPathname?.(AppRouteConst.DASHBOARD)?.canView,
      },
      {
        icon: <img src={imgMoney} alt="money" />,
        title: T('page.transaction'),
        path: AppRouteConst.TRANSACTION,
        to: AppRouteConst.TRANSACTION,
        isShow: getAllowingByPathname?.(AppRouteConst.TRANSACTION)?.canView,
      },
      {
        icon: <Icon name="chart line" />,
        title: T('page.report'),
        path: AppRouteConst.REPORT.LIST,
        to: AppRouteConst.REPORT.LIST,
        isShow: getAllowingByPathname?.(AppRouteConst.REPORT.LIST)?.canView,
      },
      {
        icon: <Icon name="building" />,
        title: T('page.company'),
        path: AppRouteConst.COMPANY,
        to: AppRouteConst.COMPANY,
        isShow: getAllowingByPathname?.(AppRouteConst.COMPANY)?.canView,
      },
      {
        icon: <img src={imgStore} alt="store" />,
        title: T('page.store'),
        path: AppRouteConst.STORE,
        to: AppRouteConst.STORE,
        isShow: getAllowingByPathname?.(AppRouteConst.STORE)?.canView,
      },
      {
        icon: <img src={imgBooth} alt="booth" />,
        title: T('page.booth'),
        path: AppRouteConst.BOOTH,
        to: AppRouteConst.BOOTH,
        isShow: getAllowingByPathname?.(AppRouteConst.BOOTH)?.canView,
      },
      {
        icon: <img src={imgLayout} alt="layout" />,
        title: T('page.layoutList'),
        path: AppRouteConst.LAYOUT_TEMPLATE.LIST,
        to: AppRouteConst.LAYOUT_TEMPLATE.LIST,
        isShow: getAllowingByPathname?.(AppRouteConst.LAYOUT_TEMPLATE.LIST)
          ?.canView,
      },
      {
        icon: <img src={imgTheme} alt="theme" />,
        title: T('page.theme'),
        path: AppRouteConst.THEME.LIST,
        to: AppRouteConst.THEME.LIST,
        isShow: getAllowingByPathname?.(AppRouteConst.THEME.LIST)?.canView,
      },
      {
        icon: <Icon name="star" />,
        title: T('page.sticker'),
        path: AppRouteConst.STICKER.LIST,
        to: AppRouteConst.STICKER.LIST,
        isShow: getAllowingByPathname?.(AppRouteConst.STICKER.LIST)?.canView,
      },
      {
        icon: <img src={imgGift} alt="gift" />,
        title: T('page.promotion'),
        path: AppRouteConst.PROMOTION_CAMPAIGN,
        to: AppRouteConst.PROMOTION_CAMPAIGN,
        isShow: getAllowingByPathname?.(AppRouteConst.PROMOTION_CAMPAIGN)
          ?.canView,
      },
      {
        icon: <img src={imgGift} alt="gift" />,
        title: T('page.voucher'),
        path: AppRouteConst.VOUCHER.LIST,
        to: AppRouteConst.VOUCHER.LIST,
        isShow: getAllowingByPathname?.(AppRouteConst.VOUCHER.LIST)?.canView,
      },
      {
        icon: <Icon name="user" />,
        title: T('page.user'),
        path: AppRouteConst.USER,
        to: AppRouteConst.USER,
        isShow: getAllowingByPathname?.(AppRouteConst.USER)?.canView,
      },
      {
        icon: <Icon name="setting" />,
        title: T('page.setting'),
        path: AppRouteConst.SETTING.LIST,
        to: AppRouteConst.SETTING.LIST,
        isShow: getAllowingByPathname?.(AppRouteConst.SETTING.LIST)?.canView,
      },
    ],
    [T, getAllowingByPathname],
  );

  return (
    <ul className="left-menu">
      {menuItems.map(
        (item) =>
          item?.isShow && (
            <Tooltip
              key={item?.path}
              content={item?.title}
              position="right center"
              trigger={
                <li
                  className={cx({
                    [`active`]:
                      isMatchPath(item?.path) ||
                      (item?.path !== AppRouteConst.HOME &&
                        isMatchSubPath(item?.to)),
                  })}
                  title={item?.title}
                  key={item?.path}
                >
                  <Link
                    aria-disabled={isSaleRole}
                    to={isSaleRole ? AppRouteConst.DASHBOARD : item?.to}
                    key={item?.to}
                  >
                    {item?.icon}
                  </Link>
                </li>
              }
            />
          ),
      )}
    </ul>
  );
};

export default LeftMenu;
