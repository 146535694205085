export const TIME_OUT_API = 30000;
export const PREFIX_API = {
  API: 'api',
  FILE: 'file',
  GENERAL: 'general',
  REPORT: 'reporting',
};
export enum GRANT_TYPES {
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
}
export enum CLIENT_OF_API {
  POSTMAN = 'postman',
}
export enum AUTH_SCOPE {
  PROFILE = 'profile',
  ROLES = 'roles',
  OFFLINE_ACCESS = 'offline_access',
  FUN_STUDIO_OPERATION_API = 'fun-studio-operation-api',
  FUN_STUDIO_REPORT_API = 'fun-studio-report-api',
}
