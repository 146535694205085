import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeFaq,
  createFaq,
  deleteFaq,
  getFaq,
  inactiveFaq,
  listFaq,
  updateFaq,
} from 'api/setting/faq/faq.api';
import {
  ActiveFaqRequestModel,
  DeleteFaqRequestModel,
  GetFaqRequestModel,
  InactiveFaqRequestModel,
  ListFaqRequestModel,
  SaveFaqRequestModel,
} from 'models/setting/faq/faq.model';

export const getFaqAction = createAsyncThunk(
  'GET_FAQ_REQUEST',
  async (payload: GetFaqRequestModel) => getFaq(payload),
);

export const listFaqAction = createAsyncThunk(
  'LIST_FAQ_REQUEST',
  async (payload: ListFaqRequestModel) => listFaq(payload),
);

export const createFaqAction = createAsyncThunk(
  'CREATE_FAQ_REQUEST',
  async (payload: SaveFaqRequestModel, { rejectWithValue }) => {
    try {
      return await createFaq(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateFaqAction = createAsyncThunk(
  'UPDATE_FAQ_REQUEST',
  async (payload: SaveFaqRequestModel, { rejectWithValue }) => {
    try {
      return await updateFaq(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteFaqAction = createAsyncThunk(
  'DELETE_FAQ_REQUEST',
  async (payload: DeleteFaqRequestModel, { rejectWithValue }) => {
    try {
      return await deleteFaq(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveFaqAction = createAsyncThunk(
  'INACTIVE_FAQ_REQUEST',
  async (payload: InactiveFaqRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveFaq(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeFaqAction = createAsyncThunk(
  'ACTIVE_FAQ_REQUEST',
  async (payload: ActiveFaqRequestModel, { rejectWithValue }) => {
    try {
      return await activeFaq(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
