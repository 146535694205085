import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import { getFormData } from 'helpers/form-data.helper';
import {
  ActiveStoreRequestModel,
  ActiveStoreResponseModel,
  DeleteStoreRequestModel,
  DeleteStoreResponseModel,
  GetStoreRequestModel,
  GetStoreResponseModel,
  InactiveStoreRequestModel,
  InactiveStoreResponseModel,
  ListStoreRequestModel,
  ListStoreResponseModel,
  SaveStoreRequestModel,
  SaveStoreResponseModel,
} from 'models/store/store.model';

export function getStore(payload: GetStoreRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<GetStoreResponseModel, GetStoreResponseModel>(
      `${PREFIX_API.GENERAL}/stores/${payload?.id}`,
    );
  }
}

export function listStore(payload: ListStoreRequestModel) {
  return authorizedRequest.get<ListStoreResponseModel, ListStoreResponseModel>(
    `${PREFIX_API.GENERAL}/stores?${preprocessGetQuery(payload)}`,
  );
}

export function createStore(payload: SaveStoreRequestModel) {
  const formDataPayload = getFormData(payload);

  return authorizedRequest.post<SaveStoreResponseModel, SaveStoreResponseModel>(
    `${PREFIX_API.GENERAL}/stores`,
    formDataPayload,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
}

export function updateStore(payload: SaveStoreRequestModel) {
  const formDataPayload = getFormData(payload);

  if (payload?.id) {
    return authorizedRequest.put<
      SaveStoreResponseModel,
      SaveStoreResponseModel
    >(`${PREFIX_API.GENERAL}/stores/${payload?.id}`, formDataPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export function deleteStore(payload: DeleteStoreRequestModel) {
  return authorizedRequest.delete<
    DeleteStoreResponseModel,
    DeleteStoreResponseModel
  >(`${PREFIX_API.GENERAL}/stores/${payload?.id}`);
}

export function inactiveStore(payload: InactiveStoreRequestModel) {
  return authorizedRequest.put<
    InactiveStoreResponseModel,
    InactiveStoreResponseModel
  >(
    `${PREFIX_API.GENERAL}/stores/${payload?.id}/inactive?${preprocessGetQuery(
      payload,
    )}`,
    payload,
  );
}

export function activeStore(payload: ActiveStoreRequestModel) {
  return authorizedRequest.put<
    ActiveStoreResponseModel,
    ActiveStoreResponseModel
  >(
    `${PREFIX_API.GENERAL}/stores/${payload?.id}/active?${preprocessGetQuery(
      payload,
    )}`,
    payload,
  );
}
