import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ThemeLayoutStateModel } from 'models/theme/theme-layout.model';
import {
  getThemeLayoutAction,
  listLayoutHasThemeAction,
} from './theme-layout.action';

const initialState: ThemeLayoutStateModel = {
  themeLayoutListData: null,
  themeLayoutDetail: null,
  triggerSaveThemeLayout: null,
  layoutListHasTheme: [],

  loadingList: false,
  loadingThemeLayoutDetail: false,
};

export const themeLayoutSlice = createSlice({
  name: 'themeLayout',
  initialState,
  reducers: {
    setThemeLayoutStateAction: (
      state,
      action: PayloadAction<Partial<ThemeLayoutStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as ThemeLayoutStateModel),
    resetThemeLayoutStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThemeLayoutAction.pending, (state) => ({
        ...state,
        themeLayoutDetail: null,
      }))
      .addCase(getThemeLayoutAction.fulfilled, (state, { payload }) => ({
        ...state,
        themeLayoutDetail: payload?.data || null,
      }))
      .addCase(getThemeLayoutAction.rejected, (state) => ({
        ...state,
        themeLayoutDetail: null,
      }))
      .addCase(listLayoutHasThemeAction.pending, (state) => ({
        ...state,
        layoutListHasTheme: [],
        loadingThemeLayoutDetail: true,
      }))
      .addCase(listLayoutHasThemeAction.fulfilled, (state, { payload }) => ({
        ...state,
        layoutListHasTheme: payload?.data?.items || [],
        loadingThemeLayoutDetail: false,
      }))
      .addCase(listLayoutHasThemeAction.rejected, (state) => ({
        ...state,
        layoutListHasTheme: [],
        loadingThemeLayoutDetail: false,
      }));
  },
});

export const { setThemeLayoutStateAction, resetThemeLayoutStateAction } =
  themeLayoutSlice.actions;

export default themeLayoutSlice.reducer;
