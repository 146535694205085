import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { UserStateModel } from 'models/user/user.model';
import {
  activeUserAction,
  changePasswordProfileAction,
  createUserAction,
  deleteUserAction,
  getUserAction,
  getUserIsLoggingAction,
  inactiveUserAction,
  listUserAction,
  updateUserAction,
} from './user.action';

const initialState: UserStateModel = {
  userListData: null,
  userDetail: null,
  userIsLogging: null,
  triggerSaveUser: null,
  triggerChangePassword: null,

  loadingList: false,
  loadingUserIsLogging: false,
  loadingDetail: false,
  loadingChangePassword: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserStateAction: (
      state,
      action: PayloadAction<Partial<UserStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as UserStateModel),
    resetUserStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAction.pending, (state) => ({
        ...state,
        userDetail: null,
        loadingDetail: true,
      }))
      .addCase(getUserAction.fulfilled, (state, { payload }) => ({
        ...state,
        userDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getUserAction.rejected, (state) => ({
        ...state,
        userDetail: null,
        loadingDetail: false,
      }))
      .addCase(getUserIsLoggingAction.pending, (state) => ({
        ...state,
        userIsLogging: null,
        loadingUserIsLogging: true,
      }))
      .addCase(getUserIsLoggingAction.fulfilled, (state, { payload }) => ({
        ...state,
        userIsLogging: payload?.data || null,
        loadingUserIsLogging: false,
      }))
      .addCase(getUserIsLoggingAction.rejected, (state) => ({
        ...state,
        userIsLogging: null,
        loadingUserIsLogging: false,
      }))
      .addCase(listUserAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listUserAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        userListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listUserAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createUserAction.fulfilled, (state) => ({
        ...state,
        triggerSaveUser: { id: uuidv4() },
      }))
      .addCase(updateUserAction.fulfilled, (state) => ({
        ...state,
        triggerSaveUser: { id: uuidv4() },
      }))
      .addCase(deleteUserAction.fulfilled, (state) => ({
        ...state,
        triggerSaveUser: { id: uuidv4() },
      }))
      .addCase(inactiveUserAction.fulfilled, (state) => ({
        ...state,
        triggerSaveUser: { id: uuidv4() },
      }))
      .addCase(activeUserAction.fulfilled, (state) => ({
        ...state,
        triggerSaveUser: { id: uuidv4() },
      }))
      .addCase(changePasswordProfileAction.pending, (state) => ({
        ...state,
        loadingChangePassword: true,
      }))
      .addCase(changePasswordProfileAction.fulfilled, (state) => ({
        ...state,
        triggerChangePassword: { id: uuidv4() },
        loadingChangePassword: false,
      }))
      .addCase(changePasswordProfileAction.rejected, (state) => ({
        ...state,
        loadingChangePassword: false,
      }));
  },
});

export const { setUserStateAction, resetUserStateAction } = userSlice.actions;

export default userSlice.reducer;
