import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { FaqStateModel } from 'models/setting/faq/faq.model';
import {
  activeFaqAction,
  createFaqAction,
  deleteFaqAction,
  getFaqAction,
  inactiveFaqAction,
  listFaqAction,
  updateFaqAction,
} from './faq.action';

const initialState: FaqStateModel = {
  faqListData: null,
  faqDetail: null,
  triggerSaveFaq: null,

  loadingList: false,
  loadingDetail: false,
};

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setFaqStateAction: (state, action: PayloadAction<Partial<FaqStateModel>>) =>
      ({
        ...state,
        ...action?.payload,
      } as FaqStateModel),
    resetFaqStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFaqAction.pending, (state) => ({
        ...state,
        faqDetail: null,
        loadingDetail: true,
      }))
      .addCase(getFaqAction.fulfilled, (state, { payload }) => ({
        ...state,
        faqDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getFaqAction.rejected, (state) => ({
        ...state,
        faqDetail: null,
        loadingDetail: false,
      }))
      .addCase(listFaqAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listFaqAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        faqListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listFaqAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createFaqAction.fulfilled, (state) => ({
        ...state,
        triggerSaveFaq: { id: uuidv4() },
      }))
      .addCase(updateFaqAction.fulfilled, (state) => ({
        ...state,
        triggerSaveFaq: { id: uuidv4() },
      }))

      .addCase(deleteFaqAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteFaqAction.fulfilled, (state) => ({
        ...state,
        triggerSaveFaq: { id: uuidv4() },
      }))
      .addCase(deleteFaqAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeFaqAction.fulfilled, (state) => ({
        ...state,
        triggerSaveFaq: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveFaqAction.fulfilled, (state) => ({
        ...state,
        triggerSaveFaq: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setFaqStateAction, resetFaqStateAction } = faqSlice.actions;

export default faqSlice.reducer;
