import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeCompany,
  createCompany,
  deleteCompany,
  getCompany,
  inactiveCompany,
  listCompany,
  updateCompany,
} from 'api/company/company.api';
import {
  ActiveCompanyRequestModel,
  DeleteCompanyRequestModel,
  GetCompanyRequestModel,
  InactiveCompanyRequestModel,
  ListCompanyRequestModel,
  SaveCompanyRequestModel,
} from 'models/company/company.model';

export const getCompanyAction = createAsyncThunk(
  'GET_COMPANY_REQUEST',
  async (payload: GetCompanyRequestModel) => getCompany(payload),
);

export const listCompanyAction = createAsyncThunk(
  'LIST_COMPANY_REQUEST',
  async (payload: ListCompanyRequestModel) => listCompany(payload),
);

export const createCompanyAction = createAsyncThunk(
  'CREATE_COMPANY_REQUEST',
  async (payload: SaveCompanyRequestModel, { rejectWithValue }) => {
    try {
      return await createCompany(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateCompanyAction = createAsyncThunk(
  'UPDATE_COMPANY_REQUEST',
  async (payload: SaveCompanyRequestModel, { rejectWithValue }) => {
    try {
      return await updateCompany(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteCompanyAction = createAsyncThunk(
  'DELETE_COMPANY_REQUEST',
  async (payload: DeleteCompanyRequestModel, { rejectWithValue }) => {
    try {
      return await deleteCompany(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveCompanyAction = createAsyncThunk(
  'INACTIVE_COMPANY_REQUEST',
  async (payload: InactiveCompanyRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveCompany(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeCompanyAction = createAsyncThunk(
  'ACTIVE_COMPANY_REQUEST',
  async (payload: ActiveCompanyRequestModel, { rejectWithValue }) => {
    try {
      return await activeCompany(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
