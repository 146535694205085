import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { tokenManager } from 'api/request/tokenManager';
import appReducer from './features/app/appSlice';
import { authReducer } from './features/auth/authSlice';
import { prefetchReducer } from './features/app/prefetch/prefetch.slice';
import { dashboardSlice } from './features/dashboard/dashboard.slice';
import { reportSlice } from './features/report/report.slice';
import { transactionSlice } from './features/transaction/transactionSlice';
import { storeSlice } from './features/store/store.slice';
import { boothSlice } from './features/booth/booth.slice';
import { layoutSlice } from './features/layout/layout.slice';
import { layoutTemplateSlice } from './features/layout/layout-template.slice';
import { themeSlice } from './features/theme/theme.slice';
import { themeTemplateSlice } from './features/theme/theme-template.slice';
import { themeCategorySlice } from './features/theme/theme-category.slice';
import { themeLayoutSlice } from './features/theme/theme-layout.slice';

import { stickerSlice } from './features/sticker/sticker.slice';
import { stickerTemplateSlice } from './features/sticker/sticker-template.slice';
import { stickerCategorySlice } from './features/sticker/sticker-category.slice';

import { boothBackgroundSlice } from './features/booth-background/booth-background.slice';
import { boothBackgroundTemplateSlice } from './features/booth-background/booth-background-template.slice';
import { boothBackgroundCategorySlice } from './features/booth-background/booth-background-category.slice';

import { promotionReducer } from './features/promotion/promotion.slice';
import { userSlice } from './features/user/user.slice';
import { companySlice } from './features/company/company.slice';
import { counterSlice } from './features/counter/counterSlice';

// setting
import { capturePositionSlice } from './features/setting/capture-position/capture-position.slice';
import { capturePositionTemplateSlice } from './features/setting/capture-position/capture-position-template.slice';
import { uiTemplateBoothSlice } from './features/setting/ui-template-booth/ui-template-booth.slice';
import { newsSlice } from './features/setting/news/news.slice';
import { faqSlice } from './features/setting/faq/faq.slice';
import { boothVersionSlice } from './features/setting/booth-version/booth-version.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    prefetch: prefetchReducer,
    app: appReducer,
    counter: counterSlice.reducer,
    dashboard: dashboardSlice.reducer,
    transaction: transactionSlice.reducer,
    report: reportSlice.reducer,
    store: storeSlice.reducer,
    booth: boothSlice.reducer,
    layout: layoutSlice.reducer,
    theme: themeSlice.reducer,
    themeTemplate: themeTemplateSlice.reducer,
    themeCategory: themeCategorySlice.reducer,
    themeLayout: themeLayoutSlice.reducer,

    sticker: stickerSlice.reducer,
    stickerTemplate: stickerTemplateSlice.reducer,
    stickerCategory: stickerCategorySlice.reducer,

    boothBackground: boothBackgroundSlice.reducer,
    boothBackgroundTemplate: boothBackgroundTemplateSlice.reducer,
    boothBackgroundCategory: boothBackgroundCategorySlice.reducer,

    layoutTemplate: layoutTemplateSlice.reducer,
    promotion: promotionReducer,
    user: userSlice.reducer,
    company: companySlice.reducer,

    // setting
    capturePosition: capturePositionSlice.reducer,
    capturePositionTemplate: capturePositionTemplateSlice.reducer,
    uiTemplateBooth: uiTemplateBoothSlice.reducer,
    news: newsSlice.reducer,
    faq: faqSlice.reducer,
    boothVersion: boothVersionSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

let currentToken: string;
let currentRefreshToken: string | undefined;

store.subscribe(() => {
  const previousToken = currentToken;
  const previousRefreshToken = currentRefreshToken;
  currentToken = store.getState().auth.token;
  currentRefreshToken = store.getState().auth.loginData?.refresh_token;
  if (currentToken && previousToken !== currentToken) {
    tokenManager.setToken(currentToken);
  }
  if (currentRefreshToken && previousRefreshToken !== currentRefreshToken) {
    tokenManager.setRefreshToken(currentRefreshToken);
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
