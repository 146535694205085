import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { persistor, store } from 'store';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import AppLayout from 'containers/layout/app/AppLayout';
import AuthLayout from 'containers/layout/login/AuthLayout';
import { I18nNamespace } from 'constants/i18n.const';
import { ALL_ROUTE_CHAR, AuthRouteConst } from 'constants/route.const';
import { axiosUserContext } from 'api/request/authorizedRequest';
import { useAppDispatch } from 'store/store-hooks';
import {
  logoutAction,
  refreshTokenAction,
} from 'store/features/auth/auth.action';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';

const router = createBrowserRouter([
  {
    path: `${AuthRouteConst.AUTH}/${ALL_ROUTE_CHAR}`,
    element: <AuthLayout />,
  },
  {
    path: ALL_ROUTE_CHAR,
    element: <AppLayout />,
  },
]);

function App() {
  const dispatch = useAppDispatch();
  useTranslation(I18nNamespace.COMMON);

  useEffect(() => {
    axiosUserContext.dispatchLogout = () => {
      dispatch(logoutAction());
    };
    axiosUserContext.dispatchRefreshToken = () => {
      const loginData = store.getState()?.auth?.loginData;
      if (loginData?.refresh_token) {
        dispatch(
          refreshTokenAction({ refresh_token: loginData?.refresh_token }),
        ).then((res) => {
          if ('error' in res && res?.error) dispatch(logoutAction());
        });
      } else {
        dispatch(logoutAction());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router} />
      <ToastContainer />
    </PersistGate>
  );
}

function ReduxApp() {
  return (
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  );
}

export default ReduxApp;
