import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeStickerTemplate,
  createStickerTemplate,
  deleteStickerTemplate,
  getStickerTemplate,
  inactiveStickerTemplate,
  listStickerTemplate,
  updateStickerTemplate,
} from 'api/sticker/sticker-template.api';
import {
  ActiveStickerTemplateRequestModel,
  DeleteStickerTemplateRequestModel,
  GetStickerTemplateRequestModel,
  InactiveStickerTemplateRequestModel,
  ListStickerTemplateRequestModel,
  SaveStickerTemplateRequestModel,
} from 'models/sticker/sticker-template.model';

export const getStickerTemplateAction = createAsyncThunk(
  'GET_STICKER_TEMPLATE_REQUEST',
  async (payload: GetStickerTemplateRequestModel) =>
    getStickerTemplate(payload),
);

export const listStickerTemplateAction = createAsyncThunk(
  'LIST_STICKER_TEMPLATE_REQUEST',
  async (payload: ListStickerTemplateRequestModel) =>
    listStickerTemplate(payload),
);

export const createStickerTemplateAction = createAsyncThunk(
  'CREATE_STICKER_TEMPLATE_REQUEST',
  async (payload: SaveStickerTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await createStickerTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateStickerTemplateAction = createAsyncThunk(
  'UPDATE_STICKER_TEMPLATE_REQUEST',
  async (payload: SaveStickerTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await updateStickerTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteStickerTemplateAction = createAsyncThunk(
  'DELETE_STICKER_TEMPLATE_REQUEST',
  async (payload: DeleteStickerTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await deleteStickerTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveStickerTemplateAction = createAsyncThunk(
  'INACTIVE_STICKER_TEMPLATE_REQUEST',
  async (payload: InactiveStickerTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveStickerTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeStickerTemplateAction = createAsyncThunk(
  'ACTIVE_STICKER_TEMPLATE_REQUEST',
  async (payload: ActiveStickerTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await activeStickerTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
