import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { StoreStateModel } from 'models/store/store.model';
import { v4 as uuidv4 } from 'uuid';
import {
  activeStoreAction,
  createStoreAction,
  deleteStoreAction,
  getStoreAction,
  inactiveStoreAction,
  listStoreAction,
  updateStoreAction,
} from './store.action';

const initialState: StoreStateModel = {
  storeListData: null,
  storeDetail: null,
  triggerSaveStore: null,

  loadingList: false,
  loadingDetail: false,
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStoreStateAction: (
      state,
      action: PayloadAction<Partial<StoreStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as StoreStateModel),
    resetStoreStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoreAction.pending, (state) => ({
        ...state,
        storeDetail: null,
        loadingDetail: true,
      }))
      .addCase(getStoreAction.fulfilled, (state, { payload }) => ({
        ...state,
        storeDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getStoreAction.rejected, (state) => ({
        ...state,
        storeDetail: null,
        loadingDetail: false,
      }))
      .addCase(listStoreAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listStoreAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        storeListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listStoreAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createStoreAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStore: { id: uuidv4() },
      }))
      .addCase(updateStoreAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStore: { id: uuidv4() },
      }))

      .addCase(deleteStoreAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteStoreAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStore: { id: uuidv4() },
      }))
      .addCase(deleteStoreAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeStoreAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStore: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveStoreAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStore: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setStoreStateAction, resetStoreStateAction } =
  storeSlice.actions;

export default storeSlice.reducer;
