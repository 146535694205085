import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { BoothBackgroundStateModel } from 'models/booth-background/booth-background.model';
import {
  activeBoothBackgroundAction,
  createBoothBackgroundAction,
  deleteBoothBackgroundAction,
  getBoothBackgroundAction,
  inactiveBoothBackgroundAction,
  listBoothBackgroundAction,
  updateBoothBackgroundAction,
} from './booth-background.action';

const initialState: BoothBackgroundStateModel = {
  boothBackgroundListData: null,
  boothBackgroundDetail: null,
  triggerSaveBoothBackground: null,

  loadingList: false,
  loadingDetail: false,
};

export const boothBackgroundSlice = createSlice({
  name: 'booth-background',
  initialState,
  reducers: {
    setBoothBackgroundStateAction: (
      state,
      action: PayloadAction<Partial<BoothBackgroundStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as BoothBackgroundStateModel),
    resetBoothBackgroundStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoothBackgroundAction.pending, (state) => ({
        ...state,
        boothBackgroundDetail: null,
        loadingDetail: true,
      }))
      .addCase(getBoothBackgroundAction.fulfilled, (state, { payload }) => ({
        ...state,
        boothBackgroundDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getBoothBackgroundAction.rejected, (state) => ({
        ...state,
        boothBackgroundDetail: null,
        loadingDetail: false,
      }))
      .addCase(listBoothBackgroundAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listBoothBackgroundAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        boothBackgroundListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listBoothBackgroundAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createBoothBackgroundAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackground: { id: uuidv4() },
      }))
      .addCase(updateBoothBackgroundAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackground: { id: uuidv4() },
      }))

      .addCase(deleteBoothBackgroundAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteBoothBackgroundAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackground: { id: uuidv4() },
      }))
      .addCase(deleteBoothBackgroundAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeBoothBackgroundAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackground: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveBoothBackgroundAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackground: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const {
  setBoothBackgroundStateAction,
  resetBoothBackgroundStateAction,
} = boothBackgroundSlice.actions;

export default boothBackgroundSlice.reducer;
