import { createAsyncThunk } from '@reduxjs/toolkit';
import { getThemeLayout, listLayoutHasTheme } from 'api/theme/theme-layout.api';
import {
  GetThemeLayoutRequestModel,
  ListLayoutHasThemeRequestModel,
} from 'models/theme/theme-layout.model';

export const getThemeLayoutAction = createAsyncThunk(
  'GET_THEME_LAYOUT_REQUEST',
  async (payload: GetThemeLayoutRequestModel) => getThemeLayout(payload),
);

export const listLayoutHasThemeAction = createAsyncThunk(
  'LIST_LAYOUT_HAS_THEME_REQUEST',
  async (payload: ListLayoutHasThemeRequestModel) =>
    listLayoutHasTheme(payload),
);
