import { size } from 'lodash';
import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router';

export interface UseRouterReturnTypes {
  isMatchPath: (
    pathModel: string,
    pathname?: string,
  ) => ReturnType<typeof matchPath>;
  isMatchSubPath: (pathModel: string, pathname?: string) => boolean;
}

export const useRouter = (): UseRouterReturnTypes => {
  const { pathname } = useLocation();

  const isMatchPath = useCallback(
    (pathModel: string, path = pathname) =>
      matchPath(
        {
          path: pathModel,
        },
        path,
      ),
    [pathname],
  );

  const isMatchSubPath = useCallback(
    (pathModel: string, path = pathname) =>
      (typeof path != 'string' ? '' : path).indexOf(pathModel) === 0 &&
      (typeof path != 'string' ? '' : path).indexOf(pathModel) ===
        size(path) - size(pathModel),
    [pathname],
  );

  return { isMatchPath, isMatchSubPath };
};
