import React, { FC, ReactElement } from 'react';
import { I18nNamespace } from 'constants/i18n.const';
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  ModalHeaderProps,
  ModalContentProps,
  ModalActionsProps,
} from 'semantic-ui-react';
import { useTranslation } from 'hooks/useTranslation';
import Button from 'components/button/Button';
import Modal, { ModalProps } from 'components/modal/Modal';

export type ConfirmModalProps = {
  open?: boolean;
  title?: string | ReactElement | ReactElement[];
  content?: string | ReactElement | ReactElement[];
  ModalProps?: ModalProps;
  ModalHeaderProps?: ModalHeaderProps;
  ModalContentProps?: ModalContentProps;
  ModalActionsProps?: ModalActionsProps;
  hideClose?: boolean;
  hideOk?: boolean;
  onClose?: () => void;
  onOk?: () => void;
};

const ConfirmModal: FC<ConfirmModalProps> = ({
  open = false,
  title,
  content,
  ModalProps,
  ModalHeaderProps,
  ModalContentProps,
  ModalActionsProps,
  hideClose,
  hideOk,
  onClose,
  onOk,
}) => {
  const { T } = useTranslation(I18nNamespace.COMMON);

  return (
    <Modal
      size="mini"
      open={open}
      onClose={onClose}
      dimmer="blurring"
      {...ModalProps}
    >
      <ModalHeader {...ModalHeaderProps}>{title || T('confirm')}</ModalHeader>
      <ModalContent {...ModalContentProps}>
        {content || T('areYouConfirm')}
      </ModalContent>
      {!hideClose && !hideOk && (
        <ModalActions {...ModalActionsProps}>
          {!hideClose && (
            <Button negative onClick={onClose}>
              {T('no')}
            </Button>
          )}
          {!hideOk && (
            <Button positive onClick={onOk}>
              {T('yes')}
            </Button>
          )}
        </ModalActions>
      )}
    </Modal>
  );
};

export default ConfirmModal;
