import { createAsyncThunk } from '@reduxjs/toolkit';
import { login, refreshToken } from 'api/auth/auth.api';
import { tokenManager } from 'api/request/tokenManager';
import {
  LoginRequestModel,
  RefreshTokenRequestModel,
} from 'models/auth/auth.model';

export const loginAction = createAsyncThunk(
  'LOGIN_REQUEST',
  async (payload: LoginRequestModel, { rejectWithValue }) => {
    try {
      const loginRes = await login(payload);
      tokenManager.setToken(loginRes?.access_token);
      return loginRes;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const refreshTokenAction = createAsyncThunk(
  'REFRESH_TOKEN_REQUEST',
  async (payload: RefreshTokenRequestModel, { rejectWithValue }) => {
    try {
      const loginRes = await refreshToken(payload);
      tokenManager.setRefreshToken(loginRes?.access_token);
      return loginRes;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const logoutAction = createAsyncThunk('LOGOUT_ACTION', () => {
  tokenManager.doLogout();
  return null;
});
