import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActivePromotionCampaignRequestModel,
  ActivePromotionCampaignResponseModel,
  DeletePromotionCampaignRequestModel,
  DeletePromotionCampaignResponseModel,
  GetPromotionCampaignRequestModel,
  GetPromotionCampaignResponseModel,
  InactivePromotionCampaignRequestModel,
  InactivePromotionCampaignResponseModel,
  ListPromotionCampaignRequestModel,
  ListPromotionCampaignResponseModel,
  SavePromotionCampaignRequestModel,
  SavePromotionCampaignResponseModel,
} from 'models/promotion/promotion-campaign.model';

export function getPromotionCampaign(
  payload: GetPromotionCampaignRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetPromotionCampaignResponseModel,
      GetPromotionCampaignResponseModel
    >(`${PREFIX_API.GENERAL}/promotion-campaigns/${payload?.id}`);
  }
}

export function listPromotionCampaign(
  payload: ListPromotionCampaignRequestModel,
) {
  return authorizedRequest.get<
    ListPromotionCampaignResponseModel,
    ListPromotionCampaignResponseModel
  >(`${PREFIX_API.GENERAL}/promotion-campaigns?${preprocessGetQuery(payload)}`);
}

export function createPromotionCampaign(
  payload: SavePromotionCampaignRequestModel,
) {
  return authorizedRequest.post<
    SavePromotionCampaignResponseModel,
    SavePromotionCampaignResponseModel
  >(`${PREFIX_API.GENERAL}/promotion-campaigns`, payload);
}

export function updatePromotionCampaign(
  payload: SavePromotionCampaignRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.put<
      SavePromotionCampaignResponseModel,
      SavePromotionCampaignResponseModel
    >(`${PREFIX_API.GENERAL}/promotion-campaigns/${payload?.id}`, payload);
  }
}

export function deletePromotionCampaign(
  payload: DeletePromotionCampaignRequestModel,
) {
  return authorizedRequest.delete<
    DeletePromotionCampaignResponseModel,
    DeletePromotionCampaignResponseModel
  >(`${PREFIX_API.GENERAL}/promotion-campaigns/${payload?.id}`);
}

export function inactivePromotionCampaign(
  payload: InactivePromotionCampaignRequestModel,
) {
  return authorizedRequest.put<
    InactivePromotionCampaignResponseModel,
    InactivePromotionCampaignResponseModel
  >(`${PREFIX_API.GENERAL}/promotion-campaigns/${payload?.id}/inactive`);
}

export function activePromotionCampaign(
  payload: ActivePromotionCampaignRequestModel,
) {
  return authorizedRequest.put<
    ActivePromotionCampaignResponseModel,
    ActivePromotionCampaignResponseModel
  >(`${PREFIX_API.GENERAL}/promotion-campaigns/${payload?.id}/active`);
}
