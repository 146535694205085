import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeBoothBackground,
  createBoothBackground,
  deleteBoothBackground,
  getBoothBackground,
  inactiveBoothBackground,
  listBoothBackground,
  updateBoothBackground,
} from 'api/booth-background/booth-background.api';
import {
  ActiveBoothBackgroundRequestModel,
  DeleteBoothBackgroundRequestModel,
  GetBoothBackgroundRequestModel,
  InactiveBoothBackgroundRequestModel,
  ListBoothBackgroundRequestModel,
  SaveBoothBackgroundRequestModel,
} from 'models/booth-background/booth-background.model';

export const getBoothBackgroundAction = createAsyncThunk(
  'GET_BOOTH_BACKGROUND_REQUEST',
  async (payload: GetBoothBackgroundRequestModel) =>
    getBoothBackground(payload),
);

export const listBoothBackgroundAction = createAsyncThunk(
  'LIST_BOOTH_BACKGROUND_REQUEST',
  async (payload: ListBoothBackgroundRequestModel) =>
    listBoothBackground(payload),
);

export const createBoothBackgroundAction = createAsyncThunk(
  'CREATE_BOOTH_BACKGROUND_REQUEST',
  async (payload: SaveBoothBackgroundRequestModel, { rejectWithValue }) => {
    try {
      return await createBoothBackground(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateBoothBackgroundAction = createAsyncThunk(
  'UPDATE_BOOTH_BACKGROUND_REQUEST',
  async (payload: SaveBoothBackgroundRequestModel, { rejectWithValue }) => {
    try {
      return await updateBoothBackground(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteBoothBackgroundAction = createAsyncThunk(
  'DELETE_BOOTH_BACKGROUND_REQUEST',
  async (payload: DeleteBoothBackgroundRequestModel, { rejectWithValue }) => {
    try {
      return await deleteBoothBackground(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveBoothBackgroundAction = createAsyncThunk(
  'INACTIVE_BOOTH_BACKGROUND_REQUEST',
  async (payload: InactiveBoothBackgroundRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveBoothBackground(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeBoothBackgroundAction = createAsyncThunk(
  'ACTIVE_BOOTH_BACKGROUND_REQUEST',
  async (payload: ActiveBoothBackgroundRequestModel, { rejectWithValue }) => {
    try {
      return await activeBoothBackground(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
