import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import { size } from 'lodash';
import {
  ActiveBoothRequestModel,
  ActiveBoothResponseModel,
  DeleteBoothRequestModel,
  DeleteBoothResponseModel,
  GetBoothIdentityRequestModel,
  GetBoothIdentityResponseModel,
  GetBoothRequestModel,
  GetBoothResponseModel,
  InactiveBoothRequestModel,
  InactiveBoothResponseModel,
  ListBoothRequestModel,
  ListBoothResponseModel,
  SaveBoothRequestModel,
  SaveBoothResponseModel,
  UpdateBoothVersionRequestModel,
  UpdateBoothVersionResponseModel,
} from 'models/booth/booth.model';

export function getBooth(payload: GetBoothRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<GetBoothResponseModel, GetBoothResponseModel>(
      `${PREFIX_API.GENERAL}/booths/${payload?.id}`,
    );
  }
}

export function getBoothIdentity(payload: GetBoothIdentityRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetBoothIdentityResponseModel,
      GetBoothIdentityResponseModel
    >(`${PREFIX_API.GENERAL}/booths/${payload?.id}/hash-identify`);
  }
}

export function listBooth(payload: ListBoothRequestModel) {
  return authorizedRequest.get<ListBoothResponseModel, ListBoothResponseModel>(
    `${PREFIX_API.GENERAL}/booths?${preprocessGetQuery(payload)}`,
  );
}

export function createBooth(payload: SaveBoothRequestModel) {
  return authorizedRequest.post<SaveBoothResponseModel, SaveBoothResponseModel>(
    `${PREFIX_API.GENERAL}/booths`,
    payload,
  );
}

export function updateBooth(payload: SaveBoothRequestModel) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveBoothResponseModel,
      SaveBoothResponseModel
    >(`${PREFIX_API.GENERAL}/booths/${payload?.id}`, payload);
  }
}

export function updateBoothVersion(payload: UpdateBoothVersionRequestModel) {
  if (size(payload?.listBoothId) > 0) {
    return authorizedRequest.post<
      UpdateBoothVersionResponseModel,
      UpdateBoothVersionResponseModel
    >(`${PREFIX_API.GENERAL}/booths/update-booth-version`, payload);
  }
}

export function deleteBooth(payload: DeleteBoothRequestModel) {
  return authorizedRequest.delete<
    DeleteBoothResponseModel,
    DeleteBoothResponseModel
  >(`${PREFIX_API.GENERAL}/booths/${payload?.id}`);
}

export function inactiveBooth(payload: InactiveBoothRequestModel) {
  return authorizedRequest.put<
    InactiveBoothResponseModel,
    InactiveBoothResponseModel
  >(
    `${PREFIX_API.GENERAL}/booths/${payload?.id}/inactive?${preprocessGetQuery(
      payload,
    )}`,
    payload,
  );
}

export function activeBooth(payload: ActiveBoothRequestModel) {
  return authorizedRequest.put<
    ActiveBoothResponseModel,
    ActiveBoothResponseModel
  >(
    `${PREFIX_API.GENERAL}/booths/${payload?.id}/active?${preprocessGetQuery(
      payload,
    )}`,
    payload,
  );
}
