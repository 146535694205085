import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activePromotionCampaign,
  createPromotionCampaign,
  deletePromotionCampaign,
  getPromotionCampaign,
  inactivePromotionCampaign,
  listPromotionCampaign,
  updatePromotionCampaign,
} from 'api/promotion/promotion-campaign.api';
import {
  ActivePromotionCampaignRequestModel,
  DeletePromotionCampaignRequestModel,
  GetPromotionCampaignRequestModel,
  InactivePromotionCampaignRequestModel,
  ListPromotionCampaignRequestModel,
  SavePromotionCampaignRequestModel,
} from 'models/promotion/promotion-campaign.model';

export const getPromotionCampaignAction = createAsyncThunk(
  'GET_PROMOTION_CAMPAIGN_REQUEST',
  async (payload: GetPromotionCampaignRequestModel) =>
    getPromotionCampaign(payload),
);

export const listPromotionCampaignAction = createAsyncThunk(
  'LIST_PROMOTION_CAMPAIGN_REQUEST',
  async (payload: ListPromotionCampaignRequestModel) =>
    listPromotionCampaign(payload),
);

export const createPromotionCampaignAction = createAsyncThunk(
  'CREATE_PROMOTION_CAMPAIGN_REQUEST',
  async (payload: SavePromotionCampaignRequestModel, { rejectWithValue }) => {
    try {
      return await createPromotionCampaign(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updatePromotionCampaignAction = createAsyncThunk(
  'UPDATE_PROMOTION_CAMPAIGN_REQUEST',
  async (payload: SavePromotionCampaignRequestModel, { rejectWithValue }) => {
    try {
      return await updatePromotionCampaign(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deletePromotionCampaignAction = createAsyncThunk(
  'DELETE_PROMOTION_CAMPAIGN_REQUEST',
  async (payload: DeletePromotionCampaignRequestModel, { rejectWithValue }) => {
    try {
      return await deletePromotionCampaign(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactivePromotionCampaignAction = createAsyncThunk(
  'INACTIVE_PROMOTION_CAMPAIGN_REQUEST',
  async (
    payload: InactivePromotionCampaignRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await inactivePromotionCampaign(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activePromotionCampaignAction = createAsyncThunk(
  'ACTIVE_PROMOTION_CAMPAIGN_REQUEST',
  async (payload: ActivePromotionCampaignRequestModel, { rejectWithValue }) => {
    try {
      return await activePromotionCampaign(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
