import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import { getFormData } from 'helpers/form-data.helper';
import {
  ActiveBoothBackgroundRequestModel,
  ActiveBoothBackgroundResponseModel,
  DeleteBoothBackgroundRequestModel,
  DeleteBoothBackgroundResponseModel,
  GetBoothBackgroundRequestModel,
  GetBoothBackgroundResponseModel,
  InactiveBoothBackgroundRequestModel,
  InactiveBoothBackgroundResponseModel,
  ListBoothBackgroundRequestModel,
  ListBoothBackgroundResponseModel,
  SaveBoothBackgroundRequestModel,
  SaveBoothBackgroundResponseModel,
} from 'models/booth-background/booth-background.model';

export function getBoothBackground(payload: GetBoothBackgroundRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetBoothBackgroundResponseModel,
      GetBoothBackgroundResponseModel
    >(`${PREFIX_API.GENERAL}/backgrounds/${payload?.id}`);
  }
}

export function listBoothBackground(payload: ListBoothBackgroundRequestModel) {
  return authorizedRequest.get<
    ListBoothBackgroundResponseModel,
    ListBoothBackgroundResponseModel
  >(`${PREFIX_API.GENERAL}/backgrounds?${preprocessGetQuery(payload)}`);
}

export function createBoothBackground(
  payload: SaveBoothBackgroundRequestModel,
) {
  const formDataPayload = getFormData(payload);

  return authorizedRequest.post<
    SaveBoothBackgroundResponseModel,
    SaveBoothBackgroundResponseModel
  >(`${PREFIX_API.GENERAL}/backgrounds`, formDataPayload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function updateBoothBackground(
  payload: SaveBoothBackgroundRequestModel,
) {
  const formDataPayload = getFormData(payload);

  if (payload?.id) {
    return authorizedRequest.put<
      SaveBoothBackgroundResponseModel,
      SaveBoothBackgroundResponseModel
    >(`${PREFIX_API.GENERAL}/backgrounds/${payload?.id}`, formDataPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export function deleteBoothBackground(
  payload: DeleteBoothBackgroundRequestModel,
) {
  return authorizedRequest.delete<
    DeleteBoothBackgroundResponseModel,
    DeleteBoothBackgroundResponseModel
  >(`${PREFIX_API.GENERAL}/backgrounds/${payload?.id}`);
}

export function inactiveBoothBackground(
  payload: InactiveBoothBackgroundRequestModel,
) {
  return authorizedRequest.put<
    InactiveBoothBackgroundResponseModel,
    InactiveBoothBackgroundResponseModel
  >(`${PREFIX_API.GENERAL}/backgrounds/${payload?.id}/inactive`);
}

export function activeBoothBackground(
  payload: ActiveBoothBackgroundRequestModel,
) {
  return authorizedRequest.put<
    ActiveBoothBackgroundResponseModel,
    ActiveBoothBackgroundResponseModel
  >(`${PREFIX_API.GENERAL}/backgrounds/${payload?.id}/active`);
}
