import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveBoothBackgroundCategoryRequestModel,
  ActiveBoothBackgroundCategoryResponseModel,
  DeleteBoothBackgroundCategoryRequestModel,
  DeleteBoothBackgroundCategoryResponseModel,
  GetBoothBackgroundCategoryRequestModel,
  GetBoothBackgroundCategoryResponseModel,
  InactiveBoothBackgroundCategoryRequestModel,
  InactiveBoothBackgroundCategoryResponseModel,
  ListBoothBackgroundCategoryRequestModel,
  ListBoothBackgroundCategoryResponseModel,
  SaveBoothBackgroundCategoryRequestModel,
  SaveBoothBackgroundCategoryResponseModel,
} from 'models/booth-background/booth-background-category.model';

export function getBoothBackgroundCategory(
  payload: GetBoothBackgroundCategoryRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetBoothBackgroundCategoryResponseModel,
      GetBoothBackgroundCategoryResponseModel
    >(`${PREFIX_API.GENERAL}/background-categories/${payload?.id}`);
  }
}

export function listBoothBackgroundCategory(
  payload: ListBoothBackgroundCategoryRequestModel,
) {
  return authorizedRequest.get<
    ListBoothBackgroundCategoryResponseModel,
    ListBoothBackgroundCategoryResponseModel
  >(
    `${PREFIX_API.GENERAL}/background-categories?${preprocessGetQuery(
      payload,
    )}`,
  );
}

export function createBoothBackgroundCategory(
  payload: SaveBoothBackgroundCategoryRequestModel,
) {
  return authorizedRequest.post<
    SaveBoothBackgroundCategoryResponseModel,
    SaveBoothBackgroundCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/background-categories`, payload);
}

export function updateBoothBackgroundCategory(
  payload: SaveBoothBackgroundCategoryRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveBoothBackgroundCategoryResponseModel,
      SaveBoothBackgroundCategoryResponseModel
    >(`${PREFIX_API.GENERAL}/background-categories/${payload?.id}`, payload);
  }
}

export function deleteBoothBackgroundCategory(
  payload: DeleteBoothBackgroundCategoryRequestModel,
) {
  return authorizedRequest.delete<
    DeleteBoothBackgroundCategoryResponseModel,
    DeleteBoothBackgroundCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/background-categories/${payload?.id}`);
}

export function inactiveBoothBackgroundCategory(
  payload: InactiveBoothBackgroundCategoryRequestModel,
) {
  return authorizedRequest.put<
    InactiveBoothBackgroundCategoryResponseModel,
    InactiveBoothBackgroundCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/background-categories/${payload?.id}/inactive`);
}

export function activeBoothBackgroundCategory(
  payload: ActiveBoothBackgroundCategoryRequestModel,
) {
  return authorizedRequest.put<
    ActiveBoothBackgroundCategoryResponseModel,
    ActiveBoothBackgroundCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/background-categories/${payload?.id}/active`);
}
