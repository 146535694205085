import React, { FC, memo } from 'react';
import isEqual from 'react-fast-compare';
import cx from 'classnames';
import { Popup, PopupProps } from 'semantic-ui-react';
import './tooltip.css';

export type TooltipProps = PopupProps & {};

const Tooltip: FC<TooltipProps> = ({ ...rest }) => (
  <Popup
    trigger={rest?.children}
    popper={<div style={{ filter: 'none' }} />}
    {...rest}
    className={cx('pb-tooltip', rest?.className)}
  />
);

export default memo(Tooltip, isEqual);
