import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeCapturePosition,
  createCapturePosition,
  deleteCapturePosition,
  getCapturePosition,
  inactiveCapturePosition,
  listCapturePosition,
  updateCapturePosition,
} from 'api/setting/capture-position/capture-position.api';
import {
  ActiveCapturePositionRequestModel,
  DeleteCapturePositionRequestModel,
  GetCapturePositionRequestModel,
  InactiveCapturePositionRequestModel,
  ListCapturePositionRequestModel,
  SaveCapturePositionRequestModel,
} from 'models/setting/capture-position/capture-position.model';

export const getCapturePositionAction = createAsyncThunk(
  'GET_CAPTURE_POSITION_REQUEST',
  async (payload: GetCapturePositionRequestModel) =>
    getCapturePosition(payload),
);

export const listCapturePositionAction = createAsyncThunk(
  'LIST_CAPTURE_POSITION_REQUEST',
  async (payload: ListCapturePositionRequestModel) =>
    listCapturePosition(payload),
);

export const createCapturePositionAction = createAsyncThunk(
  'CREATE_CAPTURE_POSITION_REQUEST',
  async (payload: SaveCapturePositionRequestModel, { rejectWithValue }) => {
    try {
      return await createCapturePosition(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateCapturePositionAction = createAsyncThunk(
  'UPDATE_CAPTURE_POSITION_REQUEST',
  async (payload: SaveCapturePositionRequestModel, { rejectWithValue }) => {
    try {
      return await updateCapturePosition(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteCapturePositionAction = createAsyncThunk(
  'DELETE_CAPTURE_POSITION_REQUEST',
  async (payload: DeleteCapturePositionRequestModel, { rejectWithValue }) => {
    try {
      return await deleteCapturePosition(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveCapturePositionAction = createAsyncThunk(
  'INACTIVE_CAPTURE_POSITION_REQUEST',
  async (payload: InactiveCapturePositionRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveCapturePosition(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeCapturePositionAction = createAsyncThunk(
  'ACTIVE_CAPTURE_POSITION_REQUEST',
  async (payload: ActiveCapturePositionRequestModel, { rejectWithValue }) => {
    try {
      return await activeCapturePosition(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
