import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ListAnnualRevenueStatisticRequestModel,
  ListAnnualRevenueStatisticResponseModel,
  ListCaptureModeStatisticRequestModel,
  ListCaptureModeStatisticResponseModel,
  ListLayoutStatisticRequestModel,
  ListLayoutStatisticResponseModel,
  ListMonthlyRevenueStatisticRequestModel,
  ListMonthlyRevenueStatisticResponseModel,
  GetSummaryRevenueStatisticRequestModel,
  GetSummaryRevenueStatisticResponseModel,
  ListThemeStatisticRequestModel,
  ListThemeStatisticResponseModel,
  ListDailyRevenueStatisticRequestModel,
  ListDailyRevenueStatisticResponseModel,
} from 'models/dashboard/dashboard.model';

export function getSummaryRevenueStatistic(
  payload: GetSummaryRevenueStatisticRequestModel,
) {
  return authorizedRequest.get<
    GetSummaryRevenueStatisticResponseModel,
    GetSummaryRevenueStatisticResponseModel
  >(
    `${PREFIX_API.REPORT}/transactions/current-revenue?${preprocessGetQuery(
      payload,
    )}`,
  );
}

export function listLayoutStatistic(payload: ListLayoutStatisticRequestModel) {
  return authorizedRequest.get<
    ListLayoutStatisticResponseModel,
    ListLayoutStatisticResponseModel
  >(
    `${PREFIX_API.REPORT}/layouts/capture-times?${preprocessGetQuery(payload)}`,
  );
}

export function listCaptureModeStatistic(
  payload: ListCaptureModeStatisticRequestModel,
) {
  return authorizedRequest.get<
    ListCaptureModeStatisticResponseModel,
    ListCaptureModeStatisticResponseModel
  >(
    `${
      PREFIX_API.REPORT
    }/transactions/capture-mode-capture-times?${preprocessGetQuery(payload)}`,
  );
}

export function listThemeStatistic(payload: ListThemeStatisticRequestModel) {
  return authorizedRequest.get<
    ListThemeStatisticResponseModel,
    ListThemeStatisticResponseModel
  >(`${PREFIX_API.REPORT}/themes/capture-times?${preprocessGetQuery(payload)}`);
}

export function listAnnualRevenueStatistic(
  payload: ListAnnualRevenueStatisticRequestModel,
) {
  return authorizedRequest.get<
    ListAnnualRevenueStatisticResponseModel,
    ListAnnualRevenueStatisticResponseModel
  >(`${PREFIX_API.REPORT}/stores/revenue-year?${preprocessGetQuery(payload)}`);
}

export function listMonthlyRevenueStatistic(
  payload: ListMonthlyRevenueStatisticRequestModel,
) {
  return authorizedRequest.get<
    ListMonthlyRevenueStatisticResponseModel,
    ListMonthlyRevenueStatisticResponseModel
  >(`${PREFIX_API.REPORT}/stores/revenue-month?${preprocessGetQuery(payload)}`);
}

export function listDailyRevenueStatistic(
  payload: ListDailyRevenueStatisticRequestModel,
) {
  return authorizedRequest.get<
    ListDailyRevenueStatisticResponseModel,
    ListDailyRevenueStatisticResponseModel
  >(`${PREFIX_API.REPORT}/stores/revenue-day?${preprocessGetQuery(payload)}`);
}
