import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ThemeCategoryStateModel } from 'models/theme/theme-category.model';
import {
  activeThemeCategoryAction,
  createThemeCategoryAction,
  deleteThemeCategoryAction,
  getThemeCategoryAction,
  inactiveThemeCategoryAction,
  listThemeCategoryAction,
  updateThemeCategoryAction,
} from './theme-category.action';

const initialState: ThemeCategoryStateModel = {
  themeCategoryListData: null,
  themeCategoryDetail: null,
  loadingList: false,
  triggerSaveThemeCategory: null,
};

export const themeCategorySlice = createSlice({
  name: 'themeCategory',
  initialState,
  reducers: {
    setThemeCategoryStateAction: (
      state,
      action: PayloadAction<Partial<ThemeCategoryStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as ThemeCategoryStateModel),
    resetThemeCategoryStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThemeCategoryAction.pending, (state) => ({
        ...state,
        themeCategoryDetail: null,
      }))
      .addCase(getThemeCategoryAction.fulfilled, (state, { payload }) => ({
        ...state,
        themeCategoryDetail: payload?.data || null,
      }))
      .addCase(getThemeCategoryAction.rejected, (state) => ({
        ...state,
        themeCategoryDetail: null,
      }))
      .addCase(listThemeCategoryAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listThemeCategoryAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        themeCategoryListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listThemeCategoryAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createThemeCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveThemeCategory: { id: uuidv4() },
      }))
      .addCase(updateThemeCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveThemeCategory: { id: uuidv4() },
      }))

      .addCase(deleteThemeCategoryAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteThemeCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveThemeCategory: { id: uuidv4() },
      }))
      .addCase(deleteThemeCategoryAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeThemeCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveThemeCategory: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveThemeCategoryAction.fulfilled, (state) => ({
        ...state,
        triggerSaveThemeCategory: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setThemeCategoryStateAction, resetThemeCategoryStateAction } =
  themeCategorySlice.actions;

export default themeCategorySlice.reducer;
