import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeUiTemplateBooth,
  createUiTemplateBooth,
  deleteUiTemplateBooth,
  getUiTemplateBooth,
  inactiveUiTemplateBooth,
  listUiTemplateBooth,
  updateUiTemplateBooth,
} from 'api/setting/ui-template-booth/ui-template-booth.api';
import {
  ActiveUiTemplateBoothRequestModel,
  DeleteUiTemplateBoothRequestModel,
  GetUiTemplateBoothRequestModel,
  InactiveUiTemplateBoothRequestModel,
  ListUiTemplateBoothRequestModel,
  SaveUiTemplateBoothRequestModel,
} from 'models/setting/ui-template-booth/ui-template-booth.model';

export const getUiTemplateBoothAction = createAsyncThunk(
  'GET_UI_TEMPLATE_BOOTH_REQUEST',
  async (payload: GetUiTemplateBoothRequestModel) =>
    getUiTemplateBooth(payload),
);

export const listUiTemplateBoothAction = createAsyncThunk(
  'LIST_UI_TEMPLATE_BOOTH_REQUEST',
  async (payload: ListUiTemplateBoothRequestModel) =>
    listUiTemplateBooth(payload),
);

export const createUiTemplateBoothAction = createAsyncThunk(
  'CREATE_UI_TEMPLATE_BOOTH_REQUEST',
  async (payload: SaveUiTemplateBoothRequestModel, { rejectWithValue }) => {
    try {
      return await createUiTemplateBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateUiTemplateBoothAction = createAsyncThunk(
  'UPDATE_UI_TEMPLATE_BOOTH_REQUEST',
  async (payload: SaveUiTemplateBoothRequestModel, { rejectWithValue }) => {
    try {
      return await updateUiTemplateBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteUiTemplateBoothAction = createAsyncThunk(
  'DELETE_UI_TEMPLATE_BOOTH_REQUEST',
  async (payload: DeleteUiTemplateBoothRequestModel, { rejectWithValue }) => {
    try {
      return await deleteUiTemplateBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveUiTemplateBoothAction = createAsyncThunk(
  'INACTIVE_UI_TEMPLATE_BOOTH_REQUEST',
  async (payload: InactiveUiTemplateBoothRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveUiTemplateBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeUiTemplateBoothAction = createAsyncThunk(
  'ACTIVE_UI_TEMPLATE_BOOTH_REQUEST',
  async (payload: ActiveUiTemplateBoothRequestModel, { rejectWithValue }) => {
    try {
      return await activeUiTemplateBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
