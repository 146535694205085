import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveVoucherRequestModel,
  ActiveVoucherResponseModel,
  DeleteVoucherRequestModel,
  DeleteVoucherResponseModel,
  GenerateVoucherRequestModel,
  GenerateVoucherResponseModel,
  GetVoucherRequestModel,
  GetVoucherResponseModel,
  InactiveVoucherRequestModel,
  InactiveVoucherResponseModel,
  ListVoucherRequestModel,
  ListVoucherResponseModel,
} from 'models/promotion/voucher.model';

export function getVoucher(payload: GetVoucherRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetVoucherResponseModel,
      GetVoucherResponseModel
    >(`${PREFIX_API.GENERAL}/vouchers/${payload?.id}`);
  }
}

export function listVoucher(payload: ListVoucherRequestModel) {
  return authorizedRequest.get<
    ListVoucherResponseModel,
    ListVoucherResponseModel
  >(`${PREFIX_API.GENERAL}/vouchers?${preprocessGetQuery(payload)}`);
}

export function generateVoucher(payload: GenerateVoucherRequestModel) {
  return authorizedRequest.post<
    GenerateVoucherResponseModel,
    GenerateVoucherResponseModel
  >(`${PREFIX_API.GENERAL}/vouchers?${preprocessGetQuery(payload)}`);
}

export function deleteVoucher(payload: DeleteVoucherRequestModel) {
  return authorizedRequest.delete<
    DeleteVoucherResponseModel,
    DeleteVoucherResponseModel
  >(`${PREFIX_API.GENERAL}/vouchers/${payload?.id}`);
}

export function inactiveVoucher(payload: InactiveVoucherRequestModel) {
  return authorizedRequest.put<
    InactiveVoucherResponseModel,
    InactiveVoucherResponseModel
  >(`${PREFIX_API.GENERAL}/vouchers/${payload?.id}/inactive`);
}

export function activeVoucher(payload: ActiveVoucherRequestModel) {
  return authorizedRequest.put<
    ActiveVoucherResponseModel,
    ActiveVoucherResponseModel
  >(`${PREFIX_API.GENERAL}/vouchers/${payload?.id}/active`);
}
