import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeThemeCategory,
  createThemeCategory,
  deleteThemeCategory,
  getThemeCategory,
  inactiveThemeCategory,
  listThemeCategory,
  updateThemeCategory,
} from 'api/theme/theme-category.api';
import {
  ActiveThemeCategoryRequestModel,
  DeleteThemeCategoryRequestModel,
  GetThemeCategoryRequestModel,
  InactiveThemeCategoryRequestModel,
  ListThemeCategoryRequestModel,
  SaveThemeCategoryRequestModel,
} from 'models/theme/theme-category.model';

export const getThemeCategoryAction = createAsyncThunk(
  'GET_THEME_CATEGORY_REQUEST',
  async (payload: GetThemeCategoryRequestModel) => getThemeCategory(payload),
);

export const listThemeCategoryAction = createAsyncThunk(
  'LIST_THEME_CATEGORY_REQUEST',
  async (payload: ListThemeCategoryRequestModel) => listThemeCategory(payload),
);

export const createThemeCategoryAction = createAsyncThunk(
  'CREATE_THEME_CATEGORY_REQUEST',
  async (payload: SaveThemeCategoryRequestModel, { rejectWithValue }) => {
    try {
      return await createThemeCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateThemeCategoryAction = createAsyncThunk(
  'UPDATE_THEME_CATEGORY_REQUEST',
  async (payload: SaveThemeCategoryRequestModel, { rejectWithValue }) => {
    try {
      return await updateThemeCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteThemeCategoryAction = createAsyncThunk(
  'DELETE_THEME_CATEGORY_REQUEST',
  async (payload: DeleteThemeCategoryRequestModel, { rejectWithValue }) => {
    try {
      return await deleteThemeCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveThemeCategoryAction = createAsyncThunk(
  'INACTIVE_THEME_CATEGORY_REQUEST',
  async (payload: InactiveThemeCategoryRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveThemeCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeThemeCategoryAction = createAsyncThunk(
  'ACTIVE_THEME_CATEGORY_REQUEST',
  async (payload: ActiveThemeCategoryRequestModel, { rejectWithValue }) => {
    try {
      return await activeThemeCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
