import React, { memo } from 'react';
import isEqual from 'react-fast-compare';
import {
  MenuMenu as SUIMenuMenu,
  MenuMenuProps as SUIMenuMenuProps,
} from 'semantic-ui-react';

export type MenuMenuProps = SUIMenuMenuProps & {};

const MenuMenu: React.FC<MenuMenuProps> = ({ ...rest }) => (
  <SUIMenuMenu {...rest} />
);

export default memo(MenuMenu, isEqual);
