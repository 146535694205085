import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeThemeTemplate,
  createThemeTemplate,
  deleteThemeTemplate,
  getThemeTemplate,
  inactiveThemeTemplate,
  listThemeTemplate,
  updateThemeTemplate,
} from 'api/theme/theme-template.api';
import {
  ActiveThemeTemplateRequestModel,
  DeleteThemeTemplateRequestModel,
  GetThemeTemplateRequestModel,
  InactiveThemeTemplateRequestModel,
  ListThemeTemplateRequestModel,
  SaveThemeTemplateRequestModel,
} from 'models/theme/theme-template.model';

export const getThemeTemplateAction = createAsyncThunk(
  'GET_THEME_TEMPLATE_REQUEST',
  async (payload: GetThemeTemplateRequestModel) => getThemeTemplate(payload),
);

export const listThemeTemplateAction = createAsyncThunk(
  'LIST_THEME_TEMPLATE_REQUEST',
  async (payload: ListThemeTemplateRequestModel) => listThemeTemplate(payload),
);

export const createThemeTemplateAction = createAsyncThunk(
  'CREATE_THEME_TEMPLATE_REQUEST',
  async (payload: SaveThemeTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await createThemeTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateThemeTemplateAction = createAsyncThunk(
  'UPDATE_THEME_TEMPLATE_REQUEST',
  async (payload: SaveThemeTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await updateThemeTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteThemeTemplateAction = createAsyncThunk(
  'DELETE_THEME_TEMPLATE_REQUEST',
  async (payload: DeleteThemeTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await deleteThemeTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveThemeTemplateAction = createAsyncThunk(
  'INACTIVE_THEME_TEMPLATE_REQUEST',
  async (payload: InactiveThemeTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveThemeTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeThemeTemplateAction = createAsyncThunk(
  'ACTIVE_THEME_TEMPLATE_REQUEST',
  async (payload: ActiveThemeTemplateRequestModel, { rejectWithValue }) => {
    try {
      return await activeThemeTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
