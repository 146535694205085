import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveStickerCategoryRequestModel,
  ActiveStickerCategoryResponseModel,
  DeleteStickerCategoryRequestModel,
  DeleteStickerCategoryResponseModel,
  GetStickerCategoryRequestModel,
  GetStickerCategoryResponseModel,
  InactiveStickerCategoryRequestModel,
  InactiveStickerCategoryResponseModel,
  ListStickerCategoryRequestModel,
  ListStickerCategoryResponseModel,
  SaveStickerCategoryRequestModel,
  SaveStickerCategoryResponseModel,
} from 'models/sticker/sticker-category.model';

export function getStickerCategory(payload: GetStickerCategoryRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetStickerCategoryResponseModel,
      GetStickerCategoryResponseModel
    >(`${PREFIX_API.GENERAL}/sticker-categories/${payload?.id}`);
  }
}

export function listStickerCategory(payload: ListStickerCategoryRequestModel) {
  return authorizedRequest.get<
    ListStickerCategoryResponseModel,
    ListStickerCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/sticker-categories?${preprocessGetQuery(payload)}`);
}

export function createStickerCategory(
  payload: SaveStickerCategoryRequestModel,
) {
  return authorizedRequest.post<
    SaveStickerCategoryResponseModel,
    SaveStickerCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/sticker-categories`, payload);
}

export function updateStickerCategory(
  payload: SaveStickerCategoryRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveStickerCategoryResponseModel,
      SaveStickerCategoryResponseModel
    >(`${PREFIX_API.GENERAL}/sticker-categories/${payload?.id}`, payload);
  }
}

export function deleteStickerCategory(
  payload: DeleteStickerCategoryRequestModel,
) {
  return authorizedRequest.delete<
    DeleteStickerCategoryResponseModel,
    DeleteStickerCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/sticker-categories/${payload?.id}`);
}

export function inactiveStickerCategory(
  payload: InactiveStickerCategoryRequestModel,
) {
  return authorizedRequest.put<
    InactiveStickerCategoryResponseModel,
    InactiveStickerCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/sticker-categories/${payload?.id}/inactive`);
}

export function activeStickerCategory(
  payload: ActiveStickerCategoryRequestModel,
) {
  return authorizedRequest.put<
    ActiveStickerCategoryResponseModel,
    ActiveStickerCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/sticker-categories/${payload?.id}/active`);
}
