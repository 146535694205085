import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { NewsStateModel } from 'models/setting/news/news.model';
import {
  activeNewsAction,
  createNewsAction,
  deleteNewsAction,
  getNewsAction,
  inactiveNewsAction,
  listNewsAction,
  updateNewsAction,
} from './news.action';

const initialState: NewsStateModel = {
  newsListData: null,
  newsDetail: null,
  triggerSaveNews: null,

  loadingList: false,
  loadingDetail: false,
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNewsStateAction: (
      state,
      action: PayloadAction<Partial<NewsStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as NewsStateModel),
    resetNewsStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsAction.pending, (state) => ({
        ...state,
        newsDetail: null,
        loadingDetail: true,
      }))
      .addCase(getNewsAction.fulfilled, (state, { payload }) => ({
        ...state,
        newsDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getNewsAction.rejected, (state) => ({
        ...state,
        newsDetail: null,
        loadingDetail: false,
      }))
      .addCase(listNewsAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listNewsAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        newsListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listNewsAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createNewsAction.fulfilled, (state) => ({
        ...state,
        triggerSaveNews: { id: uuidv4() },
      }))
      .addCase(updateNewsAction.fulfilled, (state) => ({
        ...state,
        triggerSaveNews: { id: uuidv4() },
      }))

      .addCase(deleteNewsAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteNewsAction.fulfilled, (state) => ({
        ...state,
        triggerSaveNews: { id: uuidv4() },
      }))
      .addCase(deleteNewsAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeNewsAction.fulfilled, (state) => ({
        ...state,
        triggerSaveNews: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveNewsAction.fulfilled, (state) => ({
        ...state,
        triggerSaveNews: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setNewsStateAction, resetNewsStateAction } = newsSlice.actions;

export default newsSlice.reducer;
