import React, { FC, PropsWithChildren, useContext, useMemo } from 'react';
import useOnlineState from 'beautiful-react-hooks/useOnlineState';
import { usePermission } from 'hooks/usePermission';
import { PermissionAllowModel } from 'models/hook/permission.model';
import { NOT_HAVE_PERMISSION } from 'constants/backend-defines.const';

export const Context = React.createContext<AppContextReturnTypes>({
  isOnline: true,
  currentUserAllow: { ...NOT_HAVE_PERMISSION },
});

interface AppContextProps {}
type AppContextReturnTypes = {
  isOnline: boolean;
  currentUserAllow: PermissionAllowModel;
};

export const AppContext: FC<PropsWithChildren<AppContextProps>> = ({
  children,
}) => {
  const isOnline = useOnlineState();
  const { currentUserAllow } = usePermission();

  return (
    <Context.Provider
      value={useMemo(
        () => ({
          isOnline,
          currentUserAllow,
        }),
        [isOnline, currentUserAllow],
      )}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppContext = () => useContext(Context);
