import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  exportTransaction,
  getTransaction,
  listTransaction,
  refundTransaction,
} from 'api/transaction/transaction.api';
import {
  ExportTransactionRequestModel,
  GetTransactionRequestModel,
  ListTransactionRequestModel,
  RefundTransactionRequestModel,
} from 'models/transaction/transaction.model';

export const listTransactionAction = createAsyncThunk(
  'LIST_TRANSACTION_REQUEST',
  async (payload: ListTransactionRequestModel) => listTransaction(payload),
);
export const getTransactionAction = createAsyncThunk(
  'GET_TRANSACTION_REQUEST',
  async (payload: GetTransactionRequestModel) => getTransaction(payload),
);

export const refundTransactionAction = createAsyncThunk(
  'REFUND_TRANSACTION_REQUEST',
  async (payload: RefundTransactionRequestModel) => refundTransaction(payload),
);

export const exportTransactionAction = createAsyncThunk(
  'EXPORT_TRANSACTION_REQUEST',
  async (payload: ExportTransactionRequestModel) => exportTransaction(payload),
);
