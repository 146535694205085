import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { CapturePositionTemplateStateModel } from 'models/setting/capture-position/capture-position-template.model';
import {
  activeCapturePositionTemplateAction,
  createCapturePositionTemplateAction,
  deleteCapturePositionTemplateAction,
  getCapturePositionTemplateAction,
  inactiveCapturePositionTemplateAction,
  listCapturePositionTemplateAction,
  updateCapturePositionTemplateAction,
} from './capture-position-template.action';

const initialState: CapturePositionTemplateStateModel = {
  capturePositionTemplateListData: null,
  capturePositionTemplateDetail: null,
  loadingList: false,
  triggerSaveCapturePositionTemplate: null,
};

export const capturePositionTemplateSlice = createSlice({
  name: 'capturePositionTemplate',
  initialState,
  reducers: {
    setCapturePositionTemplateStateAction: (
      state,
      action: PayloadAction<Partial<CapturePositionTemplateStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as CapturePositionTemplateStateModel),
    resetCapturePositionTemplateStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCapturePositionTemplateAction.pending, (state) => ({
        ...state,
        capturePositionTemplateDetail: null,
      }))
      .addCase(
        getCapturePositionTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          capturePositionTemplateDetail: payload?.data || null,
        }),
      )
      .addCase(getCapturePositionTemplateAction.rejected, (state) => ({
        ...state,
        capturePositionTemplateDetail: null,
      }))
      .addCase(listCapturePositionTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(
        listCapturePositionTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          loadingList: false,
          capturePositionTemplateListData: {
            items: payload?.data?.items || [],
            totalItems: payload?.data?.totalItems || 0,
            totalPages: payload?.data?.totalPages || 0,
          },
        }),
      )
      .addCase(listCapturePositionTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createCapturePositionTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCapturePositionTemplate: { id: uuidv4() },
      }))
      .addCase(updateCapturePositionTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCapturePositionTemplate: { id: uuidv4() },
      }))

      .addCase(deleteCapturePositionTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteCapturePositionTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCapturePositionTemplate: { id: uuidv4() },
      }))
      .addCase(deleteCapturePositionTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeCapturePositionTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCapturePositionTemplate: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveCapturePositionTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCapturePositionTemplate: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const {
  setCapturePositionTemplateStateAction,
  resetCapturePositionTemplateStateAction,
} = capturePositionTemplateSlice.actions;

export default capturePositionTemplateSlice.reducer;
