import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeBoothBackgroundCategory,
  createBoothBackgroundCategory,
  deleteBoothBackgroundCategory,
  getBoothBackgroundCategory,
  inactiveBoothBackgroundCategory,
  listBoothBackgroundCategory,
  updateBoothBackgroundCategory,
} from 'api/booth-background/booth-background-category.api';
import {
  ActiveBoothBackgroundCategoryRequestModel,
  DeleteBoothBackgroundCategoryRequestModel,
  GetBoothBackgroundCategoryRequestModel,
  InactiveBoothBackgroundCategoryRequestModel,
  ListBoothBackgroundCategoryRequestModel,
  SaveBoothBackgroundCategoryRequestModel,
} from 'models/booth-background/booth-background-category.model';

export const getBoothBackgroundCategoryAction = createAsyncThunk(
  'GET_BOOTH_BACKGROUND_CATEGORY_REQUEST',
  async (payload: GetBoothBackgroundCategoryRequestModel) =>
    getBoothBackgroundCategory(payload),
);

export const listBoothBackgroundCategoryAction = createAsyncThunk(
  'LIST_BOOTH_BACKGROUND_CATEGORY_REQUEST',
  async (payload: ListBoothBackgroundCategoryRequestModel) =>
    listBoothBackgroundCategory(payload),
);

export const createBoothBackgroundCategoryAction = createAsyncThunk(
  'CREATE_BOOTH_BACKGROUND_CATEGORY_REQUEST',
  async (
    payload: SaveBoothBackgroundCategoryRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await createBoothBackgroundCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateBoothBackgroundCategoryAction = createAsyncThunk(
  'UPDATE_BOOTH_BACKGROUND_CATEGORY_REQUEST',
  async (
    payload: SaveBoothBackgroundCategoryRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await updateBoothBackgroundCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteBoothBackgroundCategoryAction = createAsyncThunk(
  'DELETE_BOOTH_BACKGROUND_CATEGORY_REQUEST',
  async (
    payload: DeleteBoothBackgroundCategoryRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await deleteBoothBackgroundCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveBoothBackgroundCategoryAction = createAsyncThunk(
  'INACTIVE_BOOTH_BACKGROUND_CATEGORY_REQUEST',
  async (
    payload: InactiveBoothBackgroundCategoryRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await inactiveBoothBackgroundCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeBoothBackgroundCategoryAction = createAsyncThunk(
  'ACTIVE_BOOTH_BACKGROUND_CATEGORY_REQUEST',
  async (
    payload: ActiveBoothBackgroundCategoryRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await activeBoothBackgroundCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
