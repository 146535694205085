import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeStickerCategory,
  createStickerCategory,
  deleteStickerCategory,
  getStickerCategory,
  inactiveStickerCategory,
  listStickerCategory,
  updateStickerCategory,
} from 'api/sticker/sticker-category.api';
import {
  ActiveStickerCategoryRequestModel,
  DeleteStickerCategoryRequestModel,
  GetStickerCategoryRequestModel,
  InactiveStickerCategoryRequestModel,
  ListStickerCategoryRequestModel,
  SaveStickerCategoryRequestModel,
} from 'models/sticker/sticker-category.model';

export const getStickerCategoryAction = createAsyncThunk(
  'GET_STICKER_CATEGORY_REQUEST',
  async (payload: GetStickerCategoryRequestModel) =>
    getStickerCategory(payload),
);

export const listStickerCategoryAction = createAsyncThunk(
  'LIST_STICKER_CATEGORY_REQUEST',
  async (payload: ListStickerCategoryRequestModel) =>
    listStickerCategory(payload),
);

export const createStickerCategoryAction = createAsyncThunk(
  'CREATE_STICKER_CATEGORY_REQUEST',
  async (payload: SaveStickerCategoryRequestModel, { rejectWithValue }) => {
    try {
      return await createStickerCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateStickerCategoryAction = createAsyncThunk(
  'UPDATE_STICKER_CATEGORY_REQUEST',
  async (payload: SaveStickerCategoryRequestModel, { rejectWithValue }) => {
    try {
      return await updateStickerCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteStickerCategoryAction = createAsyncThunk(
  'DELETE_STICKER_CATEGORY_REQUEST',
  async (payload: DeleteStickerCategoryRequestModel, { rejectWithValue }) => {
    try {
      return await deleteStickerCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveStickerCategoryAction = createAsyncThunk(
  'INACTIVE_STICKER_CATEGORY_REQUEST',
  async (payload: InactiveStickerCategoryRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveStickerCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeStickerCategoryAction = createAsyncThunk(
  'ACTIVE_STICKER_CATEGORY_REQUEST',
  async (payload: ActiveStickerCategoryRequestModel, { rejectWithValue }) => {
    try {
      return await activeStickerCategory(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
