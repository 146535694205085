type ConfigModel = NodeJS.ProcessEnv & {
  REACT_APP_BASE_URL: string;
  REACT_APP_IDP_BASE_URL: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_CLIENT_SECRET: string;
  REACT_APP_AUTH_SCOPE: string;
  REACT_APP_TINY_MCE_API_KEY: string;
};

const CONFIG = process.env as ConfigModel;

export default CONFIG;
