import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ThemeStateModel } from 'models/theme/theme.model';
import {
  activeThemeAction,
  createThemeAction,
  createThemeLayoutAction,
  deleteThemeAction,
  getThemeAction,
  inactiveThemeAction,
  listThemeAction,
  updateThemeAction,
  updateThemeLayoutAction,
} from './theme.action';

const initialState: ThemeStateModel = {
  themeListData: null,
  themeDetail: null,
  triggerSaveTheme: null,

  loadingList: false,
  loadingDetail: false,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeStateAction: (
      state,
      action: PayloadAction<Partial<ThemeStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as ThemeStateModel),
    resetThemeStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThemeAction.pending, (state) => ({
        ...state,
        themeDetail: null,
        loadingDetail: true,
      }))
      .addCase(getThemeAction.fulfilled, (state, { payload }) => ({
        ...state,
        themeDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getThemeAction.rejected, (state) => ({
        ...state,
        themeDetail: null,
        loadingDetail: false,
      }))
      .addCase(listThemeAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listThemeAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        themeListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listThemeAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createThemeAction.fulfilled, (state) => ({
        ...state,
        triggerSaveTheme: { id: uuidv4() },
      }))
      .addCase(updateThemeAction.fulfilled, (state) => ({
        ...state,
        triggerSaveTheme: { id: uuidv4() },
      }))

      .addCase(deleteThemeAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteThemeAction.fulfilled, (state) => ({
        ...state,
        triggerSaveTheme: { id: uuidv4() },
      }))
      .addCase(deleteThemeAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createThemeLayoutAction.fulfilled, (state) => ({
        ...state,
        triggerSaveTheme: { id: uuidv4() },
      }))
      .addCase(updateThemeLayoutAction.fulfilled, (state) => ({
        ...state,
        triggerSaveTheme: { id: uuidv4() },
      }))
      .addCase(activeThemeAction.fulfilled, (state) => ({
        ...state,
        triggerSaveTheme: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveThemeAction.fulfilled, (state) => ({
        ...state,
        triggerSaveTheme: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setThemeStateAction, resetThemeStateAction } =
  themeSlice.actions;

export default themeSlice.reducer;
