import React, { FC, HTMLAttributes, ReactElement, memo } from 'react';
import isEqual from 'react-fast-compare';
import Tooltip, {
  TooltipProps as TooltipPropsProps,
} from 'components/tooltip/Tooltip';
import Icon from 'components/icon/Icon';
import './hook-form.css';

export type FormFieldLabelProps = HTMLAttributes<HTMLLabelElement> & {
  id?: string;
  infoContent?: string | ReactElement;
  TooltipProps?: TooltipPropsProps;
  content?: string | ReactElement;
  required?: boolean;
};

const FormFieldLabel: FC<FormFieldLabelProps> = ({
  id = '',
  required,
  content,
  infoContent,
  TooltipProps,
  ...rest
}) => (
  <>
    {!!content && (
      <label
        htmlFor={id}
        {...rest}
        style={{ textAlign: 'left', ...rest?.style }}
      >
        {infoContent && (
          <Tooltip
            trigger={<Icon name="info circle" />}
            {...TooltipProps}
            content={infoContent}
          />
        )}
        {content} {!!required && <span className="hf-error-text">*</span>}
      </label>
    )}
  </>
);

export default memo(FormFieldLabel, isEqual);
