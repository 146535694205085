import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import {
  ExportTransactionRequestModel,
  ExportTransactionResponseModel,
  GetTransactionRequestModel,
  GetTransactionResponseModel,
  ListTransactionRequestModel,
  ListTransactionResponseModel,
  RefundTransactionRequestModel,
  RefundTransactionResponseModel,
} from 'models/transaction/transaction.model';
import { preprocessGetQuery } from 'helpers/api.helper';
import { getFormData } from 'helpers/form-data.helper';

export function getTransaction(payload: GetTransactionRequestModel) {
  return authorizedRequest.get<
    GetTransactionResponseModel,
    GetTransactionResponseModel
  >(`${PREFIX_API.GENERAL}/transactions/${payload?.id}/get-by-id`);
}

export function listTransaction(payload: ListTransactionRequestModel) {
  return authorizedRequest.get<
    ListTransactionResponseModel,
    ListTransactionResponseModel
  >(`${PREFIX_API.GENERAL}/transactions?${preprocessGetQuery(payload)}`);
}

export function refundTransaction(payload: RefundTransactionRequestModel) {
  const formDataPayload = getFormData(payload);

  return authorizedRequest.put<
    RefundTransactionResponseModel,
    RefundTransactionResponseModel
  >(
    `${PREFIX_API.GENERAL}/transactions/${payload?.id}/refund`,
    formDataPayload,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
}

export function exportTransaction(payload: ExportTransactionRequestModel) {
  return authorizedRequest.get<
    ExportTransactionResponseModel,
    ExportTransactionResponseModel
  >(
    `${PREFIX_API.GENERAL}/transactions/export-excel?${preprocessGetQuery(
      payload,
    )}`,
  );
}
