import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeBooth,
  createBooth,
  deleteBooth,
  getBooth,
  getBoothIdentity,
  inactiveBooth,
  listBooth,
  updateBooth,
  updateBoothVersion,
} from 'api/booth/booth.api';
import {
  ActiveBoothRequestModel,
  DeleteBoothRequestModel,
  GetBoothIdentityRequestModel,
  GetBoothRequestModel,
  InactiveBoothRequestModel,
  ListBoothRequestModel,
  SaveBoothRequestModel,
  UpdateBoothVersionRequestModel,
} from 'models/booth/booth.model';

export const getBoothAction = createAsyncThunk(
  'GET_BOOTH_REQUEST',
  async (payload: GetBoothRequestModel) => getBooth(payload),
);

export const getBoothIdentityAction = createAsyncThunk(
  'GET_BOOTH_IDENTITY_REQUEST',
  async (payload: GetBoothIdentityRequestModel) => getBoothIdentity(payload),
);

export const listBoothAction = createAsyncThunk(
  'LIST_BOOTH_REQUEST',
  async (payload: ListBoothRequestModel) => listBooth(payload),
);

export const createBoothAction = createAsyncThunk(
  'CREATE_BOOTH_REQUEST',
  async (payload: SaveBoothRequestModel, { rejectWithValue }) => {
    try {
      return await createBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateBoothAction = createAsyncThunk(
  'UPDATE_BOOTH_REQUEST',
  async (payload: SaveBoothRequestModel, { rejectWithValue }) => {
    try {
      return await updateBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateBoothVersionAction = createAsyncThunk(
  'UPDATE_BOOTH_VERSION_REQUEST',
  async (payload: UpdateBoothVersionRequestModel, { rejectWithValue }) => {
    try {
      return await updateBoothVersion(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteBoothAction = createAsyncThunk(
  'DELETE_BOOTH_REQUEST',
  async (payload: DeleteBoothRequestModel, { rejectWithValue }) => {
    try {
      return await deleteBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveBoothAction = createAsyncThunk(
  'INACTIVE_BOOTH_REQUEST',
  async (payload: InactiveBoothRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeBoothAction = createAsyncThunk(
  'ACTIVE_BOOTH_REQUEST',
  async (payload: ActiveBoothRequestModel, { rejectWithValue }) => {
    try {
      return await activeBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
