import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { AuthStateModel } from 'models/auth/auth.model';
import { AUTHENTICATE_PERSIST_KEY } from 'constants/store.const';
import { loginAction, logoutAction, refreshTokenAction } from './auth.action';

const initialState: AuthStateModel = {
  token: '',
  loginData: null,
  loading: false,
  refreshLoading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthStateAction: (
      state,
      action: PayloadAction<Partial<AuthStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as AuthStateModel),
    resetAuthStateAction: () => ({ ...initialState }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginAction.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(loginAction.fulfilled, (state, { payload }) => ({
        ...state,
        token: payload?.access_token || '',
        loginData: payload ?? null,
        loading: false,
      }))
      .addCase(loginAction.rejected, (state) => ({
        ...state,
        loading: false,
      }))
      .addCase(logoutAction.fulfilled, (state) => ({
        ...state,
        token: '',
        loginData: null,
      }))
      .addCase(refreshTokenAction.pending, (state) => ({
        ...state,
        refreshLoading: true,
      }))
      .addCase(refreshTokenAction.fulfilled, (state, { payload }) => ({
        ...state,
        token: payload?.access_token || '',
        loginData: payload ?? null,
        refreshLoading: false,
      }))
      .addCase(refreshTokenAction.rejected, (state) => ({
        ...state,
        refreshLoading: false,
      }));
  },
});

export const { setAuthStateAction, resetAuthStateAction } = authSlice.actions;

const persistConfig = {
  key: AUTHENTICATE_PERSIST_KEY,
  whitelist: ['token', 'loginData'],
  storage,
};

export const authReducer = persistReducer(persistConfig, authSlice.reducer);
