import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import qs from 'query-string';
import { TIME_OUT_API } from 'constants/api.const';
import CONFIG from '../../config';
import { tokenManager } from './tokenManager';

export const axiosUserContext: {
  dispatchLogout?: Function;
  dispatchRefreshToken?: Function;
} = {};

const CancelToken = axios.CancelToken.source();
const authorizedRequest: AxiosInstance = axios.create({
  baseURL: CONFIG.REACT_APP_BASE_URL,
  cancelToken: CancelToken.token,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: TIME_OUT_API,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: 'separator' }),
  },
});

authorizedRequest.interceptors.request.use((config) => {
  const { token } = tokenManager || {};

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

authorizedRequest.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response?.data?.code >= 400 && response?.data?.code <= 500) {
      throw Object.assign(response?.data ?? {}, {
        message:
          response?.data?.errors?.[0]?.errorMessage || response?.data?.message,
      });
    }
    return response?.data;
  },
  (error: AxiosError<any>) => {
    if (
      error?.response?.status === 401 &&
      error?.config?.url !== `${CONFIG.REACT_APP_IDP_BASE_URL}/connect/token`
    ) {
      axiosUserContext?.dispatchRefreshToken?.();
    }
    return Promise.reject(error);
  },
);

export default authorizedRequest;
