import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  DeleteLocationRequestModel,
  DeleteLocationResponseModel,
  ListLocationRequestModel,
  ListLocationResponseModel,
  SaveLocationRequestModel,
  SaveLocationResponseModel,
} from 'models/location/location.model';

export function listLocation(payload: ListLocationRequestModel) {
  return authorizedRequest.get<
    ListLocationResponseModel,
    ListLocationResponseModel
  >(`${PREFIX_API.GENERAL}/locations?${preprocessGetQuery(payload)}`);
}

export function createLocation(payload: SaveLocationRequestModel) {
  return authorizedRequest.post<
    SaveLocationResponseModel,
    SaveLocationResponseModel
  >(`${PREFIX_API.GENERAL}/locations?${preprocessGetQuery(payload)}`);
}

export function updateLocation(payload: SaveLocationRequestModel) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveLocationResponseModel,
      SaveLocationResponseModel
    >(`${PREFIX_API.GENERAL}/locations/${payload?.id}`, payload);
  }
}

export function deleteLocation(payload: DeleteLocationRequestModel) {
  return authorizedRequest.delete<
    DeleteLocationResponseModel,
    DeleteLocationResponseModel
  >(`${PREFIX_API.GENERAL}/locations/${payload?.id}`);
}
