import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import { getFormData } from 'helpers/form-data.helper';
import {
  ActiveUiTemplateBoothRequestModel,
  ActiveUiTemplateBoothResponseModel,
  DeleteUiTemplateBoothRequestModel,
  DeleteUiTemplateBoothResponseModel,
  GetUiTemplateBoothRequestModel,
  GetUiTemplateBoothResponseModel,
  InactiveUiTemplateBoothRequestModel,
  InactiveUiTemplateBoothResponseModel,
  ListUiTemplateBoothRequestModel,
  ListUiTemplateBoothResponseModel,
  SaveUiTemplateBoothRequestModel,
  SaveUiTemplateBoothResponseModel,
} from 'models/setting/ui-template-booth/ui-template-booth.model';

export function getUiTemplateBooth(payload: GetUiTemplateBoothRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetUiTemplateBoothResponseModel,
      GetUiTemplateBoothResponseModel
    >(`${PREFIX_API.GENERAL}/ui-template-booths/${payload?.id}`);
  }
}

export function listUiTemplateBooth(payload: ListUiTemplateBoothRequestModel) {
  return authorizedRequest.get<
    ListUiTemplateBoothResponseModel,
    ListUiTemplateBoothResponseModel
  >(`${PREFIX_API.GENERAL}/ui-template-booths?${preprocessGetQuery(payload)}`);
}

export function createUiTemplateBooth(
  payload: SaveUiTemplateBoothRequestModel,
) {
  const formDataPayload = getFormData(payload);

  return authorizedRequest.post<
    SaveUiTemplateBoothResponseModel,
    SaveUiTemplateBoothResponseModel
  >(`${PREFIX_API.GENERAL}/ui-template-booths`, formDataPayload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function updateUiTemplateBooth(
  payload: SaveUiTemplateBoothRequestModel,
) {
  if (payload?.id) {
    const formDataPayload = getFormData(payload);

    return authorizedRequest.put<
      SaveUiTemplateBoothResponseModel,
      SaveUiTemplateBoothResponseModel
    >(
      `${PREFIX_API.GENERAL}/ui-template-booths/${payload?.id}`,
      formDataPayload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  }
}

export function deleteUiTemplateBooth(
  payload: DeleteUiTemplateBoothRequestModel,
) {
  return authorizedRequest.delete<
    DeleteUiTemplateBoothResponseModel,
    DeleteUiTemplateBoothResponseModel
  >(`${PREFIX_API.GENERAL}/ui-template-booths/${payload?.id}`);
}

export function inactiveUiTemplateBooth(
  payload: InactiveUiTemplateBoothRequestModel,
) {
  return authorizedRequest.put<
    InactiveUiTemplateBoothResponseModel,
    InactiveUiTemplateBoothResponseModel
  >(
    `${PREFIX_API.GENERAL}/ui-template-booths/${
      payload?.id
    }/inactive?${preprocessGetQuery(payload)}`,
    payload,
  );
}

export function activeUiTemplateBooth(
  payload: ActiveUiTemplateBoothRequestModel,
) {
  return authorizedRequest.put<
    ActiveUiTemplateBoothResponseModel,
    ActiveUiTemplateBoothResponseModel
  >(
    `${PREFIX_API.GENERAL}/ui-template-booths/${
      payload?.id
    }/active?${preprocessGetQuery(payload)}`,
    payload,
  );
}
