import React, { ComponentClass, FC, useMemo } from 'react';
import {
  AuthRouteConst,
  AppRouteConst,
  NoNeedPermissionRoute,
} from 'constants/route.const';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/store-hooks';
import { usePermission } from 'hooks/usePermission';
import { includesId } from 'helpers/string.helper';

interface WithAuthenticateOption {
  needAuthenticated?: boolean;
  unMatchingRedirect?: string;
}

// Todo: refactor code
export default function withAuthenticate(
  InnerComponent: ComponentClass<any> | FC,
  customOptions?: WithAuthenticateOption,
): FC {
  const options = {
    needAuthenticated: true,
    ...customOptions,
  };
  if (!options.unMatchingRedirect) {
    options.unMatchingRedirect = options.needAuthenticated
      ? AuthRouteConst.LOGIN
      : AppRouteConst.HOME;
  }
  const WithAuthenticate: FC = (props: any) => {
    const { ...otherProps } = props;
    const { pathname } = useLocation();
    const { token } = useAppSelector((state) => state.auth);
    const { userIsLogging } = useAppSelector((state) => state.user);
    const { getDefaultRoute } = usePermission();

    const isAuthenticate = useMemo(() => !!token, [token]);

    // Nếu chưa đăng nhập tự động redirect đến login
    if (options.needAuthenticated !== isAuthenticate) {
      return <Navigate to={{ pathname: options.unMatchingRedirect }} replace />;
    }
    // Nếu đi vào trang dashboard mặc định từ khi login, check nếu không có acception
    // Thì auto redirect đến trang đầu tiên có cho phép (getDefaultRoute in usePermission)
    if (
      userIsLogging &&
      !includesId(NoNeedPermissionRoute, pathname) &&
      pathname === AppRouteConst.DASHBOARD &&
      !!getDefaultRoute() &&
      pathname !== getDefaultRoute()
    ) {
      return <Navigate to={{ pathname: getDefaultRoute() }} replace />;
    }
    return (
      <ErrorBoundary>
        <InnerComponent {...otherProps} />
      </ErrorBoundary>
    );
  };

  return WithAuthenticate;
}
