import { REVERSE_DATE_FORMAT } from 'constants/time.const';
import { reduce } from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import { ThenResponseTypes } from 'models/common.model';
import { toastError, toastSuccess } from './toast.helper';

/**
 * Xử lý dữ liệu payload trước khi call api
 *
 * @category Object
 * @param object
 * @returns Returns string.
 * @example
 * { a: 1, b: 20 } => a=1&b=20
 *
 */
function preprocessGetQuery(payload: any = {}) {
  return qs.stringify(
    reduce(
      payload,
      (stackPayload, item, key) => ({
        ...stackPayload,
        [key]: item,
        ...((item instanceof Date || item instanceof moment) && {
          [key]: moment(item).format(REVERSE_DATE_FORMAT),
        }),
      }),
      payload,
    ),
  );
}

/**
 * Xử lý response của api
 */
function handleDispatchThen(
  { error, payload }: ThenResponseTypes | any,
  successFunction: (data: any) => void = () => {},
  successMessage: string = '',
  errorMessage: string = '',
) {
  if (!error) {
    toastSuccess(successMessage);
    successFunction?.(payload);
  } else {
    toastError(payload?.data?.message || errorMessage);
  }
  return qs.stringify(payload);
}

export { preprocessGetQuery, handleDispatchThen };
