import { PermissionAllowModel } from 'models/hook/permission.model';

export const BE_DEFINES = {
  HUB_TYPE: {
    BILL_ACCEPTOR: 1,
    REMOTE: 0,
  },
  REPORT_REVENUE_SHOOTING_TYPES: {
    REVENUE: 1,
    SHOOTING_COUNT: 2,
  },
  DEPOSIT_MACHINE_TYPE: {
    MONEY_IN: 1,
    MONEY_OUT: 2,
  },
  BOOTH_TYPE: {
    NORMAL: 1,
    ROBOT_ARM: 2,
    OVER_HEAD: 3,
  },
  SCREEN_TYPE: {
    HORIZONTAL: 1,
    VERTICAL: 2,
  },
  BOOTH_VERSION_TYPE: {
    UPDATE_SYNC: 1, // cập nhật service đồng bộ
    UPDATE_BE_API: 2, // cập nhật api BE
  },
};

export enum ROLE_TYPES {
  Admin = 'Admin',
  Manager = 'Manager',
  Franchiser = 'Franchiser',
  FranchiserManager = 'FranchiserManager',
  BoothReceptionist = 'BoothReceptionist',
  Sale = 'Sale',
}

export const NOT_HAVE_PERMISSION: PermissionAllowModel = {
  canView: false,
  canUpdate: false,
  canDelete: false,
  canSeeBoothId: false,
  canRefund: false,
};

export const HAVE_ALL_PERMISSION: PermissionAllowModel = {
  canView: true,
  canUpdate: true,
  canDelete: true,
  canSeeBoothId: true,
  canRefund: true,
};
