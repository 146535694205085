import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { CompanyStateModel } from 'models/company/company.model';
import {
  activeCompanyAction,
  createCompanyAction,
  deleteCompanyAction,
  getCompanyAction,
  inactiveCompanyAction,
  listCompanyAction,
  updateCompanyAction,
} from './company.action';

const initialState: CompanyStateModel = {
  companyListData: null,
  companyDetail: null,
  triggerSaveCompany: null,

  loadingList: false,
  loadingDetail: false,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyStateAction: (
      state,
      action: PayloadAction<Partial<CompanyStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as CompanyStateModel),
    resetCompanyStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyAction.pending, (state) => ({
        ...state,
        companyDetail: null,
        loadingDetail: true,
      }))
      .addCase(getCompanyAction.fulfilled, (state, { payload }) => ({
        ...state,
        companyDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getCompanyAction.rejected, (state) => ({
        ...state,
        companyDetail: null,
        loadingDetail: false,
      }))
      .addCase(listCompanyAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listCompanyAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        companyListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listCompanyAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createCompanyAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCompany: { id: uuidv4() },
      }))
      .addCase(updateCompanyAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCompany: { id: uuidv4() },
      }))

      .addCase(deleteCompanyAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteCompanyAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCompany: { id: uuidv4() },
      }))
      .addCase(deleteCompanyAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeCompanyAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCompany: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveCompanyAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCompany: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setCompanyStateAction, resetCompanyStateAction } =
  companySlice.actions;

export default companySlice.reducer;
