import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import { getFormData } from 'helpers/form-data.helper';
import {
  ActiveCapturePositionRequestModel,
  ActiveCapturePositionResponseModel,
  DeleteCapturePositionRequestModel,
  DeleteCapturePositionResponseModel,
  GetCapturePositionRequestModel,
  GetCapturePositionResponseModel,
  InactiveCapturePositionRequestModel,
  InactiveCapturePositionResponseModel,
  ListCapturePositionRequestModel,
  ListCapturePositionResponseModel,
  SaveCapturePositionRequestModel,
  SaveCapturePositionResponseModel,
} from 'models/setting/capture-position/capture-position.model';

export function getCapturePosition(payload: GetCapturePositionRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetCapturePositionResponseModel,
      GetCapturePositionResponseModel
    >(`${PREFIX_API.GENERAL}/capture-positions/${payload?.id}`);
  }
}

export function listCapturePosition(payload: ListCapturePositionRequestModel) {
  return authorizedRequest.get<
    ListCapturePositionResponseModel,
    ListCapturePositionResponseModel
  >(`${PREFIX_API.GENERAL}/capture-positions?${preprocessGetQuery(payload)}`);
}

export function createCapturePosition(
  payload: SaveCapturePositionRequestModel,
) {
  const formDataPayload = getFormData(payload);

  return authorizedRequest.post<
    SaveCapturePositionResponseModel,
    SaveCapturePositionResponseModel
  >(`${PREFIX_API.GENERAL}/capture-positions`, formDataPayload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function updateCapturePosition(
  payload: SaveCapturePositionRequestModel,
) {
  const formDataPayload = getFormData(payload);

  if (payload?.id) {
    return authorizedRequest.put<
      SaveCapturePositionResponseModel,
      SaveCapturePositionResponseModel
    >(
      `${PREFIX_API.GENERAL}/capture-positions/${payload?.id}`,
      formDataPayload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  }
}

export function deleteCapturePosition(
  payload: DeleteCapturePositionRequestModel,
) {
  return authorizedRequest.delete<
    DeleteCapturePositionResponseModel,
    DeleteCapturePositionResponseModel
  >(`${PREFIX_API.GENERAL}/capture-positions/${payload?.id}`);
}

export function inactiveCapturePosition(
  payload: InactiveCapturePositionRequestModel,
) {
  return authorizedRequest.put<
    InactiveCapturePositionResponseModel,
    InactiveCapturePositionResponseModel
  >(`${PREFIX_API.GENERAL}/capture-positions/${payload?.id}/inactive`);
}

export function activeCapturePosition(
  payload: ActiveCapturePositionRequestModel,
) {
  return authorizedRequest.put<
    ActiveCapturePositionResponseModel,
    ActiveCapturePositionResponseModel
  >(`${PREFIX_API.GENERAL}/capture-positions/${payload?.id}/active`);
}
