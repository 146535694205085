import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import { getFormData } from 'helpers/form-data.helper';
import {
  ActiveFaqRequestModel,
  ActiveFaqResponseModel,
  DeleteFaqRequestModel,
  DeleteFaqResponseModel,
  GetFaqRequestModel,
  GetFaqResponseModel,
  InactiveFaqRequestModel,
  InactiveFaqResponseModel,
  ListFaqRequestModel,
  ListFaqResponseModel,
  SaveFaqRequestModel,
  SaveFaqResponseModel,
} from 'models/setting/faq/faq.model';

export function getFaq(payload: GetFaqRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<GetFaqResponseModel, GetFaqResponseModel>(
      `${PREFIX_API.GENERAL}/fa-questions/${payload?.id}`,
    );
  }
}

export function listFaq(payload: ListFaqRequestModel) {
  return authorizedRequest.get<ListFaqResponseModel, ListFaqResponseModel>(
    `${PREFIX_API.GENERAL}/fa-questions?${preprocessGetQuery(payload)}`,
  );
}

export function createFaq(payload: SaveFaqRequestModel) {
  const formDataPayload = getFormData(payload);

  return authorizedRequest.post<SaveFaqResponseModel, SaveFaqResponseModel>(
    `${PREFIX_API.GENERAL}/fa-questions`,
    formDataPayload,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
}

export function updateFaq(payload: SaveFaqRequestModel) {
  const formDataPayload = getFormData(payload);

  if (payload?.id) {
    return authorizedRequest.put<SaveFaqResponseModel, SaveFaqResponseModel>(
      `${PREFIX_API.GENERAL}/fa-questions/${payload?.id}`,
      formDataPayload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  }
}

export function deleteFaq(payload: DeleteFaqRequestModel) {
  return authorizedRequest.delete<
    DeleteFaqResponseModel,
    DeleteFaqResponseModel
  >(`${PREFIX_API.GENERAL}/fa-questions/${payload?.id}`);
}

export function inactiveFaq(payload: InactiveFaqRequestModel) {
  return authorizedRequest.put<
    InactiveFaqResponseModel,
    InactiveFaqResponseModel
  >(`${PREFIX_API.GENERAL}/fa-questions/${payload?.id}/inactive`);
}

export function activeFaq(payload: ActiveFaqRequestModel) {
  return authorizedRequest.put<ActiveFaqResponseModel, ActiveFaqResponseModel>(
    `${PREFIX_API.GENERAL}/fa-questions/${payload?.id}/active`,
  );
}
