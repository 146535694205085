import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { BoothBackgroundTemplateStateModel } from 'models/booth-background/booth-background-template.model';
import {
  activeBoothBackgroundTemplateAction,
  createBoothBackgroundTemplateAction,
  deleteBoothBackgroundTemplateAction,
  getBoothBackgroundTemplateAction,
  inactiveBoothBackgroundTemplateAction,
  listBoothBackgroundTemplateAction,
  updateBoothBackgroundTemplateAction,
} from './booth-background-template.action';

const initialState: BoothBackgroundTemplateStateModel = {
  boothBackgroundTemplateListData: null,
  boothBackgroundTemplateDetail: null,
  loadingList: false,
  triggerSaveBoothBackgroundTemplate: null,
};

export const boothBackgroundTemplateSlice = createSlice({
  name: 'booth-backgroundTemplate',
  initialState,
  reducers: {
    setBoothBackgroundTemplateStateAction: (
      state,
      action: PayloadAction<Partial<BoothBackgroundTemplateStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as BoothBackgroundTemplateStateModel),
    resetBoothBackgroundTemplateStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoothBackgroundTemplateAction.pending, (state) => ({
        ...state,
        boothBackgroundTemplateDetail: null,
      }))
      .addCase(
        getBoothBackgroundTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          boothBackgroundTemplateDetail: payload?.data || null,
        }),
      )
      .addCase(getBoothBackgroundTemplateAction.rejected, (state) => ({
        ...state,
        boothBackgroundTemplateDetail: null,
      }))
      .addCase(listBoothBackgroundTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(
        listBoothBackgroundTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          loadingList: false,
          boothBackgroundTemplateListData: {
            items: payload?.data?.items || [],
            totalItems: payload?.data?.totalItems || 0,
            totalPages: payload?.data?.totalPages || 0,
          },
        }),
      )
      .addCase(listBoothBackgroundTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createBoothBackgroundTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackgroundTemplate: { id: uuidv4() },
      }))
      .addCase(updateBoothBackgroundTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackgroundTemplate: { id: uuidv4() },
      }))

      .addCase(deleteBoothBackgroundTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteBoothBackgroundTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackgroundTemplate: { id: uuidv4() },
      }))
      .addCase(deleteBoothBackgroundTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeBoothBackgroundTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackgroundTemplate: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveBoothBackgroundTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveBoothBackgroundTemplate: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const {
  setBoothBackgroundTemplateStateAction,
  resetBoothBackgroundTemplateStateAction,
} = boothBackgroundTemplateSlice.actions;

export default boothBackgroundTemplateSlice.reducer;
