import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { StickerStateModel } from 'models/sticker/sticker.model';
import {
  activeStickerAction,
  createStickerAction,
  deleteStickerAction,
  getStickerAction,
  inactiveStickerAction,
  listStickerAction,
  updateStickerAction,
} from './sticker.action';

const initialState: StickerStateModel = {
  stickerListData: null,
  stickerDetail: null,
  triggerSaveSticker: null,

  loadingList: false,
  loadingDetail: false,
};

export const stickerSlice = createSlice({
  name: 'sticker',
  initialState,
  reducers: {
    setStickerStateAction: (
      state,
      action: PayloadAction<Partial<StickerStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as StickerStateModel),
    resetStickerStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStickerAction.pending, (state) => ({
        ...state,
        stickerDetail: null,
        loadingDetail: true,
      }))
      .addCase(getStickerAction.fulfilled, (state, { payload }) => ({
        ...state,
        stickerDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getStickerAction.rejected, (state) => ({
        ...state,
        stickerDetail: null,
        loadingDetail: false,
      }))
      .addCase(listStickerAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listStickerAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        stickerListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listStickerAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createStickerAction.fulfilled, (state) => ({
        ...state,
        triggerSaveSticker: { id: uuidv4() },
      }))
      .addCase(updateStickerAction.fulfilled, (state) => ({
        ...state,
        triggerSaveSticker: { id: uuidv4() },
      }))

      .addCase(deleteStickerAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteStickerAction.fulfilled, (state) => ({
        ...state,
        triggerSaveSticker: { id: uuidv4() },
      }))
      .addCase(deleteStickerAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeStickerAction.fulfilled, (state) => ({
        ...state,
        triggerSaveSticker: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveStickerAction.fulfilled, (state) => ({
        ...state,
        triggerSaveSticker: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setStickerStateAction, resetStickerStateAction } =
  stickerSlice.actions;

export default stickerSlice.reducer;
