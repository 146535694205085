import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  exportReportErrorBooth,
  getShootingCountSummary,
  listAnnualShootingCount,
  listMonthlyShootingCount,
  listReportDepositLog,
  listReportErrorBooth,
  listReportShootingDuration,
} from 'api/report/report.api';
import {
  ExportReportErrorBoothRequestModel,
  GetShootingCountSummaryRequestModel,
  ListAnnualShootingCountRequestModel,
  ListMonthlyShootingCountRequestModel,
  ListReportDepositLogRequestModel,
  ListReportErrorBoothRequestModel,
  ListReportShootingDurationRequestModel,
} from 'models/report/report.model';

export const getShootingCountSummaryAction = createAsyncThunk(
  'GET_SHOOTING_COUNT_SUMMARY_REQUEST',
  async (payload: GetShootingCountSummaryRequestModel, { rejectWithValue }) => {
    try {
      return await getShootingCountSummary(payload);
    } catch (err: any) {
      // TODO: refactor types
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const listAnnualShootingCountAction = createAsyncThunk(
  'LIST_ANNUAL_SHOOTING_COUNT_REQUEST',
  async (payload: ListAnnualShootingCountRequestModel, { rejectWithValue }) => {
    try {
      return await listAnnualShootingCount(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const listMonthlyShootingCountAction = createAsyncThunk(
  'LIST_MONTHLY_SHOOTING_COUNT_REQUEST',
  async (
    payload: ListMonthlyShootingCountRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await listMonthlyShootingCount(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const listReportShootingDurationAction = createAsyncThunk(
  'LIST_REPORT_SHOOTING_DURATION_REQUEST',
  async (
    payload: ListReportShootingDurationRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await listReportShootingDuration(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const listReportDepositLogAction = createAsyncThunk(
  'LIST_REPORT_DEPOSIT_LOG_REQUEST',
  async (payload: ListReportDepositLogRequestModel, { rejectWithValue }) => {
    try {
      return await listReportDepositLog(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const listReportErrorBoothAction = createAsyncThunk(
  'LIST_REPORT_ERROR_BOOTH_REQUEST',
  async (payload: ListReportErrorBoothRequestModel, { rejectWithValue }) => {
    try {
      return await listReportErrorBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const exportReportErrorBoothAction = createAsyncThunk(
  'EXPORT_REPORT_ERROR_BOOTH_REQUEST',
  async (payload: ExportReportErrorBoothRequestModel, { rejectWithValue }) => {
    try {
      return await exportReportErrorBooth(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
