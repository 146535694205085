import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { StickerTemplateStateModel } from 'models/sticker/sticker-template.model';
import {
  activeStickerTemplateAction,
  createStickerTemplateAction,
  deleteStickerTemplateAction,
  getStickerTemplateAction,
  inactiveStickerTemplateAction,
  listStickerTemplateAction,
  updateStickerTemplateAction,
} from './sticker-template.action';

const initialState: StickerTemplateStateModel = {
  stickerTemplateListData: null,
  stickerTemplateDetail: null,
  loadingList: false,
  triggerSaveStickerTemplate: null,
};

export const stickerTemplateSlice = createSlice({
  name: 'stickerTemplate',
  initialState,
  reducers: {
    setStickerTemplateStateAction: (
      state,
      action: PayloadAction<Partial<StickerTemplateStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as StickerTemplateStateModel),
    resetStickerTemplateStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStickerTemplateAction.pending, (state) => ({
        ...state,
        stickerTemplateDetail: null,
      }))
      .addCase(getStickerTemplateAction.fulfilled, (state, { payload }) => ({
        ...state,
        stickerTemplateDetail: payload?.data || null,
      }))
      .addCase(getStickerTemplateAction.rejected, (state) => ({
        ...state,
        stickerTemplateDetail: null,
      }))
      .addCase(listStickerTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listStickerTemplateAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        stickerTemplateListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listStickerTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createStickerTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStickerTemplate: { id: uuidv4() },
      }))
      .addCase(updateStickerTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStickerTemplate: { id: uuidv4() },
      }))

      .addCase(deleteStickerTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteStickerTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStickerTemplate: { id: uuidv4() },
      }))
      .addCase(deleteStickerTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeStickerTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStickerTemplate: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveStickerTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveStickerTemplate: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const {
  setStickerTemplateStateAction,
  resetStickerTemplateStateAction,
} = stickerTemplateSlice.actions;

export default stickerTemplateSlice.reducer;
