import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveLayoutRequestModel,
  ActiveLayoutResponseModel,
  DeleteLayoutRequestModel,
  DeleteLayoutResponseModel,
  GetLayoutRequestModel,
  GetLayoutResponseModel,
  InactiveLayoutRequestModel,
  InactiveLayoutResponseModel,
  ListLayoutRequestModel,
  ListLayoutResponseModel,
  SaveLayoutRequestModel,
  SaveLayoutResponseModel,
} from 'models/layout/layout.model';

export function getLayout(payload: GetLayoutRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetLayoutResponseModel,
      GetLayoutResponseModel
    >(`${PREFIX_API.GENERAL}/layouts/${payload?.id}`);
  }
}

export function listLayout(payload: ListLayoutRequestModel) {
  return authorizedRequest.get<
    ListLayoutResponseModel,
    ListLayoutResponseModel
  >(`${PREFIX_API.GENERAL}/layouts?${preprocessGetQuery(payload)}`);
}

export function createLayout(payload: SaveLayoutRequestModel) {
  return authorizedRequest.post<
    SaveLayoutResponseModel,
    SaveLayoutResponseModel
  >(`${PREFIX_API.GENERAL}/layouts`, payload);
}

export function updateLayout(payload: SaveLayoutRequestModel) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveLayoutResponseModel,
      SaveLayoutResponseModel
    >(`${PREFIX_API.GENERAL}/layouts/${payload?.id}`, payload);
  }
}

export function deleteLayout(payload: DeleteLayoutRequestModel) {
  return authorizedRequest.delete<
    DeleteLayoutResponseModel,
    DeleteLayoutResponseModel
  >(`${PREFIX_API.GENERAL}/layouts/${payload?.id}`);
}

export function inactiveLayout(payload: InactiveLayoutRequestModel) {
  return authorizedRequest.put<
    InactiveLayoutResponseModel,
    InactiveLayoutResponseModel
  >(`${PREFIX_API.GENERAL}/layouts/${payload?.id}/inactive`);
}

export function activeLayout(payload: ActiveLayoutRequestModel) {
  return authorizedRequest.put<
    ActiveLayoutResponseModel,
    ActiveLayoutResponseModel
  >(`${PREFIX_API.GENERAL}/layouts/${payload?.id}/active`);
}
