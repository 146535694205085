import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeBoothBackgroundTemplate,
  createBoothBackgroundTemplate,
  deleteBoothBackgroundTemplate,
  getBoothBackgroundTemplate,
  inactiveBoothBackgroundTemplate,
  listBoothBackgroundTemplate,
  updateBoothBackgroundTemplate,
} from 'api/booth-background/booth-background-template.api';
import {
  ActiveBoothBackgroundTemplateRequestModel,
  DeleteBoothBackgroundTemplateRequestModel,
  GetBoothBackgroundTemplateRequestModel,
  InactiveBoothBackgroundTemplateRequestModel,
  ListBoothBackgroundTemplateRequestModel,
  SaveBoothBackgroundTemplateRequestModel,
} from 'models/booth-background/booth-background-template.model';

export const getBoothBackgroundTemplateAction = createAsyncThunk(
  'GET_BOOTH_BACKGROUND_TEMPLATE_REQUEST',
  async (payload: GetBoothBackgroundTemplateRequestModel) =>
    getBoothBackgroundTemplate(payload),
);

export const listBoothBackgroundTemplateAction = createAsyncThunk(
  'LIST_BOOTH_BACKGROUND_TEMPLATE_REQUEST',
  async (payload: ListBoothBackgroundTemplateRequestModel) =>
    listBoothBackgroundTemplate(payload),
);

export const createBoothBackgroundTemplateAction = createAsyncThunk(
  'CREATE_BOOTH_BACKGROUND_TEMPLATE_REQUEST',
  async (
    payload: SaveBoothBackgroundTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await createBoothBackgroundTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateBoothBackgroundTemplateAction = createAsyncThunk(
  'UPDATE_BOOTH_BACKGROUND_TEMPLATE_REQUEST',
  async (
    payload: SaveBoothBackgroundTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await updateBoothBackgroundTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteBoothBackgroundTemplateAction = createAsyncThunk(
  'DELETE_BOOTH_BACKGROUND_TEMPLATE_REQUEST',
  async (
    payload: DeleteBoothBackgroundTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await deleteBoothBackgroundTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveBoothBackgroundTemplateAction = createAsyncThunk(
  'INACTIVE_BOOTH_BACKGROUND_TEMPLATE_REQUEST',
  async (
    payload: InactiveBoothBackgroundTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await inactiveBoothBackgroundTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeBoothBackgroundTemplateAction = createAsyncThunk(
  'ACTIVE_BOOTH_BACKGROUND_TEMPLATE_REQUEST',
  async (
    payload: ActiveBoothBackgroundTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await activeBoothBackgroundTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
