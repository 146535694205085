import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { CapturePositionStateModel } from 'models/setting/capture-position/capture-position.model';
import {
  activeCapturePositionAction,
  createCapturePositionAction,
  deleteCapturePositionAction,
  getCapturePositionAction,
  inactiveCapturePositionAction,
  listCapturePositionAction,
  updateCapturePositionAction,
} from './capture-position.action';

const initialState: CapturePositionStateModel = {
  capturePositionListData: null,
  capturePositionDetail: null,
  triggerSaveCapturePosition: null,

  loadingList: false,
  loadingDetail: false,
};

export const capturePositionSlice = createSlice({
  name: 'capturePosition',
  initialState,
  reducers: {
    setCapturePositionStateAction: (
      state,
      action: PayloadAction<Partial<CapturePositionStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as CapturePositionStateModel),
    resetCapturePositionStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCapturePositionAction.pending, (state) => ({
        ...state,
        capturePositionDetail: null,
        loadingDetail: true,
      }))
      .addCase(getCapturePositionAction.fulfilled, (state, { payload }) => ({
        ...state,
        capturePositionDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getCapturePositionAction.rejected, (state) => ({
        ...state,
        capturePositionDetail: null,
        loadingDetail: false,
      }))
      .addCase(listCapturePositionAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listCapturePositionAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        capturePositionListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listCapturePositionAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createCapturePositionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCapturePosition: { id: uuidv4() },
      }))
      .addCase(updateCapturePositionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCapturePosition: { id: uuidv4() },
      }))

      .addCase(deleteCapturePositionAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteCapturePositionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCapturePosition: { id: uuidv4() },
      }))
      .addCase(deleteCapturePositionAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeCapturePositionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCapturePosition: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveCapturePositionAction.fulfilled, (state) => ({
        ...state,
        triggerSaveCapturePosition: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const {
  setCapturePositionStateAction,
  resetCapturePositionStateAction,
} = capturePositionSlice.actions;

export default capturePositionSlice.reducer;
