import React, { memo } from 'react';
import isEqual from 'react-fast-compare';
import {
  Loader as SUILoader,
  LoaderProps as SUILoaderProps,
} from 'semantic-ui-react';

export type LoaderProps = SUILoaderProps & {};

const Loader: React.FC<LoaderProps> = ({ ...rest }) => <SUILoader {...rest} />;

export default memo(Loader, isEqual);
