import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeCapturePositionTemplate,
  createCapturePositionTemplate,
  deleteCapturePositionTemplate,
  getCapturePositionTemplate,
  inactiveCapturePositionTemplate,
  listCapturePositionTemplate,
  updateCapturePositionTemplate,
} from 'api/setting/capture-position/capture-position-template.api';
import {
  ActiveCapturePositionTemplateRequestModel,
  DeleteCapturePositionTemplateRequestModel,
  GetCapturePositionTemplateRequestModel,
  InactiveCapturePositionTemplateRequestModel,
  ListCapturePositionTemplateRequestModel,
  SaveCapturePositionTemplateRequestModel,
} from 'models/setting/capture-position/capture-position-template.model';

export const getCapturePositionTemplateAction = createAsyncThunk(
  'GET_CAPTURE_POSITION_TEMPLATE_REQUEST',
  async (payload: GetCapturePositionTemplateRequestModel) =>
    getCapturePositionTemplate(payload),
);

export const listCapturePositionTemplateAction = createAsyncThunk(
  'LIST_CAPTURE_POSITION_TEMPLATE_REQUEST',
  async (payload: ListCapturePositionTemplateRequestModel) =>
    listCapturePositionTemplate(payload),
);

export const createCapturePositionTemplateAction = createAsyncThunk(
  'CREATE_CAPTURE_POSITION_TEMPLATE_REQUEST',
  async (
    payload: SaveCapturePositionTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await createCapturePositionTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateCapturePositionTemplateAction = createAsyncThunk(
  'UPDATE_CAPTURE_POSITION_TEMPLATE_REQUEST',
  async (
    payload: SaveCapturePositionTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await updateCapturePositionTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteCapturePositionTemplateAction = createAsyncThunk(
  'DELETE_CAPTURE_POSITION_TEMPLATE_REQUEST',
  async (
    payload: DeleteCapturePositionTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await deleteCapturePositionTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveCapturePositionTemplateAction = createAsyncThunk(
  'INACTIVE_CAPTURE_POSITION_TEMPLATE_REQUEST',
  async (
    payload: InactiveCapturePositionTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await inactiveCapturePositionTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeCapturePositionTemplateAction = createAsyncThunk(
  'ACTIVE_CAPTURE_POSITION_TEMPLATE_REQUEST',
  async (
    payload: ActiveCapturePositionTemplateRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await activeCapturePositionTemplate(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
