import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  activeStore,
  createStore,
  deleteStore,
  getStore,
  inactiveStore,
  listStore,
  updateStore,
} from 'api/store/store.api';
import {
  ActiveStoreRequestModel,
  DeleteStoreRequestModel,
  GetStoreRequestModel,
  InactiveStoreRequestModel,
  ListStoreRequestModel,
  SaveStoreRequestModel,
} from 'models/store/store.model';

export const getStoreAction = createAsyncThunk(
  'GET_STORE_REQUEST',
  async (payload: GetStoreRequestModel) => getStore(payload),
);

export const listStoreAction = createAsyncThunk(
  'LIST_STORE_REQUEST',
  async (payload: ListStoreRequestModel) => listStore(payload),
);

export const createStoreAction = createAsyncThunk(
  'CREATE_STORE_REQUEST',
  async (payload: SaveStoreRequestModel, { rejectWithValue }) => {
    try {
      return await createStore(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const updateStoreAction = createAsyncThunk(
  'UPDATE_STORE_REQUEST',
  async (payload: SaveStoreRequestModel, { rejectWithValue }) => {
    try {
      return await updateStore(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const deleteStoreAction = createAsyncThunk(
  'DELETE_STORE_REQUEST',
  async (payload: DeleteStoreRequestModel, { rejectWithValue }) => {
    try {
      return await deleteStore(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const inactiveStoreAction = createAsyncThunk(
  'INACTIVE_STORE_REQUEST',
  async (payload: InactiveStoreRequestModel, { rejectWithValue }) => {
    try {
      return await inactiveStore(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);

export const activeStoreAction = createAsyncThunk(
  'ACTIVE_STORE_REQUEST',
  async (payload: ActiveStoreRequestModel, { rejectWithValue }) => {
    try {
      return await activeStore(payload);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  },
);
