import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveBoothBackgroundTemplateRequestModel,
  ActiveBoothBackgroundTemplateResponseModel,
  DeleteBoothBackgroundTemplateRequestModel,
  DeleteBoothBackgroundTemplateResponseModel,
  GetBoothBackgroundTemplateRequestModel,
  GetBoothBackgroundTemplateResponseModel,
  InactiveBoothBackgroundTemplateRequestModel,
  InactiveBoothBackgroundTemplateResponseModel,
  ListBoothBackgroundTemplateRequestModel,
  ListBoothBackgroundTemplateResponseModel,
  SaveBoothBackgroundTemplateRequestModel,
  SaveBoothBackgroundTemplateResponseModel,
} from 'models/booth-background/booth-background-template.model';

export function getBoothBackgroundTemplate(
  payload: GetBoothBackgroundTemplateRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetBoothBackgroundTemplateResponseModel,
      GetBoothBackgroundTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/background-lists/${payload?.id}`);
  }
}

export function listBoothBackgroundTemplate(
  payload: ListBoothBackgroundTemplateRequestModel,
) {
  return authorizedRequest.get<
    ListBoothBackgroundTemplateResponseModel,
    ListBoothBackgroundTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/background-lists?${preprocessGetQuery(payload)}`);
}

export function createBoothBackgroundTemplate(
  payload: SaveBoothBackgroundTemplateRequestModel,
) {
  return authorizedRequest.post<
    SaveBoothBackgroundTemplateResponseModel,
    SaveBoothBackgroundTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/background-lists`, payload);
}

export function updateBoothBackgroundTemplate(
  payload: SaveBoothBackgroundTemplateRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveBoothBackgroundTemplateResponseModel,
      SaveBoothBackgroundTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/background-lists/${payload?.id}`, payload);
  }
}

export function deleteBoothBackgroundTemplate(
  payload: DeleteBoothBackgroundTemplateRequestModel,
) {
  return authorizedRequest.delete<
    DeleteBoothBackgroundTemplateResponseModel,
    DeleteBoothBackgroundTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/background-lists/${payload?.id}`);
}

export function inactiveBoothBackgroundTemplate(
  payload: InactiveBoothBackgroundTemplateRequestModel,
) {
  return authorizedRequest.put<
    InactiveBoothBackgroundTemplateResponseModel,
    InactiveBoothBackgroundTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/background-lists/${payload?.id}/inactive`);
}

export function activeBoothBackgroundTemplate(
  payload: ActiveBoothBackgroundTemplateRequestModel,
) {
  return authorizedRequest.put<
    ActiveBoothBackgroundTemplateResponseModel,
    ActiveBoothBackgroundTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/background-lists/${payload?.id}/active`);
}
