import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveThemeCategoryRequestModel,
  ActiveThemeCategoryResponseModel,
  DeleteThemeCategoryRequestModel,
  DeleteThemeCategoryResponseModel,
  GetThemeCategoryRequestModel,
  GetThemeCategoryResponseModel,
  InactiveThemeCategoryRequestModel,
  InactiveThemeCategoryResponseModel,
  ListThemeCategoryRequestModel,
  ListThemeCategoryResponseModel,
  SaveThemeCategoryRequestModel,
  SaveThemeCategoryResponseModel,
} from 'models/theme/theme-category.model';

export function getThemeCategory(payload: GetThemeCategoryRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetThemeCategoryResponseModel,
      GetThemeCategoryResponseModel
    >(`${PREFIX_API.GENERAL}/theme-categories/${payload?.id}`);
  }
}

export function listThemeCategory(payload: ListThemeCategoryRequestModel) {
  return authorizedRequest.get<
    ListThemeCategoryResponseModel,
    ListThemeCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/theme-categories?${preprocessGetQuery(payload)}`);
}

export function createThemeCategory(payload: SaveThemeCategoryRequestModel) {
  return authorizedRequest.post<
    SaveThemeCategoryResponseModel,
    SaveThemeCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/theme-categories`, payload);
}

export function updateThemeCategory(payload: SaveThemeCategoryRequestModel) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveThemeCategoryResponseModel,
      SaveThemeCategoryResponseModel
    >(`${PREFIX_API.GENERAL}/theme-categories/${payload?.id}`, payload);
  }
}

export function deleteThemeCategory(payload: DeleteThemeCategoryRequestModel) {
  return authorizedRequest.delete<
    DeleteThemeCategoryResponseModel,
    DeleteThemeCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/theme-categories/${payload?.id}`);
}

export function inactiveThemeCategory(
  payload: InactiveThemeCategoryRequestModel,
) {
  return authorizedRequest.put<
    InactiveThemeCategoryResponseModel,
    InactiveThemeCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/theme-categories/${payload?.id}/inactive`);
}

export function activeThemeCategory(payload: ActiveThemeCategoryRequestModel) {
  return authorizedRequest.put<
    ActiveThemeCategoryResponseModel,
    ActiveThemeCategoryResponseModel
  >(`${PREFIX_API.GENERAL}/theme-categories/${payload?.id}/active`);
}
