import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { VoucherStateModel } from 'models/promotion/voucher.model';
import {
  activeVoucherAction,
  deleteVoucherAction,
  generateVoucherAction,
  getVoucherAction,
  inactiveVoucherAction,
  listVoucherAction,
} from './voucher.action';

const initialState: VoucherStateModel = {
  voucherListData: null,
  voucherDetail: null,
  triggerSaveVoucher: null,

  loadingList: false,
  loadingDetail: false,
};

export const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    setVoucherStateAction: (
      state,
      action: PayloadAction<Partial<VoucherStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as VoucherStateModel),
    resetVoucherStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVoucherAction.pending, (state) => ({
        ...state,
        voucherDetail: null,
        loadingDetail: true,
      }))
      .addCase(getVoucherAction.fulfilled, (state, { payload }) => ({
        ...state,
        voucherDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getVoucherAction.rejected, (state) => ({
        ...state,
        voucherDetail: null,
        loadingDetail: false,
      }))
      .addCase(listVoucherAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listVoucherAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        voucherListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listVoucherAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(generateVoucherAction.fulfilled, (state) => ({
        ...state,
        triggerSaveVoucher: { id: uuidv4() },
      }))
      .addCase(deleteVoucherAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteVoucherAction.fulfilled, (state) => ({
        ...state,
        triggerSaveVoucher: { id: uuidv4() },
      }))
      .addCase(deleteVoucherAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeVoucherAction.fulfilled, (state) => ({
        ...state,
        triggerSaveVoucher: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveVoucherAction.fulfilled, (state) => ({
        ...state,
        triggerSaveVoucher: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setVoucherStateAction, resetVoucherStateAction } =
  voucherSlice.actions;

export default voucherSlice.reducer;
