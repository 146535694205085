import React, { memo } from 'react';
import isEqual from 'react-fast-compare';
import cx from 'classnames';
import {
  Dimmer as SUIDimmer,
  DimmerProps as SUIDimmerProps,
} from 'semantic-ui-react';

export type DimmerProps = SUIDimmerProps & {};

const Dimmer: React.FC<DimmerProps> = ({ ...rest }) => (
  <SUIDimmer inverted {...rest} className={cx('pb-dimmer', rest?.className)} />
);

export default memo(Dimmer, isEqual);
