import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveThemeTemplateRequestModel,
  ActiveThemeTemplateResponseModel,
  DeleteThemeTemplateRequestModel,
  DeleteThemeTemplateResponseModel,
  GetThemeTemplateRequestModel,
  GetThemeTemplateResponseModel,
  InactiveThemeTemplateRequestModel,
  InactiveThemeTemplateResponseModel,
  ListThemeTemplateRequestModel,
  ListThemeTemplateResponseModel,
  SaveThemeTemplateRequestModel,
  SaveThemeTemplateResponseModel,
} from 'models/theme/theme-template.model';

export function getThemeTemplate(payload: GetThemeTemplateRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetThemeTemplateResponseModel,
      GetThemeTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/theme-lists/${payload?.id}`);
  }
}

export function listThemeTemplate(payload: ListThemeTemplateRequestModel) {
  return authorizedRequest.get<
    ListThemeTemplateResponseModel,
    ListThemeTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/theme-lists?${preprocessGetQuery(payload)}`);
}

export function createThemeTemplate(payload: SaveThemeTemplateRequestModel) {
  return authorizedRequest.post<
    SaveThemeTemplateResponseModel,
    SaveThemeTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/theme-lists`, payload);
}

export function updateThemeTemplate(payload: SaveThemeTemplateRequestModel) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveThemeTemplateResponseModel,
      SaveThemeTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/theme-lists/${payload?.id}`, payload);
  }
}

export function deleteThemeTemplate(payload: DeleteThemeTemplateRequestModel) {
  return authorizedRequest.delete<
    DeleteThemeTemplateResponseModel,
    DeleteThemeTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/theme-lists/${payload?.id}`);
}

export function inactiveThemeTemplate(
  payload: InactiveThemeTemplateRequestModel,
) {
  return authorizedRequest.put<
    InactiveThemeTemplateResponseModel,
    InactiveThemeTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/theme-lists/${payload?.id}/inactive`);
}

export function activeThemeTemplate(payload: ActiveThemeTemplateRequestModel) {
  return authorizedRequest.put<
    ActiveThemeTemplateResponseModel,
    ActiveThemeTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/theme-lists/${payload?.id}/active`);
}
