import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  ActiveStickerTemplateRequestModel,
  ActiveStickerTemplateResponseModel,
  DeleteStickerTemplateRequestModel,
  DeleteStickerTemplateResponseModel,
  GetStickerTemplateRequestModel,
  GetStickerTemplateResponseModel,
  InactiveStickerTemplateRequestModel,
  InactiveStickerTemplateResponseModel,
  ListStickerTemplateRequestModel,
  ListStickerTemplateResponseModel,
  SaveStickerTemplateRequestModel,
  SaveStickerTemplateResponseModel,
} from 'models/sticker/sticker-template.model';

export function getStickerTemplate(payload: GetStickerTemplateRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetStickerTemplateResponseModel,
      GetStickerTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/sticker-lists/${payload?.id}`);
  }
}

export function listStickerTemplate(payload: ListStickerTemplateRequestModel) {
  return authorizedRequest.get<
    ListStickerTemplateResponseModel,
    ListStickerTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/sticker-lists?${preprocessGetQuery(payload)}`);
}

export function createStickerTemplate(
  payload: SaveStickerTemplateRequestModel,
) {
  return authorizedRequest.post<
    SaveStickerTemplateResponseModel,
    SaveStickerTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/sticker-lists`, payload);
}

export function updateStickerTemplate(
  payload: SaveStickerTemplateRequestModel,
) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveStickerTemplateResponseModel,
      SaveStickerTemplateResponseModel
    >(`${PREFIX_API.GENERAL}/sticker-lists/${payload?.id}`, payload);
  }
}

export function deleteStickerTemplate(
  payload: DeleteStickerTemplateRequestModel,
) {
  return authorizedRequest.delete<
    DeleteStickerTemplateResponseModel,
    DeleteStickerTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/sticker-lists/${payload?.id}`);
}

export function inactiveStickerTemplate(
  payload: InactiveStickerTemplateRequestModel,
) {
  return authorizedRequest.put<
    InactiveStickerTemplateResponseModel,
    InactiveStickerTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/sticker-lists/${payload?.id}/inactive`);
}

export function activeStickerTemplate(
  payload: ActiveStickerTemplateRequestModel,
) {
  return authorizedRequest.put<
    ActiveStickerTemplateResponseModel,
    ActiveStickerTemplateResponseModel
  >(`${PREFIX_API.GENERAL}/sticker-lists/${payload?.id}/active`);
}
