import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ThemeTemplateStateModel } from 'models/theme/theme-template.model';
import {
  activeThemeTemplateAction,
  createThemeTemplateAction,
  deleteThemeTemplateAction,
  getThemeTemplateAction,
  inactiveThemeTemplateAction,
  listThemeTemplateAction,
  updateThemeTemplateAction,
} from './theme-template.action';

const initialState: ThemeTemplateStateModel = {
  themeTemplateListData: null,
  themeTemplateDetail: null,
  loadingList: false,
  triggerSaveThemeTemplate: null,
};

export const themeTemplateSlice = createSlice({
  name: 'themeTemplate',
  initialState,
  reducers: {
    setThemeTemplateStateAction: (
      state,
      action: PayloadAction<Partial<ThemeTemplateStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as ThemeTemplateStateModel),
    resetThemeTemplateStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThemeTemplateAction.pending, (state) => ({
        ...state,
        themeTemplateDetail: null,
      }))
      .addCase(getThemeTemplateAction.fulfilled, (state, { payload }) => ({
        ...state,
        themeTemplateDetail: payload?.data || null,
      }))
      .addCase(getThemeTemplateAction.rejected, (state) => ({
        ...state,
        themeTemplateDetail: null,
      }))
      .addCase(listThemeTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listThemeTemplateAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        themeTemplateListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listThemeTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createThemeTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveThemeTemplate: { id: uuidv4() },
      }))
      .addCase(updateThemeTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveThemeTemplate: { id: uuidv4() },
      }))

      .addCase(deleteThemeTemplateAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deleteThemeTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveThemeTemplate: { id: uuidv4() },
      }))
      .addCase(deleteThemeTemplateAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activeThemeTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveThemeTemplate: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactiveThemeTemplateAction.fulfilled, (state) => ({
        ...state,
        triggerSaveThemeTemplate: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const { setThemeTemplateStateAction, resetThemeTemplateStateAction } =
  themeTemplateSlice.actions;

export default themeTemplateSlice.reducer;
