import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { PromotionCampaignStateModel } from 'models/promotion/promotion-campaign.model';
import {
  activePromotionCampaignAction,
  createPromotionCampaignAction,
  deletePromotionCampaignAction,
  getPromotionCampaignAction,
  inactivePromotionCampaignAction,
  listPromotionCampaignAction,
  updatePromotionCampaignAction,
} from './promotion-campaign.action';

const initialState: PromotionCampaignStateModel = {
  promotionCampaignListData: null,
  promotionCampaignDetail: null,
  triggerSavePromotionCampaign: null,

  loadingList: false,
  loadingDetail: false,
};

export const promotionCampaignSlice = createSlice({
  name: 'promotionCampaign',
  initialState,
  reducers: {
    setPromotionCampaignStateAction: (
      state,
      action: PayloadAction<Partial<PromotionCampaignStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as PromotionCampaignStateModel),
    resetPromotionCampaignStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPromotionCampaignAction.pending, (state) => ({
        ...state,
        promotionCampaignDetail: null,
        loadingDetail: true,
      }))
      .addCase(getPromotionCampaignAction.fulfilled, (state, { payload }) => ({
        ...state,
        promotionCampaignDetail: payload?.data || null,
        loadingDetail: false,
      }))
      .addCase(getPromotionCampaignAction.rejected, (state) => ({
        ...state,
        promotionCampaignDetail: null,
        loadingDetail: false,
      }))
      .addCase(listPromotionCampaignAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(listPromotionCampaignAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingList: false,
        promotionCampaignListData: {
          items: payload?.data?.items || [],
          totalItems: payload?.data?.totalItems || 0,
          totalPages: payload?.data?.totalPages || 0,
        },
      }))
      .addCase(listPromotionCampaignAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(createPromotionCampaignAction.fulfilled, (state) => ({
        ...state,
        triggerSavePromotionCampaign: { id: uuidv4() },
      }))
      .addCase(updatePromotionCampaignAction.fulfilled, (state) => ({
        ...state,
        triggerSavePromotionCampaign: { id: uuidv4() },
      }))

      .addCase(deletePromotionCampaignAction.pending, (state) => ({
        ...state,
        loadingList: true,
      }))
      .addCase(deletePromotionCampaignAction.fulfilled, (state) => ({
        ...state,
        triggerSavePromotionCampaign: { id: uuidv4() },
      }))
      .addCase(deletePromotionCampaignAction.rejected, (state) => ({
        ...state,
        loadingList: false,
      }))
      .addCase(activePromotionCampaignAction.fulfilled, (state) => ({
        ...state,
        triggerSavePromotionCampaign: { id: uuidv4() },
        loadingList: false,
      }))
      .addCase(inactivePromotionCampaignAction.fulfilled, (state) => ({
        ...state,
        triggerSavePromotionCampaign: { id: uuidv4() },
        loadingList: false,
      }));
  },
});

export const {
  setPromotionCampaignStateAction,
  resetPromotionCampaignStateAction,
} = promotionCampaignSlice.actions;

export default promotionCampaignSlice.reducer;
