import React, { FC, memo, lazy, useCallback } from 'react';
import { ALL_ROUTE_CHAR, AppRouteConst } from 'constants/route.const';
import { useAppDispatch, useAppSelector } from 'store/store-hooks';
import { setAppStateAction } from 'store/features/app/appSlice';
import CustomRoutes from 'components/custom-routes/CustomRoutes';
import Loader from 'components/loader/Loader';
import Dimmer from 'components/dimmer/Dimmer';
import ConfirmModal from 'components/modal/confirm-modal/ConfirmModal';
import RightDrawer from 'components/modal/right-drawer/RightDrawer';
import { useLoadFirstData } from './hooks/useLoadFirstData';
import LeftMenu from './left-menu/LeftMenu';
import AppHeader from './header/AppHeader';
import { AppContext } from '../context/AppContext';
import './app-layout.css';

const Dashboard = lazy(() => import('containers/app/dashboard/Dashboard'));
const Transaction = lazy(
  () => import('containers/app/transaction/Transaction'),
);
const Company = lazy(() => import('containers/app/company/Company'));
const Store = lazy(() => import('containers/app/store/Store'));
const Booth = lazy(() => import('containers/app/booth/Booth'));
const Theme = lazy(() => import('containers/app/theme/Theme'));
const ThemeCategory = lazy(
  () => import('containers/app/theme-category/ThemeCategory'),
);
const ThemeTemplate = lazy(
  () => import('containers/app/theme-template/ThemeTemplate'),
);

// Sticker
const Sticker = lazy(() => import('containers/app/sticker/Sticker'));
const StickerCategory = lazy(
  () => import('containers/app/sticker/sticker-category/StickerCategory'),
);
const StickerTemplate = lazy(
  () => import('containers/app/sticker/sticker-template/StickerTemplate'),
);

const LayoutTemplate = lazy(
  () => import('containers/app/layout-template/LayoutTemplate'),
);
const PromotionCampaign = lazy(
  () => import('containers/app/promotion/promotion-campaign/PromotionCampaign'),
);
const Voucher = lazy(() => import('containers/app/promotion/voucher/Voucher'));
const Report = lazy(() => import('containers/app/report/Report'));
const User = lazy(() => import('containers/app/user/User'));
const Profile = lazy(() => import('containers/app/user/profile/Profile'));
const Setting = lazy(() => import('containers/app/setting/Setting'));
const Guide = lazy(() => import('containers/app/other/guide/Guide'));
const Page403 = lazy(() => import('containers/app/other/403/403'));
const Page404 = lazy(() => import('containers/app/other/404/404'));

const routes = [
  {
    path: AppRouteConst.HOME,
    element: <Dashboard />,
  },
  {
    path: AppRouteConst.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: AppRouteConst.TRANSACTION,
    element: <Transaction />,
  },
  {
    path: AppRouteConst.COMPANY,
    element: <Company />,
  },
  {
    path: AppRouteConst.STORE,
    element: <Store />,
  },
  {
    path: AppRouteConst.BOOTH,
    element: <Booth />,
  },

  // Theme
  {
    path: `${AppRouteConst.THEME.LIST}/*`,
    element: <Theme />,
  },
  {
    path: `${AppRouteConst.THEME_CATEGORY}`,
    element: <ThemeCategory />,
  },
  {
    path: `${AppRouteConst.THEME_TEMPLATE.LIST}/*`,
    element: <ThemeTemplate />,
  },
  // --------

  // Sticker --------
  {
    path: `${AppRouteConst.STICKER.LIST}/*`,
    element: <Sticker />,
  },
  {
    path: `${AppRouteConst.STICKER_CATEGORY}`,
    element: <StickerCategory />,
  },
  {
    path: `${AppRouteConst.STICKER_TEMPLATE.LIST}/*`,
    element: <StickerTemplate />,
  },
  // --------

  {
    path: `${AppRouteConst.LAYOUT_TEMPLATE.LIST}/*`,
    element: <LayoutTemplate />,
  },
  {
    path: AppRouteConst.PROMOTION_CAMPAIGN,
    element: <PromotionCampaign />,
  },
  {
    path: `${AppRouteConst.VOUCHER.LIST}/*`,
    element: <Voucher />,
  },
  {
    path: `${AppRouteConst.REPORT.LIST}/*`,
    element: <Report />,
  },
  {
    path: AppRouteConst.USER,
    element: <User />,
  },
  {
    path: `${AppRouteConst.SETTING.LIST}/*`,
    element: <Setting />,
  },
  {
    path: `${AppRouteConst.PROFILE.PREFIX}/*`,
    element: <Profile />,
  },
  {
    path: AppRouteConst.GUIDE,
    element: <Guide />,
  },
  {
    path: AppRouteConst.PAGE_403,
    element: <Page403 />,
  },
  {
    path: ALL_ROUTE_CHAR,
    element: <Page404 />,
  },
];

const AppLayout: FC = () => {
  const dispatch = useAppDispatch();
  const { confirmModal, rightModal } = useAppSelector((state) => state.app);
  const { loadingUserIsLogging } = useAppSelector((s) => s.user);
  const {
    loadingProvince,
    // loadingDistricts,
    // loadingWards,
    // loadingCompany,
    // loadingStores,
    // loadingUserRoles,
    // loadingBooths,
    // loadingLayouts,
    // loadingThemes,
    // loadingThemeCategories,
    // loadingThemeTemplates,
    // loadingLayoutTemplates,
    // loadingPromotionCampaigns,
  } = useAppSelector((s) => s.prefetch);

  const handleClose = useCallback(() => {
    dispatch(setAppStateAction({ confirmModal: { open: false } }));
  }, [dispatch]);

  const handleRightDrawerClose = useCallback(() => {
    dispatch(setAppStateAction({ rightModal: { open: false } }));
  }, [dispatch]);

  useLoadFirstData();

  return (
    <AppContext>
      <RightDrawer onClose={handleRightDrawerClose} {...rightModal}>
        <div className="app-container">
          <Dimmer
            inverted
            active={
              // TODO: Fix scroll & blank blank
              loadingUserIsLogging || loadingProvince
              // loadingUserIsLogging ||
              // loadingProvince ||
              // loadingDistricts ||
              // loadingWards ||
              // loadingCompany ||
              // loadingStores ||
              // loadingUserRoles ||
              // loadingBooths ||
              // loadingLayouts ||
              // loadingThemes ||
              // loadingThemeCategories ||
              // loadingThemeTemplates ||
              // loadingLayoutTemplates ||
              // loadingPromotionCampaigns
            }
          >
            <Loader />
          </Dimmer>
          <AppHeader />
          <div className="content-container">
            <div className="left-menu-section">
              <LeftMenu />
            </div>
            <div className="right-container">
              <div className="main-section">
                <ConfirmModal onClose={handleClose} {...confirmModal} />
                <CustomRoutes routes={routes} />
              </div>
            </div>
          </div>
        </div>
      </RightDrawer>
    </AppContext>
  );
};

export default memo(AppLayout);
