import authorizedRequest from 'api/request/authorizedRequest';
import { PREFIX_API } from 'constants/api.const';
import { preprocessGetQuery } from 'helpers/api.helper';
import {
  DeleteCompanyRequestModel,
  DeleteCompanyResponseModel,
  GetCompanyRequestModel,
  GetCompanyResponseModel,
  ListCompanyRequestModel,
  ListCompanyResponseModel,
  SaveCompanyRequestModel,
  SaveCompanyResponseModel,
  ActiveCompanyRequestModel,
  ActiveCompanyResponseModel,
  InactiveCompanyRequestModel,
  InactiveCompanyResponseModel,
} from 'models/company/company.model';

export function getCompany(payload: GetCompanyRequestModel) {
  if (payload?.id) {
    return authorizedRequest.get<
      GetCompanyResponseModel,
      GetCompanyResponseModel
    >(`${PREFIX_API.GENERAL}/subscribers/${payload?.id}`);
  }
}

export function listCompany(payload: ListCompanyRequestModel) {
  return authorizedRequest.get<
    ListCompanyResponseModel,
    ListCompanyResponseModel
  >(`${PREFIX_API.GENERAL}/subscribers?${preprocessGetQuery(payload)}`);
}

export function createCompany(payload: SaveCompanyRequestModel) {
  return authorizedRequest.post<
    SaveCompanyResponseModel,
    SaveCompanyResponseModel
  >(`${PREFIX_API.GENERAL}/subscribers`, payload);
}

export function updateCompany(payload: SaveCompanyRequestModel) {
  if (payload?.id) {
    return authorizedRequest.put<
      SaveCompanyResponseModel,
      SaveCompanyResponseModel
    >(`${PREFIX_API.GENERAL}/subscribers/${payload?.id}`, payload);
  }
}

export function deleteCompany(payload: DeleteCompanyRequestModel) {
  return authorizedRequest.delete<
    DeleteCompanyResponseModel,
    DeleteCompanyResponseModel
  >(`${PREFIX_API.GENERAL}/subscribers/${payload?.id}`);
}

export function inactiveCompany(payload: InactiveCompanyRequestModel) {
  return authorizedRequest.put<
    InactiveCompanyResponseModel,
    InactiveCompanyResponseModel
  >(
    `${PREFIX_API.GENERAL}/subscribers/${
      payload?.id
    }/inactive?${preprocessGetQuery(payload)}`,
    payload,
  );
}

export function activeCompany(payload: ActiveCompanyRequestModel) {
  return authorizedRequest.put<
    ActiveCompanyResponseModel,
    ActiveCompanyResponseModel
  >(
    `${PREFIX_API.GENERAL}/subscribers/${
      payload?.id
    }/active?${preprocessGetQuery(payload)}`,
    payload,
  );
}
